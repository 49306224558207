import firebase from 'firebase';

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "lets-fly-2020.firebaseapp.com",
    databaseURL: "https://lets-fly-2020.firebaseio.com",
    projectId: "lets-fly-2020",
    storageBucket: "lets-fly-2020.appspot.com",
    messagingSenderId: "942593263854",
    appId: "1:942593263854:web:4178cfc74808753f08cbff",
    measurementId: "G-651NSWBXZV"
  };

  firebase.initializeApp(firebaseConfig);


  // export const provider = new firebase.auth.EmailAuthProvider();
  export const auth = firebase.auth()
  export const db = firebase.firestore()
  export const storage = firebase.storage()
  export const storageRef = storage.ref()
  export const functions = firebase.functions()

  export default firebase;