import React, { useState, useEffect } from 'react'
import FetchUserEvents from '../../../helpers/FetchUserEvents'
import FlightTableRows from './FlightTableRows'

export default function FlightLog(props) {

    const { userData, setUserData, fboData } = props

    const [events, setEvents] = useState()
    const [showTable, setShowTable] = useState(false)

    useEffect(() => {
        
        FetchUserEvents(userData.uid).then(result => {
            setEvents(result)
            setShowTable(true)
        })
        
    }, [userData, fboData])
    
    return (
        <>
            {
                showTable &&
                    <FlightTableRows
                        events={events}
                        userData={userData}
                        setUserData={setUserData}
                        fboData={fboData}
                        setEvents={setEvents}
                    />
            }
        </>
    )
}