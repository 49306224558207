import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'

import "bootswatch/dist/sandstone/bootstrap.min.css"

import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider, CircularProgress } from '@material-ui/core'
import { theme } from './styles/Theming'
import { auth, db } from './utilities/fire'

import Home from './views/home'
import Navigation from './views/navigation'
import Profile from './views/profile'
import ManageFBO from './views/manageFBO'
import EventDetail from './views/eventDetail'
//import Authenticate from './views/authenticate'
import Onboarding from './views/onboarding'
import Reports from './views/reports'
import RenderCalendar from './components/Calendar/RenderCalendar'
import RenderSettings from './views/profile/RenderSettings'
import RenderFlights from './views/profile/RenderFlights';
import Instructors from './views/instructors/index'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import FetchLinkedData from './helpers/FetchLinkedData'

import './App.scss';
import Authenticate from './views/authenticate';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC)

export default function App() {

  const [userData, setUserData] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [fboData, setFBOData] = useState({})
  // const [isAuthenticated, setIsAuthenticated] = useState(false)

  useEffect(() => {

    // When a user logs in or out
    auth.onAuthStateChanged(async user => {

      // If it was a log-in
      if (user) {

        const userDoc = await db.collection('users').doc(user.uid).get()
        const data = userDoc.data()
        setUserData(data)
        setIsLoggedIn(true)

        let userType = 'mem'
        if (data?.fbosManaged?.length > 0) {
          userType = 'man' 
        }

        FetchLinkedData(user.uid, userType).then(result => {
          console.log(userType, result)
          setFBOData(result)
          setIsLoading(false)
        })

        if (!data?.confirmedEmail) {
          await db.collection('users').doc(user.uid).update({
            confirmedEmail: true,
          })
        }

      } else {
        setUserData({})
        setFBOData({})
        setIsLoggedIn(false)
        setIsLoading(false)
      }
    })
  }, [])

  let showFBOManges = false

  if (!!userData) {
    if (userData.fbosManaged) {
      if (userData.fbosManaged.length > 0) {
        showFBOManges = true
      }
    }
  }

  if (isLoading) return (
    <ThemeProvider theme={theme}>
      <div style={{ display: 'flex', direction: 'row', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
        <CircularProgress color="secondary" />
      </div>
    </ThemeProvider>
  )

  return (
    <BrowserRouter>
      <Elements stripe={stripePromise}>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <Switch>

            <Route exact path="/join">
              <Onboarding
                userData={userData}
                setUserData={setUserData}
                isLoggedIn={isLoggedIn}

              />
            </Route>

            <Route path='/acctmgmt'>
              <Authenticate userData={userData} setUserData={setUserData}/>
            </Route>

            {isLoggedIn ?
              <>
                <Navigation setUserData={setUserData} userData={userData} />

                <Route exact path='/'>
                  <Redirect to='/profile'/>
                </Route>


                <Route exact path='/profile'>
                  <Profile userData={userData} setUserData={setUserData} fboData={fboData} />
                </Route>

                <Route exact path="/event/:eventid">
                  <EventDetail userData={userData} setUserData={setUserData} fboData={fboData} />
                </Route>

                <Route exact path='/calendar'>
                  <RenderCalendar userData={userData} fboData={fboData}/>
                </Route>
                
                <Route exact path='/flights'>
                  <RenderFlights userData={userData} setUserData={setUserData} fboData={fboData}/>
                </Route>

                <Route exact path='/instructors'>
                  <Instructors userData={userData} fboData={fboData}/>
                </Route>

                <Route exact path='/settings'>
                  <RenderSettings userData={userData} setUserData={setUserData} fboData={fboData} />
                </Route>

                {/* /reports (TODO - create reports for single users) */}

                {showFBOManges &&
                  <>
                    <Route exact path="/manage-fbo">
                      <ManageFBO userData={userData} setUserData={setUserData} fboData={fboData} setFBOData={setFBOData}/>
                    </Route>
                  
                    <Route exact path="/reports">
                      <Reports userData={userData} fboData={fboData}/>
                    </Route>
                  </>
                }
              </>
              :
              <>
                <Redirect to='/' />
                <Route path='/'>
                  <Home userData={userData} setUserData={setUserData} />
                </Route>
              </>
            }
          </Switch>

        </ThemeProvider>
      </Elements>
    </BrowserRouter>
  );
}
