import React, {useEffect, useReducer, useState} from 'react'

import {
    Dialog, DialogTitle, DialogContent, Typography, IconButton, FormHelperText,
    TextField, FormControl, Button, Checkbox, FormControlLabel, Backdrop, CircularProgress
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import {functions} from '../../../../utilities/fire'

// import FlyBar from '../../../components/FlyBar'

// import { Alert } from '@material-ui/lab'; 
// import {db} from '../../../utilities/fire';

/**
 * 
 * @param {Props} props -
 * - userKey - the selected user object
 * - open - a state of whether the Dialog is open or not
 * - close - a function to close the dialog
 * - activeFBOKey - the selected fbo from the selector
 * - fboData - the complete fbo data object
 * - setFBOData - a function to set the FBO data
 * - type - employee or user
 */
export default function EditPersonDialog(props) {

    const registerPerson = functions.httpsCallable('registerPerson')

    const { activeFBOKey, userData, fboData, dialogType, setStripePersons, stripePersons } = props
    const roleKeys = ['title', 'director', 'executive', 'owner', 'representative']
    const topKeys = ['first_name', 'last_name', 'ssn_last_4', 'email', 'phone']
    const dobKeys = ['month', 'day', 'year']
    const addressKeys = ['line1', 'line2', 'postal_code', 'city', 'state']

    let initialState
    if (userData) {
        initialState = userData
    } else {
        initialState = {
            dob: {},
            address: {},
            relationship: {}
        }
    }

    
    const [person, dispatchPerson] = useReducer(personReducer, initialState)
    const [isUpdating, setIsUpdating] = useState(false)
    
    useEffect(() => {

        // console.log(person)
    
    }, [person])

    function personReducer(state, newState) {

        const {key, val} = newState

        if (topKeys.includes(key)) {
            return {
                ...state, 
                [key]: val
            }
        }

        if (dobKeys.includes(key)) {
            return {
                ...state, 
                dob: {
                    ...state.dob,
                    [key]: val
                }
            }
        }

        if (addressKeys.includes(key)) {
            return {
                ...state,
                address: {
                    ...state.address,
                    [key]: val
                }
            }
        }

        
        if (roleKeys.includes(key)) {
            return {
                ...state,
                relationship: {
                    ...state.relationship,
                    [key]: val
                }
            }
        }

    }

    async function handleSubmitPerson() {

        setIsUpdating(true)

        const personResult = await registerPerson({fboUID: activeFBOKey, stripe_aid: fboData[activeFBOKey].info.stripe.id, person: person})      

        let oldPersons = stripePersons
        oldPersons.push(personResult.data)
        setStripePersons(oldPersons)

        setIsUpdating(false)
        props.close()
    }

    return (

        <Dialog className="responsible-party-add" maxWidth={'xs'} open={props.open} onClose={props.close} color="primary">

            <DialogTitle className='dialog-title' disableTypography>
                {
                    dialogType === 'add' ?
                        <><h4 className="title"><b>Add Responsible Party To Your FBO</b></h4><br/></>
                        :
                        <><h4 className="title"><b>Edit User</b></h4><br /></>
                }
                
                <IconButton edge='end' onClick={props.close}  >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent className="dialog-content">

                <FormControl className="form" fullWidth align="center">

                    <FormHelperText className="helper-text">First Name</FormHelperText>
                    <TextField className="text-field" required variant="outlined" size="small" value={person.first_name || ''} onChange={e => dispatchPerson({ key: 'first_name', val: e.target.value })} />
                    
                    <FormHelperText className="helper-text">Last Name</FormHelperText>
                    <TextField className="text-field" required variant="outlined" size="small" value={person.last_name || ''} onChange={e => dispatchPerson({ key: 'last_name', val: e.target.value })} /> 
                    
                    <Typography className="section-header">Date of Birth</Typography>
                    <div className="dob">
                        <div className="section">
                            <FormHelperText className="helper-text">Month</FormHelperText>
                            <TextField className="text-field" required variant="outlined" size="small" value={person.dob.month || ''} onChange={e => dispatchPerson({ key: 'month', val: e.target.value })} />
                        </div>
                        <div className="section">
                            <FormHelperText className="helper-text">Day</FormHelperText>
                            <TextField className="text-field" required variant="outlined" size="small" value={person.dob.day || ''} onChange={e => dispatchPerson({ key: 'day', val: e.target.value })} />
                        </div>
                        <div className="section">
                            <FormHelperText className="helper-text">Year</FormHelperText>
                            <TextField className="text-field" required variant="outlined" size="small" value={person.dob.year || ''} onChange={e => dispatchPerson({ key: 'year', val: e.target.value })} />
                        </div>
                    </div>

                    <Typography className="section-header">Contact Information</Typography>
                    <FormHelperText className="helper-text">Email</FormHelperText>
                    <TextField className="text-field" required variant="outlined" size="small" value={person.email || ''} onChange={e => dispatchPerson({key: 'email', val: e.target.value}) } />
                    <FormHelperText className="helper-text">Phone</FormHelperText>
                    <TextField className="text-field" required variant="outlined" size="small" value={person.phone || ''} onChange={e => dispatchPerson({key: 'phone', val: e.target.value}) } />
                    <FormHelperText className="helper-text">Address</FormHelperText>
                    <TextField className="text-field" required variant="outlined" size="small" value={person.address.line1 || ''} onChange={e => dispatchPerson({key: 'line1', val: e.target.value}) } />
                    <FormHelperText className="helper-text">Address 2</FormHelperText>
                    <TextField className="text-field" required variant="outlined" size="small" value={person.address.line2 || ''} onChange={e => dispatchPerson({key: 'line2', val: e.target.value}) } />
                    <FormHelperText className="helper-text">City</FormHelperText>
                    <TextField className="text-field" required variant="outlined" size="small" value={person.address.city || ''} onChange={e => dispatchPerson({key: 'city', val: e.target.value}) } />
                    <FormHelperText className="helper-text">State</FormHelperText>
                    <TextField className="text-field" required variant="outlined" size="small" value={person.address.state || ''} onChange={e => dispatchPerson({key: 'state', val: e.target.value}) } />
                    <FormHelperText className="helper-text">Zip Code</FormHelperText>
                    <TextField className="text-field" required variant="outlined" size="small" value={person.address.postal_code || ''} onChange={e => dispatchPerson({key: 'postal_code', val: e.target.value}) } />


                    <Typography className="section-header">Tax Information</Typography>
                    <FormHelperText className="helper-text">Last 4 of SSN</FormHelperText>
                    <TextField className="text-field" required variant="outlined" name="ssn_last_4" size="small" disabled={person.ssn_last_4_provided} placeholder={person.ssn_last_4_provided ? '****' : ''} value={person.ssn_last_4 || ''} onChange={e => dispatchPerson({ key: 'ssn_last_4', val: e.target.value })} /> 
                    
                    <Typography className="section-header">Role At Organization</Typography>
                    <FormHelperText className="helper-text">Title</FormHelperText>
                    <TextField className="text-field" required variant="outlined" name="title" size="small" value={person.relationship.title || ''} onChange={e => dispatchPerson({key: 'title', val: e.target.value})} />
                    
                    {roleKeys.map(role => {

                        if (role === 'title') return null
                        return (
                            <FormControlLabel
                                key={role}
                                control={
                                    <Checkbox
                                        checked={!!person.relationship[role]}
                                        onChange={e => dispatchPerson({key: role, val: e.target.checked}) } 
                                        name={role}
                                        color="primary"
                                        />
                                }
                                label={role.substring(0, 1).toUpperCase() + role.substring(1)} 
                                />
                            
                                
                                )
                    })}
                    
                </FormControl>

                <Button className="save-button" fullWidth variant="contained" color="primary" onClick={()=>handleSubmitPerson()}>Save</Button>

            </DialogContent>

            <Backdrop className='backdrop' open={isUpdating} onClick={() => setIsUpdating(false)}>
                <CircularProgress color='secondary' />
            </Backdrop>

        </Dialog>
        
    )
}