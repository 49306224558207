import React from 'react'

import {
    FormControlLabel, Switch, FormGroup, Typography, FormControl, 
} from '@material-ui/core'

import _ from 'lodash'

import {UpdateCertificationsForUser} from '../../../helpers/SaveData'
import {certifications} from '../../../defaults/certifications'

export default function CertificationSettings(props) {

    const {userData} = props

    function ShowCertificationSettings() {

        function handleCertChange(key) {

            let newUserData = props.userData

            let newCertList = []

            if (newUserData?.certifications) {

                newCertList = [...newUserData.certifications]

                if (newCertList.includes(key)) {

                    _.pull(newCertList, key)
                    UpdateCertificationsForUser(props.userData.uid, key, 'remove')

                } else {

                    newCertList.push(key)
                    UpdateCertificationsForUser(props.userData.uid, key, 'add')

                }

            } else {

                newCertList.push(key)
                UpdateCertificationsForUser(props.userData.uid, key, 'add')

            }

            newUserData.certifications = newCertList
            props.setUserData({...userData, ...newUserData})
            
        }

        let certs = []

        Object.keys(certifications).forEach(certKey => {
            
            let checked = props.userData.certifications?.includes(certKey) ? true : false
            certs.push(
                <FormControlLabel
                    key={certKey}
                    control={
                        <Switch
                            checked={checked}
                            onChange={()=>handleCertChange(certKey)}
                            color="primary"
                        />
                    }
                    label={<div>
                        <b>{certifications[certKey].name}</b> ({certifications[certKey].value})
                    </div>}
                />
            )
        })

        if (certs.length === 0) return (<div>Something is wrong!</div>)

        return (
            <FormGroup>
                {certs}
            </FormGroup>
        )
    }

    return (

        <>

            <Typography gutterBottom className="forms-title" align="center" variant="h5">
                Select your certs.
            </Typography>
            <FormControl component="fieldset">
                <FormGroup aria-label="position">
                    {ShowCertificationSettings()}
                </FormGroup>
            </FormControl>

        </>

    )

}