import React, {useState, useEffect} from 'react'

import {
    Dialog, DialogTitle, DialogContent, FormControl,
    FormHelperText, TextField, Button, Typography, IconButton
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import {db} from '../../../utilities/fire';

// import {UpdatePlaneUser} from '../../../helpers/SaveData'

export default function EditPlaneDialog(props) {

    const { action, planeKey, fboData, activeFBOKey } = props

    
    const [newPlaneData, setNewPlaneData] = useState(null)
    const [allowSave, setAllowSave] = useState(false)
    const [identifierExists, setIdentifierExists] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    // This always runs on the page load
    useEffect(()=>{

        if (action === 'edit') {

            let {manufacturer, identifier} = fboData[activeFBOKey].planes[planeKey]
            let { model, year, rate } = fboData[activeFBOKey].planes[planeKey].planeDetails
    
            setNewPlaneData({
                manufacturer,
                identifier,
                model: model.value,
                year: year.value,
                rate: rate.value,
            })

        } else {
            
            setNewPlaneData({
                manufacturer: '',
                identifier: '',
                model: '',
                year: '',
                rate: '',
            })

        }


        console.log(fboData[activeFBOKey].planes[planeKey])
            // Set the newPlaneData to the one we clicked
            setIsLoading(false)
            
    },[action, activeFBOKey, fboData, planeKey])

    

    function handleTextChange(arg) {
        const value = arg.target.value;
        setNewPlaneData({
            ...newPlaneData,
            [arg.target.name]: value,
        })
        setAllowSave(true)
    }

    // When you press the reset button
    function handleReset() {

        // Set the data to the data in firebase
        setNewPlaneData(fboData[activeFBOKey])
        // Diable the update button
        setAllowSave(false)
        setIdentifierExists(false)
        
    }

    function handleSave() {

        let planeDoc

        if (planeKey) {
            planeDoc = db.collection('fbos').doc(activeFBOKey).collection('planes').doc(planeKey)
        } else {
            planeDoc = db.collection('fbos').doc(activeFBOKey).collection('planes').doc()
        }
  
        
        // Pick the fields from firebase we want to update
        let planeToUpdate = {
            manufacturer: newPlaneData.manufacturer,
            identifier: newPlaneData.identifier,
            planeDetails: {
                model: {
                    name: "Model",
                    value: newPlaneData.model,
                },
                year: {
                    name: "Year",
                    value: newPlaneData.year,
                },
                rate: {
                    name: "Rate",
                    value: newPlaneData.rate,
                }
            },
            users: []
        }

        // Merge the data in with the old doc
        planeDoc.set(planeToUpdate, {merge: true})

        // Get the doc id
        planeToUpdate.id = planeDoc.id
        handleUpdateLocalData(planeToUpdate)

        props.close(true)

    }

    function handleUpdateLocalData(plane) {

        // So get the data you need to update
        let newFBOData = props.fboData

        newFBOData[activeFBOKey].planes[planeKey] = plane

        // Update the FBO Data
        props.setFBOData({ ...props.fboData, ...newFBOData })

        
    }

    
    if (isLoading) return <div />

    // Otherwise return the dialog
    return (

        <Dialog className='dialog' maxWidth={'xs'} open={props.open} onClose={props.close} color="primary">

            <DialogTitle className='dialog-title' disableTypography>
                <h4 className="title"><b>{action === 'add' ? 'Add' : 'Edit'} Plane</b></h4>
                <IconButton edge='end' onClick={props.close}  >
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent className="dialog-content">

                <FormControl className="form" align="center" fullWidth={true}>

                    
                    {/* Show the email field */}
                    <FormHelperText id="identifier">Identifier</FormHelperText>
                    <TextField 
                        margin="dense" 
                        id="identifier" 
                        variant="outlined" 
                        fullWidth
                        required
                        name="identifier"
                        value={newPlaneData.identifier}
                        onChange={handleTextChange}
                        disabled={action !== 'add'}
                    />

                    <FormHelperText id="manufacturer">Manufacturer</FormHelperText>
                    <TextField 
                        margin="dense" 
                        id="manufacturer" 
                        variant="outlined" 
                        fullWidth
                        required
                        name="manufacturer"
                        value={newPlaneData.manufacturer}
                        onChange={handleTextChange}
                    />

                    <FormHelperText id="model">Model</FormHelperText>
                    <TextField 
                        margin="dense" 
                        id="model" 
                        variant="outlined" 
                        fullWidth
                        required
                        name="model"
                        value={newPlaneData.model}
                        onChange={handleTextChange}
                    />

                    <FormHelperText id="year">Year</FormHelperText>
                    <TextField 
                        margin="dense" 
                        id="year" 
                        variant="outlined" 
                        fullWidth
                        required
                        name="year"
                        value={newPlaneData.year}
                        onChange={handleTextChange}
                    />

                    <FormHelperText id="rate">$Cost/Hour</FormHelperText>
                    <TextField
                        margin="dense"
                        id="rate"
                        variant="outlined"
                        fullWidth
                        required
                        name="rate"
                        value={newPlaneData.rate}
                        onChange={handleTextChange}
                    />
                    {
                        identifierExists === true ?
                        <Typography className="fields-text">This identifier already exists, contact adminstration for assistance!</Typography>
                        : 
                        null
                    }
                    {
                        allowSave === true ?
                        <Button className="add-button" color="primary" variant="contained" onClick={()=>handleSave()}>{action === 'edit' ? 'Update' : 'Add Plane'}</Button>
                        :
                            <Button className="add-button" color="primary" variant="contained" disabled>{action === 'edit' ? 'Update' : 'Add Plane'}</Button>
                    }

                    <Button className="reset-button" color="primary" variant="outlined" onClick={()=>handleReset()}>Reset</Button>
                    
                </FormControl>

            </DialogContent>

        </Dialog>
    )
}