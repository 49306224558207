import React from 'react'
import {Button, ListItem, ListItemIcon, ListItemText} from '@material-ui/core'
import { Link } from 'react-router-dom';

export default function CustomLink(props) {

  // const { icon, primary, to } = props;

  const icon = props.page.icon
  const to = props.page.url
  const primary = props.page.commonName

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((linkProps, ref) => (
        <Link ref={ref} to={to} {...linkProps} />
      )),
    [to],
  );

  if (props.type==="ListItem") {

    return (
      <li>
        <ListItem button component={renderLink}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={primary} />
        </ListItem>
      </li>
    );
  } 

  if (props.type === "Button") {
    return (
      <Button component={renderLink} variant="contained" color="primary" size="small" fullWidth={true}>
        {primary}
      </Button> 
    )
  }
}