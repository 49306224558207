import React, {useState, useEffect} from 'react'

import _ from 'lodash'

import {
    Dialog, DialogTitle, DialogContent, Typography,
    FormControlLabel, Switch, FormGroup, CircularProgress, 
    TextField, FormControl, FormHelperText, Button, Snackbar, IconButton, Checkbox
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import CloseIcon from '@material-ui/icons/Close'
import {UpdatePlaneUser, UpdateEmployeeAsset} from '../../../helpers/SaveData'
import { employeeTypes } from '../../../defaults/employeeTypes'
import { functions, db } from '../../../utilities/fire'

/**
 * 
 * @param {Props} props -
 * - fboData - the complete fbo data object
 * - userKey - the selected user UID
 * - activeFBO - the selected fbo from the selector
 * - setFBOData - a function to set the FBO data
 * - open - a state of whether the Dialog is open or not
 * - close - a function to close the dialog
 * - type - user or employee
 * - action - either add or edit
 */
export default function UserDialog(props) {


    const updateUser = functions.httpsCallable('updateUser')
    const addUser = functions.httpsCallable('addUser')


    const defaultFormData = {
        firstName: "",
        lastName: "",
        email: "",
        phone: '',
        address: '',
        usState: '',
        zip: '',
        acceptablePilots: [],
        acceptablePlanes: []
    }

    const { fboData, userKey, setUserKey, activeFBOKey, type, action, setAction } = props
    
    const [user, setUser] = useState(defaultFormData)
    const [editNameFields, setEditNameFields] = useState(false)
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openError, setOpenError] = useState(false)
    const [openWarning, setOpenWarning] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [processing, setProcessing] = useState(false)
    
    useEffect(() => {
        
        if (userKey) {
            setUser({ ...fboData[activeFBOKey].users[userKey], uid: userKey })
        }
        setIsLoading(false)

    }, [fboData, activeFBOKey, userKey])

    /**
     * 
     * Toggles the state of the fboData and the database given the asset key
     * 
     * @param {String} asset - plane or asset
     * @param {String} key - UID of the asset
     */
    function handleUserCheckChange(asset, key) {

        // Hold the fboData temporarily
        let newfboData = fboData

        // the new person list
        let personList = []

        // If there is already a user list
        if (newfboData[activeFBOKey][asset][key]?.users) {

            // Then make a copy of it
            personList = [...newfboData[activeFBOKey][asset][key]?.users]

            // If the user list has the user in it, then remove them
            if (personList.includes(user.uid)) {

                // Pulls the user from the new array
                _.pull(personList, user.uid)

                // Updates the user's array on firebase
                UpdatePlaneUser(activeFBOKey, asset, key, user.uid, 'remove')
            
            // Otherwise the user was not in the list, so they should be added
            } else {

                // Add the user to the user list and update firebase
                personList.push(user.uid)
                UpdatePlaneUser(activeFBOKey, asset, key, user.uid, 'add')

            }
            
        // There wasn't even a user list yet, so add the user to that non-existant list
        } else {
            personList.push(user.uid)
            UpdatePlaneUser(props.activeFBOKey, asset, key, user.uid, 'add')
        }

        // Save the new user list locally
        newfboData[props.activeFBOKey][asset][key].users = personList
        
        // Update the FBO data appropriately
        props.setFBOData({...fboData, ...newfboData})

        console.log({...fboData, ...newfboData})

    }

        /**
     * 
     * Toggles the state of the fboData and the database given the plane key
     * 
     * @param {String} planeKey - UID of the plane to change
     */
    function handleEmployeeCheckChange(asset, key) {

        // Hold the fboData temporarily
        let newfboData = fboData

        // the new plane object
        let employee = {...newfboData[activeFBOKey].employees[user.uid]}

        console.log(employee)
        // If there is already an asset object
        if (newfboData[activeFBOKey].employees[user.uid][asset]) {

            let currentAssetEmployeeStatus = newfboData[activeFBOKey].employees[user.uid][asset][key]?.active 
            
            if (employee[asset][key]) {
                employee[asset][key].active = !currentAssetEmployeeStatus
            } else {
                employee[asset][key] = {active: false}
            }

            // Add the plane to the active list and update firebase
            UpdateEmployeeAsset(activeFBOKey, user.uid, asset, employee[asset])

            
            
        // There wasn't even a user list yet, so add the user to that non-existant list
        } else {
            let newAssetObject = {[key]: {active: true}}
            employee[asset] = newAssetObject
        
            UpdateEmployeeAsset(activeFBOKey, user.uid, asset, newAssetObject)
        }
        // Save the new user list locally
        newfboData[activeFBOKey].employees[user.uid] = employee
        
        // Update the FBO data appropriately
        props.setFBOData({...props.fboData, ...newfboData})

    }




    function handleTextChange(e) {
        const value = e.target.value;
        setUser({
            ...user,
            [e.target.name]: value,
        })
    }

    /**
     * 
     * @param {Object} employeeType name, key
     */
    async function handleEmployeeTypeChange(employeeType) {

        console.log(employeeType)
        
        let newFBOData = fboData
        console.log(newFBOData)

        // If the employee type exists in the employee's object
        if (newFBOData[activeFBOKey]?.employees[userKey]?.type?.[employeeType.key]) {
            
            let status = newFBOData[activeFBOKey]?.employees[userKey]?.type[employeeType.key]?.active
            console.log('changing the status')
            status = !status
            newFBOData[activeFBOKey].employees[userKey].type[employeeType.key] = { active: status }

        // Else we need to add the employee type
        } else {
            console.log(`adding ${employeeType.key} to ${userKey}`)
            newFBOData[activeFBOKey].employees[userKey].type[employeeType.key] = { active: true }
        }

        let updateField = newFBOData[activeFBOKey].employees[userKey].type
        await db.collection('fbos').doc(activeFBOKey).collection('employees').doc(userKey).update({
            type: updateField
        }).catch(e => {
            console.error(e.message)
        })

        props.setFBOData({...fboData, ...newFBOData})
    }

    /**
     * 
     * @param {String} asset The asset can be 'planes' or 'assets'
     * @param {String} key The uid of the specific asset
     * 
     * @returns {JSX} FormControlLabel of the asset
     */
    function ShowAssetSwitch(asset, key) {

        // Create the label for the asset
        let label
        if (asset === 'planes') {
            label = fboData[activeFBOKey][asset][key]?.identifier
        } else if (asset === 'assets') {
            label = fboData[activeFBOKey][asset][key].name
        }

        let checked = false

        // See if the asset is checked
        if (type === 'employee') {

            checked = !!fboData[activeFBOKey].employees[user.uid]?.[asset]?.[key]?.active

        } else {
        
            checked = !!fboData[activeFBOKey][asset][key].users?.includes(user.uid)

        }
        
        let changeHandler

        if (type === 'employee') {
            changeHandler = handleEmployeeCheckChange
        } else if (type === 'user') {
            changeHandler = handleUserCheckChange
        }
        
        // Return a JSX of the Switch
        return (
            <FormControlLabel
                key={key}
                control={
                    <Switch
                        checked={checked}
                        onChange={() => changeHandler(asset, key)}
                        color="primary"
                    />
                }
                label={label}
            />
        )
    }


    async function handleAddUser() {

        setProcessing(true)
        
        // Try to add the user
        const result = await addUser({
            email: user.email,
            addData: {
                type: type,
                fboUID: activeFBOKey
            }
        }).catch(e => {
            return console.error(e.message)
        })

        console.log(result.data)

        // If is is a new user, prompt for the names
        if (result.data.isNewUser) {

            console.log('I am a new user')
            setUser(result.data.user)
            setUserKey(result.data.user.uid)
            handleUpdateLocalData(result.data.user)
            setEditNameFields(true)
            setAction('edit')
        }
        
        // If they were already employed, inform the user 
        if (result.data.wasEmployed) {
            console.log('was emaployed')
            setOpenError(true)
            setUser(defaultFormData)
            // handleClose()
        }

        // If they were not a new user and they were not employed, inform the user
        if (!result.data.isNewUser && !result.data.wasEmployed) {
            console.log('not a new user and not employed aready')
            // Notify the user that the user exists
            setOpenWarning(true)
            handleUpdateLocalData(result.data.user)
            props.close()
        }

        // If they were not employed, but they w
        // if (!result.data.wasEmployed) {
        //     handleUpdateLocalData(result.data.user)
        // }

        setProcessing(false)
        
    }


    function handleClose() {
        setOpenSuccess(false); // Success alert
        setOpenError(false);
        setOpenWarning(false);
        
    };


    function handleUpdateLocalData(user) {

        // So get the data you need to update
        let newFBOData = fboData

        // The fboData object has all of the fbos that the LOGGED IN user can MANAGE (so here its Sandmann)
        // Then each fboData object has properties: info, users, planes, assets
        // The the users property is an object itself that has properties where each KEY is a user UID
        // But there is no key for the user that you are trying to add (because otherwise you wouldn't be adding them)
        newFBOData[activeFBOKey].users[user.uid] = user

        if (type === 'employee') {

            if (!newFBOData[activeFBOKey].employees[user.uid]) {

                newFBOData[activeFBOKey].employees[user.uid] = {
                    active: true, 
                    deleted: false,
                    planes: {},
                    type: {}
                }
            }


        }

        // This is adding the user... But does the user's fbo list have the fbo

        // Update the FBO Data
        props.setFBOData({ ...fboData, ...newFBOData })
        
    }

    async function handleSave() {

        setProcessing(true)

        const updatedUser = await updateUser({
            email: user.email, 
            newData: {
                firstName: user.firstName,
                lastName: user.lastName,

            }
        })
        
        if (updatedUser) {

            // Update the local data
            handleUpdateLocalData(updatedUser.data)
            
            // Snackbar success alert
            setOpenSuccess(true)
            
            // // Reset data to blank after submission
            // setUser(defaultFormData)
    
            // // Close the name fields
            // setEditNameFields(false)  
            
            props.close()

        } else {
            // TODO: Handle update data errors
        }

    }

    function AddPersonContent() {

        return (

            <DialogContent className="dialog-content">

            <FormControl className="form" align="center" fullWidth={true}>

                {/* Show the email field */}
                <FormHelperText id="email">Email:</FormHelperText>
                <TextField 
                    margin="dense" 
                    id="email" 
                    variant="outlined" 
                    fullWidth
                    required
                    name="email"
                    value={user.email}
                    onChange={handleTextChange}
                />


                    {processing ?
                        <CircularProgress className="circular-progress" />
                        : 
                        <>
                            <Button className="add-button" color="primary" variant="contained" disabled={!user.email.includes('@')} fullWidth onClick={()=>handleAddUser()}>Add User</Button>
                            <Button className="reset-button" color="primary" variant="outlined" fullWidth onClick={()=>handleClose()}>Reset</Button>
                        </>
                }

            </FormControl>

            </DialogContent>
        )


    }

    async function handleMangeFBOChange() {

        let managedList
        if (fboData[activeFBOKey].users[userKey]?.fbosManaged) {

            managedList = fboData[activeFBOKey].users[userKey].fbosManaged

            if (fboData[activeFBOKey].users[userKey].fbosManaged.includes(activeFBOKey)) {
                let index = managedList.indexOf(activeFBOKey)
                if (index > -1) { managedList.splice(index, 1)}
            } else {
                managedList.push(activeFBOKey)
            }
            
        } else {
            managedList = [activeFBOKey]
        }

        await db.collection('users').doc(userKey).update({
            fbosManaged: managedList
        })

        let updatedUser = {
            ...fboData[activeFBOKey].users[userKey],
            fbosManaged: managedList
        }

        let newFBOData = fboData
        newFBOData[activeFBOKey].users[userKey] = updatedUser

        props.setFBOData({...fboData, ...newFBOData})

    }

    function EditPersonContent() {

        let savedFirstName = fboData[activeFBOKey].users?.[user.uid]?.firstName
        // let savedLastName = fboData[activeFBOKey].users?.[user.uid]?.lastName

        const allowTextEdit = (!!savedFirstName && !editNameFields)

        return (
            <DialogContent className="dialog-content">

                {/* Conditionally show the firstName and lastName fields */}

                <FormControl className="form" align="center" fullWidth={true}>


                    {/* First Name Field */}
                    {!allowTextEdit && <Typography className="fields-text" variant="body2" gutterBottom>Complete the following fields!</Typography>}
                    <FormHelperText id="firstName">First Name:</FormHelperText>
                    <TextField
                        margin="dense"
                        id="firstName"
                        variant="outlined"
                        fullWidth
                        required
                        name="firstName"
                        value={user.firstName}
                        onChange={handleTextChange}
                        disabled={allowTextEdit}
                    />

                    {/* Last Name Field */}
                    <FormHelperText id="lastName">Last Name:</FormHelperText>
                    <TextField
                        margin="dense"
                        id="lastName"
                        variant="outlined"
                        fullWidth
                        required
                        name="lastName"
                        value={user.lastName}
                        onChange={handleTextChange}
                        disabled={allowTextEdit}
                    />
                    {
                        !allowTextEdit && !processing &&
                        <Button className="add-button" color="primary" variant="contained" fullWidth onClick={() => handleSave()}>Save</Button>
                    }
                    {
                        processing &&
                        <CircularProgress className="circular-progress" />
                    }

                    {type === 'employee' &&
                        <div className="switch-group">
                            Employee Type:
                            <FormGroup>
                            {employeeTypes.map(type => {
                                let checked = !!fboData[activeFBOKey]?.employees[userKey]?.type?.[type.key]?.active
                                return (
                                    <FormControlLabel
                                        key={type.key}
                                        control={
                                            <Switch
                                                checked={checked}
                                                onChange={() => handleEmployeeTypeChange(type)}
                                                color="primary"
                                            />
                                        }
                                        label={type.name}
                                    />
                                )
                            })}
                            </FormGroup>
                        </div>
                    }
                    {type === 'employee' &&
                        <div className='checkbox'>
                            <FormGroup>
                                <FormControlLabel
                                    label='Manages FBO'
                                    control={<Checkbox
                                                checked={fboData[activeFBOKey].users[userKey]?.fbosManaged?.includes(activeFBOKey)}
                                                color='primary'
                                                onChange={handleMangeFBOChange}
                                            />}
                                />
                            </FormGroup>
                        </div>
                    }
                    
                    <div className="switch-group">
                        Planes:
                        <FormGroup>

                            {Object.keys(fboData[activeFBOKey].planes).map(key => {

                                if (!fboData[activeFBOKey].planes[key].deleted) {

                                    return ShowAssetSwitch('planes', key)

                                }
                            })}
                        </FormGroup>
                    </div>

                    <div className="switch-group">
                        Assets:
                        <FormGroup>
                            {Object.keys(fboData[activeFBOKey].assets).map(key => {

                                if (!fboData[activeFBOKey].assets[key].deleted) {

                                    return ShowAssetSwitch('assets', key)

                                }
                            })}
                        </FormGroup>
                    </div>

                </FormControl>

            </DialogContent>
        )
    }

    // If the dialog isn't open, show an empty div
    if (!props.open) return <div />

    if (isLoading) return <div />

    // Otherwise return the dialog
    return (

        <Dialog className="dialog" maxWidth={'xs'} fullWidth open={props.open} onClose={props.close} color="primary">
            <DialogTitle className='dialog-title' disableTypography>
                <h4 className="title"><b>{action === 'add' ? 'Add' : 'Edit'} {type === 'employee' ? 'Employee' : 'User'}</b></h4><br/>
                <IconButton edge='end' onClick={props.close}  >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            {
                action === 'edit' &&
                    <DialogTitle disableTypography>
                    <Typography gutterBottom variant="h6" align="center">{fboData[activeFBOKey]?.users?.[user.uid]?.email}</Typography>
                    {!fboData[activeFBOKey]?.users?.[user.uid]?.confirmedEmail &&
                        <>
                            <div style={{ textAlign: 'center', color: 'red', marginTop: '10px' }}>
                                User has not signed in yet!
                            </div>
                        </>
                    }
                    </DialogTitle>
            }

            {
                action === 'add' ? AddPersonContent() : EditPersonContent()
            }


            <Snackbar open={openSuccess} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">Success!</Alert>
            </Snackbar>
            <Snackbar open={openError} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">This employee is already part of the staff!</Alert>
            </Snackbar>
            <Snackbar open={openWarning} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning">This employee already has an accout, but has been added to your staff!</Alert>
            </Snackbar>
        </Dialog>
    )
}