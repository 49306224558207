import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from "react-router-dom";
import { TextField, FormHelperText, Grid, Typography, FormControl, Paper, Button, CircularProgress } from '@material-ui/core'
import { functions, auth } from '../../utilities/fire'

export default function Authenticate(props) {

    const checkUserExists = functions.httpsCallable('checkUserExists')
    const resetPassword = functions.httpsCallable('resetPassword')

    const location = useLocation()
    const history = useHistory()

    const { userData, setUserData } = props

    // var user = firebase.auth().currentUser
    const [newPassword, setNewPassword] = useState({
        password1: '',
        password2: '',
    })
    const [email, setEmail] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const [isProcessing, setIsProcessing] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')
    const [disableSubmit, setDisableSubmit] = useState(true)
    const [passwordSetting, setPasswordSetting] = useState('reset')

    const mode = new URLSearchParams(location.search).get('mode')
    const actionCode = new URLSearchParams(location.search).get('oobCode')

    useEffect(() => {

        async function getEmail(code) {
            const email = await auth.verifyPasswordResetCode(code).catch(e => {
                console.error(e.message)
                setErrorMessage(e.message)
            })
            setEmail(email)
        }

        // If the user clicked the password reset link
        if (mode && actionCode) {

            // Verify the code and set the form to 'reset' mode
            getEmail(actionCode)
            setPasswordSetting('reset')

        } else {
            
            // Else the user is visiting the page and needs to check if their email exsists
            setPasswordSetting('check')
        }

        setIsLoading(false)

    }, [location, mode, actionCode])

    useEffect(() => {

        if (newPassword.password1.length && newPassword.password2.length) {
            if (newPassword.password1 === newPassword.password2) {
                setDisableSubmit(false)
                setErrorMessage('')
            } else {
                setDisableSubmit(true)
                setErrorMessage("Passwords don't match!")
            }
        } else {
            setDisableSubmit(true)
        }

        if (passwordSetting === 'check' && email.includes('@')) {
            setDisableSubmit(false)
        }

    }, [newPassword, passwordSetting, email])

    async function submitPassword() {
        setIsProcessing(true)

        // Confirm the reset with the actionCode from the url and their new password
        auth.confirmPasswordReset(actionCode, newPassword.password1).then(res => {
            console.log(res)
            setIsProcessing(false)

            // Then sign the user in and direct them to the profile page
            return auth.signInWithEmailAndPassword(email, newPassword.password1).then(res => {
                setUserData(res)
                history.replace('/profile')
            })
        }).catch(e => {
            console.error(e.message)
            setIsProcessing(false)
            setErrorMessage(e.message)
        })
    }

    async function handleCheckUser() {
        setIsProcessing(true)
        
        // Check if the user exists in the auth portion of firebase
        const check = await checkUserExists({ email }).catch(e => {
            console.error(e)
            setErrorMessage(e.message)
        })

        // Returns true if user exists
        if (check.data) {
            await resetPassword({ email: email }).catch(e => {
                console.error(e)
                setErrorMessage(e.message)
            })
            setSuccessMessage('Check your email for the reset link!')
        } else {
            setErrorMessage('Your email was not in the database!')
        }

        setIsProcessing(false)
    }

    if (isLoading) return <div />

    return (
        <Grid className="home-container" container direction='row' alignItems='center' justify='center' style={{ height: '100vh', backgroundColor: '#212121' }}>
            <Grid item align='center'>
                <Paper style={{ padding: '20px' }}>

                    <div>
                        <img alt='PilotFBO.com' src={require('../../images/PilotFBO.com_png.png')} style={{ maxWidth: '200px', marginBottom: '20px' }} />
                    </div>

                    <Typography variant={passwordSetting === 'check' ? 'body1' : 'h5'} gutterBottom>
                        {passwordSetting === 'check' ? 'Enter your email below:' : 'Reset Password'}
                    </Typography>

                    {passwordSetting === 'check' &&
                        <>
                            {/* <Typography variant='body1' gutterBottom>{email}</Typography> */}
                            <FormControl fullWidth>

                                <FormHelperText>Your Email</FormHelperText>
                                <TextField
                                    variant='outlined'
                                    size='small'
                                    value={email || ''}
                                    onChange={e => setEmail(e.target.value)}
                                />

                                {isProcessing ?
                                    <div style={{ marginTop: '15px' }}><CircularProgress /></div>
                                    :
                                    <>
                                        <Button variant='contained' color='primary' disabled={disableSubmit} onClick={handleCheckUser} style={{ marginTop: '15px' }}>Send Link</Button>
                                        <Button color='primary' onClick={() => history.push('/')}>Back to Login</Button>
                                    </>
                                }

                                {successMessage && <Typography style={{ marginTop: '10px', color: 'green' }}>{successMessage}</Typography>}
                                {errorMessage && <Typography style={{ marginTop: '10px', color: 'red' }}>{errorMessage}</Typography>}

                            </FormControl>
                        </>
                    }

                    {passwordSetting === 'reset' &&
                        <>
                            <Typography variant='body1' gutterBottom>{email}</Typography>
                            <FormControl fullWidth>
                                <FormHelperText>New Password</FormHelperText>
                                <TextField
                                    variant='outlined'
                                    type='password'
                                    size='small'
                                    value={newPassword.password1 || ''}
                                    onChange={e => setNewPassword({ ...newPassword, password1: e.target.value })}
                                />

                                <FormHelperText>Confirm Password</FormHelperText>
                                <TextField
                                    variant='outlined'
                                    type='password'
                                    size='small'
                                    value={newPassword.password2 || ''}
                                    onChange={e => setNewPassword({ ...newPassword, password2: e.target.value })}
                                />

                                {isProcessing ?
                                    <div><CircularProgress /></div>
                                    :
                                    <Button variant='contained' color='primary' disabled={disableSubmit} onClick={submitPassword} style={{ marginTop: '15px' }}>Submit</Button>
                                }

                                {errorMessage && <Typography style={{ marginTop: '10px', color: 'red' }}>{errorMessage}</Typography>}

                            </FormControl>
                        </>
                    }
                </Paper>
            </Grid>
        </Grid>


    )


}