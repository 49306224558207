import React, { useState } from 'react'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import MyCard from './StripeCardElement'
import { Button, FormControl, Typography } from '@material-ui/core'
import {CircularProgress} from '@material-ui/core'
import {useHistory} from 'react-router-dom'
import firebase from 'firebase'


export default function CheckoutForm(props) {

    /**
     * stripeTotal - amount in cents
     * handleLocalPayment - function to update the state of the tables
     * userData - user data object
     * eventsToPay - list of event ids that are being paid
     */
    const { userData, closeDialog, cardList, setCardList } = props

    const history = useHistory()

    const stripe = useStripe()
    const elements = useElements()

    // Error message
    const [message, setMessage] = useState('')
    const [hasSubmitted, setHasSubmitted] = useState(false)

    // Get the payment intent from the cloud function and by extension Stripe
    const retrieveIntent = firebase.functions().httpsCallable('retrieveIntent')

    const handleSubmit = async (event) => {
        // Block native form submission.
        event.preventDefault()

        setHasSubmitted(true)

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement)

        const intentResult = await retrieveIntent({stripe_cid: userData.stripe_cid})

        // Use your card Element with other Stripe.js APIs
        const result = await stripe.confirmCardSetup(intentResult.data.client_secret, {
            payment_method: {
                card: cardElement
            }
        })
        
        if (result.error) {
            setMessage(result.error.message)
            setHasSubmitted(false)
        } else {
            
            closeDialog()
            history.go(0) // force page refresh - to handle card reload
            // handleLocalPayment()
        }

    };

    return (
        <FormControl fullWidth>
            <MyCard />
            {
                !hasSubmitted &&
                <Button type="submit" variant="contained" color="primary" disabled={!stripe} onClick={handleSubmit}>
                    Register Card
                </Button>

            }   
            {   hasSubmitted && 
                <div className="checkout-form-progress">
                    <CircularProgress />
                </div>
            }
            {
                message.length > 0 &&
                    <Typography align="center" style={{color: "red", padding: "10px"}}>{message}</Typography>
            }
        </FormControl>

    );
}