import React, { useEffect, useState } from 'react'
import {
    Grid, Typography, FormControl, TextField, Button, CircularProgress, FormHelperText,
    
} from '@material-ui/core'
import { db, functions } from '../../../../utilities/fire'
import OwnershipTable from './OwnwershipTable'
import NewPersonDialog from './NewPersonDialog'
import AccountsTable from './AccountsTable'

export default function FBOFinancials(props) {

    const updateFBO = functions.httpsCallable('updateFBO')

    const addressFields = [
        { key: 'line1', label: 'Address' },
        { key: 'line2', label: 'Address 2' },
        { key: 'city', label: 'City' },
        { key: 'state', label: 'State' },
        { key: 'postal_code', label: 'ZIP Code' }
    ]

    const { fboData, activeFBOKey } = props

    const [isLoading, setIsLoading] = useState(true)
    const [needsSave, setNeedsSave] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [showNewPersonDialog, setShowNewPersonDialog] = useState(false)
    const [dialogType, setDialogType] = useState()
    const [currentUser, setCurrentUser] = useState()
    const [stripeData, setStripeData] = useState({})
    const [stripePersons, setStripePersons] = useState()

    useEffect(() => {
        // TS trying to make it appear something is loading
        setIsLoading(true)
        const timer = setTimeout(() => {
            if (fboData[activeFBOKey]?.info?.stripe) {

                setStripeData(fboData[activeFBOKey].info.stripe)
                
            } else {
                setStripeData({})
            }
            setIsLoading(false)
        }, 1000)
        GetStripeOwners(activeFBOKey).then(result => {
            setStripePersons(result)
        }) 
        return () => clearTimeout(timer)
    }, [fboData, activeFBOKey])

    useEffect(() => {
        if (stripeData !== fboData[activeFBOKey].info.stripe) {
            console.log('needs save')
            setNeedsSave(true)
        } else {
            setNeedsSave(false)
        }
    }, [stripeData])

    function GetStripeOwners(fboKey) {

        return new Promise(res => {
            db.collection('fbos').doc(fboKey).collection('stripePersons').get().then(doc => {
                let persons = []
                doc.forEach(user => {
                    persons.push(user.data())
                })
                res(persons)
            })
        })
    }

    function RunChecks(input) {

        let output = input

        if (input.business_profile.support_phone?.substring(0, 1) === '1') {
            output.business_profile.support_phone = output.business_profile.support_phone.substring(1)
        }

        return output
    }

    async function handleSave() {

        let finalSendData = RunChecks(stripeData)
        setErrorMessage('')

        setProcessing(true)
        console.log('Let us save.')
        console.log(finalSendData)

        const updateResult = await updateFBO({
            fboUID: activeFBOKey,
            newStripe: finalSendData
        }).catch(e => {
            console.error(e.message)
            setErrorMessage("Make sure that your information is formatted properly!")
        })

        setProcessing(false)

        if (updateResult) {

            let newFBOData = fboData
            newFBOData[activeFBOKey].info.stripe = updateResult.data
            props.setFBOData({...fboData, ...newFBOData})
            console.log('Result', updateResult.data)
            setNeedsSave(false)
        }
    }

    // function GetPersons() {

    // }
    
    /**
     * 
     * @param {String} type add or edit
     * @param {Object} user the stripePerson object
     */
    function HandleNewPersonDialog(type, user) {
        setDialogType(type)
        setCurrentUser(user)
        setShowNewPersonDialog(true)
    }
    
    function CloseNewPersonDialog() {
        setShowNewPersonDialog(false)
        setDialogType(null)
        setCurrentUser(null)
    }
    
    if (isLoading) return <div><CircularProgress style={{ marginTop: '100px', marginLeft: '50%' }}/></div>

    return (
        <>
            {showNewPersonDialog &&
                <NewPersonDialog
                    activeFBOKey={activeFBOKey}
                    fboData={fboData}
                    dialogType={dialogType}
                    userData={currentUser}
                    open={showNewPersonDialog}
                    close={CloseNewPersonDialog}
                    stripePersons={stripePersons}
                    setStripePersons={setStripePersons}
                />
            }
            <Grid container direction="row" justify="space-evenly" alignItems="flex-start">

                {/* Put this on top becuase on mobile you can't see the button */}
                {
                    needsSave &&                         
                        <Grid item xs={10} align="center" className="save-item">
                            <Button className="button" fullWidth color="primary" variant="contained" onClick={() => handleSave()}>Save Updates</Button>
                            {processing && <CircularProgress className="circular-progress"/>}
                            <Typography className="error">{errorMessage}</Typography>
                        </Grid >
                }

                <Grid className="fbo-settings" item xs={10} sm={10} md={5}>
                    <Typography className="title" align="center" variant="h5">
                        Your Company Data
                    </Typography>
                    <FormControl className="form" fullWidth>

                        {Object.keys(stripeData).length > 0 &&
                            // I know this isn't "correct" - but it's just a stopgap for useReducer not working properly right now
                            // Also - every "new" account from this point will have a Stripe account created for them
                            // So those FBO's without a Stripe account should be deleted
                            <>
                                {addressFields.map(field => {
                                    return (
                                        <div key={field.key}>
                                            <FormHelperText id={field.key}>{field.label}</FormHelperText>
                                            <TextField id={field.key} className="text-field" required variant="outlined" size="small" value={stripeData.company.address[field.key] || ''}
                                                onChange={e =>
                                                    setStripeData({
                                                        ...stripeData,
                                                        company: {
                                                            ...stripeData.company,
                                                            address: {
                                                                ...stripeData.company.address,
                                                                [field.key]: e.target.value
                                                            }
                                                        }
                                                    })} />
                                        </div>
                                    )
                                })}
                            <FormHelperText id="phone">Management Phone</FormHelperText>
                            <TextField
                                id="phone" className="text-field" required variant="outlined" size="small" value={stripeData.company.phone || ''}
                                onChange={e =>
                                    setStripeData({
                                        ...stripeData,
                                        company: {
                                            ...stripeData.company,
                                            phone: e.target.value
                                        }
                                    })}
                            />
                            {!stripeData.company.tax_id_provided &&
                                <>
                                    <FormHelperText id="taxid">Tax ID</FormHelperText>
                                    <TextField key={'company-taxid'} id="taxid" className="text-field" required variant="outlined" size="small" value={stripeData.company.tax_id || ''}
                                        onChange={e =>
                                            setStripeData({
                                                ...stripeData,
                                                company: {
                                                    ...stripeData.company,
                                                    tax_id: e.target.value
                                                }
                                            })}
                                    />
                                </>
                            }
                            </>
                        }
                        
                    </FormControl>
                </Grid>

                <Grid item xs={10} sm={10} md={5} className="fbo-settings">

                    <Typography className="title" align="center" variant="h5">
                        Your Support Data
                    </Typography>

                    {Object.keys(stripeData).length > 0 &&
                        <>
                            <FormControl className="form" fullWidth>

                                <FormHelperText id="support_phone">Support Phone</FormHelperText>
                                <TextField className="text-field" required variant="outlined" id="support_phone" size="small" value={stripeData.business_profile.support_phone || ''}
                                    onChange={e =>
                                        setStripeData({
                                            ...stripeData,
                                            business_profile: {
                                                ...stripeData.business_profile,
                                                support_phone: e.target.value
                                            }
                                        })}
                                />

                                <FormHelperText id="support_email">Support Email</FormHelperText>
                                <TextField className="text-field" required variant="outlined" id="support_email" size="small" value={stripeData.business_profile.support_email || ''}
                                    onChange={e =>
                                        setStripeData({
                                            ...stripeData,
                                            business_profile: {
                                                ...stripeData.business_profile,
                                                support_email: e.target.value
                                            }
                                        })} />

                                <FormHelperText id="website">Support Website</FormHelperText>
                                <TextField className="text-field" variant="outlined" id="website" size="small" value={stripeData.business_profile.support_url || ''}
                                    onChange={e =>
                                        setStripeData({
                                            ...stripeData,
                                            business_profile: {
                                                ...stripeData.business_profile,
                                                support_url: e.target.value
                                            }
                                        })} />



                            </FormControl>

                        
                            {stripeData.business_type === 'company' &&
                                <FormControl className="form">
                                    <Typography className="title" align="center" variant="h5">
                                        "{fboData[activeFBOKey].info.name}" Ownership Details
                                    </Typography>
                                    <Typography className="sub-title" align="center">
                                        You need to have at least one person fiscally responsible for the account listed here.
                                    </Typography>
                                    <Button fullWidth className="add-button" variant="contained" color="primary" onClick={() => HandleNewPersonDialog('add')}>Add Person</Button>
                                </FormControl>
                            }
                        </>
                    }

                </Grid>

                <Grid item className="ownership-table" align="center" xs={10}>

                    <OwnershipTable
                        HandleNewPersonDialog={HandleNewPersonDialog}
                        stripePersons={stripePersons}
                    />

                </Grid>

                <Grid item className="accounts-table" align="center" xs={10}>

                    <AccountsTable
                        activeFBOKey={activeFBOKey}
                    />

                </Grid>
            </Grid>
        </>
    )
}