import  { db } from '../utilities/fire'

export default function BalanceAccount(user, fboData) {

    // calculateFUTTMCharge(instructorBaseRate, planeBaseRate, pfboFee, flightDuration, groundTime, hasInstructor)
    function calculateFUTTMCharge(instructorBaseRate, planeBaseRate, pfboFee, flightDuration, groundTime, hasInstructor) {
        
        if (flightDuration === 0) {
            return 0
        } else {
            let cost = (flightDuration * planeBaseRate) + (flightDuration * instructorBaseRate * Number(hasInstructor)) + (groundTime * instructorBaseRate * Number(hasInstructor))
            let costWithFees = cost * (1 + pfboFee)
            return costWithFees
        }
    }

    return new Promise(async (res, rej) => {

        const userUID = user
        const eventsRef = db.collection('events')
        const eventsQuery = await eventsRef.where('user', '==', userUID).get()

        if (eventsQuery.empty) {
            rej(new Error('no events'))
        } 

        let eventArray = []
        eventsQuery.forEach(event => {
            let data = event.data()
            if (data.assetType !== 'blocks') {
                eventArray.push(data)
            }
        })

        let costsTotal = 0
        eventArray.forEach(async event => {

            const eventData = event
            const fcEvent = eventData.event
            const assetType = eventData.assetType
            const planeDoc = await db.collection('fbos').doc(eventData.fboID).collection(assetType).doc(fcEvent.planeUID).get()
            const planeData = planeDoc.data()

            let { hobbsStart, hobbsEnd, groundTime } = fcEvent
            const flightDuration = Math.abs(hobbsEnd - hobbsStart)

            let planeBaseRate = planeData.planeDetails.rate.value
            let instructorBaseRate = fboData[eventData.fboID].info.rates.instructors
            let pfboFee = fboData[eventData.fboID].info.rates.pfbo
            let hasInstructor = Boolean(eventData.hasInstructor)

            let flightTotal = calculateFUTTMCharge(instructorBaseRate, planeBaseRate, pfboFee, flightDuration, groundTime, hasInstructor)

            costsTotal = costsTotal + flightTotal
        })

        const paymentQuery = await db.collection('users').doc(userUID).collection('payments').where('status', '==', 'succeeded').get()

        let paymentsArray = []
        paymentQuery.forEach(payment => {
            let amount = payment.data().amount
            paymentsArray.push(amount)
        })

        let stripePaymentTotal
        if (paymentsArray.length) {
            stripePaymentTotal = paymentsArray.reduce((a, b) => a + b)
        } else {
            stripePaymentTotal = 0
        }
        const paymentsTotal = stripePaymentTotal / 100
        const netAccountBalance = costsTotal - paymentsTotal

        if (netAccountBalance) {
            let returnObj = {
                netAccountBalance: netAccountBalance,
                paymentsTotal: paymentsTotal
            }
            await db.collection('users').doc(userUID).update({ accountBalance: netAccountBalance, paymentsTotal: paymentsTotal })
            res(returnObj)
        } else {
            rej(new Error('there was a problem'))
        }
    })
}