import React, { useState } from 'react';
import {
    Drawer, AppBar, Toolbar, List, Divider, IconButton, Button
} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import EventIcon from '@material-ui/icons/Event';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SettingsIcon from '@material-ui/icons/Settings';
import SchoolIcon from '@material-ui/icons/School';

import { auth } from '../../utilities/fire.js'
import { Link, useLocation } from 'react-router-dom';
import CustomLink from '../../components/CustomLink'
// import ForgotPassword from './forgotPassword'
// import ResetPassword from './resetPassword'

export default function Navigation(props) {

    const { userData, setUserData } = props
    
    const [open, setOpen] = useState(false);

    let pageList = [
        {
            commonName: 'My Profile',
            url: '/profile',
            icon: <AccountBoxIcon />,
        },
        {
            commonName: 'Calendar',
            url: '/calendar',
            icon: <EventIcon />,
        },
        {
            commonName: 'Flights',
            url: '/flights',
            icon: <FlightTakeoffIcon />,
        },
        {
            commonName: 'Settings',
            url: '/settings',
            icon: <SettingsIcon />,
        },
        // {
        //     commonName: 'Reports',
        //     url: '/reports',
        //     icon: <AssignmentIcon />,
        // },
    ]

    if (userData?.employedBy) {
        if (userData.employedBy.length > 0) {
            pageList.push(
                {
                    commonName: 'Instructors',
                    url: '/instructors',
                    icon: <SchoolIcon />,
                },
            )
        }
    }

    if (userData?.fbosManaged) {
        if (userData.fbosManaged.length > 0) {
            pageList.push(
                {
                    commonName: 'Manage FBO',
                    url: '/manage-fbo',
                    icon: <SupervisedUserCircleIcon />,
                },
                {
                    commonName: 'Reports',
                    url: '/reports',
                    icon: <AssignmentIcon />,
                }
            )
        }
    }

    function handleSignOut() {
        auth.signOut().then(result => {
            setUserData({})
        }).catch(err => {
            console.log(err)
        })
    }

    return (
        <div className="root">

            <AppBar
                color="primary"
                position="fixed"
                className="appbar-main"
            >
                <Toolbar>
                    <IconButton edge="start" color="secondary" aria-label="menu" onClick={() => setOpen(true)}>
                        <MenuIcon />
                    </IconButton>
                    <div className='img-wrapper'>
                        <img alt="icon" className="navbar-icon" src={require('../../images/PilotFBO.com_png.png')} />
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="temporary"
                anchor="left"
                open={open}
                className="custom-drawer"
            >
                <div>
                    <IconButton className="close-drawer" onClick={() => setOpen(false)}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List className="link-list">

                    {pageList.map((page, index) => (

                        <div className="link-item" key={index} onClick={() => setOpen(false)}>
                            <CustomLink page={page} type={'ListItem'} />
                        </div>

                    ))}
                    <br />

                    <Link to="/">
                        <div className="forms">
                            <Button className="custom-button" color="primary" variant="contained" onClick={handleSignOut}>
                                Log Out
                            </Button>
                        </div>
                    </Link>
                   
                </List>
            </Drawer>
        </div>
    );
}