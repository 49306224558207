import React, {useState} from 'react'

import {
    Dialog, DialogTitle, DialogContent, Typography,
    TextField, FormControl, Button, Snackbar,
} from '@material-ui/core'

// import FlyBar from '../../../components/FlyBar'

import { Alert } from '@material-ui/lab'; 
import {db} from '../../../utilities/fire';

/**
 * 
 * @param {Props} props -
 * - userKey - the selected user object
 * - open - a state of whether the Dialog is open or not
 * - close - a function to close the dialog
 * - activeFBOKey - the selected fbo from the selector
 * - fboData - the complete fbo data object
 * - setFBOData - a function to set the FBO data
 * - type - employee or user
 */
export default function RemovePersonDialog(props) {

    const code = "REMOVE"

    const { activeFBOKey, userKey, fboData, type } = props

    const [open, setOpen] = useState(false);
    const [openError, setOpenError] = useState(false)
    const [openWarning, setOpenWarning] = useState(false)
    const [showEntry, setShowEntry] = useState(false)
    const [showFinalConfirm, setShowFinalConfirm] = useState(false)
    const [confirmField, setConfirmField] = useState('')

    // const [flyBarData, setFlyBarData] = useState(null)

    function handleTextChange(evt) {
        // Set the value to the current input
        const value = evt.target.value;

        // Set the field to value
        setConfirmField(value);   
   
        // If the value = to the code then show the final confirm button
        if (value === code) {
            setShowFinalConfirm(true)
        } else {

            // Need this because need to revert state if entry is changed != code
            setShowFinalConfirm(false)
        }
    }
    
    function handleUserFinalConfirm() {

        let fboList = fboData[activeFBOKey].users[userKey].fbos // list of fbos for the user

        let index = fboList.indexOf(activeFBOKey) // Create index of the fboList

        // If the index exists 
        if (index > -1) {
            // Remove the fbo from the list
            fboList.splice(index, 1)

            // Remove the fbo from the users db
            db.collection('users').doc(userKey).update({
                fbos: fboList
            })

            let newFBOData = fboData
            delete newFBOData[activeFBOKey].users[userKey]
            props.setFBOData({ ...fboData, ...newFBOData })
            props.close()

        } else {
            console.log('not in the fbo')
        }

    }

    function handleEmployeeFinalConfirm() {
        
        let employeeList = fboData[activeFBOKey].users[userKey].employedBy // list of fbos for the user
        
        let index = employeeList.indexOf(activeFBOKey) // Create index of the fboList

        // If the index exists 
        if (index > -1) {
            // Remove the fbo from the list
            employeeList.splice(index, 1)

            // Remove the fbo from the users db
            db.collection('users').doc(userKey).set({
                employedBy: employeeList
            }, {merge: true})

            // Remove the user from the FBO
            db.collection('fbos').doc(activeFBOKey).collection('employees').doc(userKey).update({deleted: true})

            let newFBOData = fboData
            delete newFBOData[activeFBOKey].employees[userKey]
            props.setFBOData({...fboData, ...newFBOData})
            props.close()

        } else {
            console.log('not in the fbo')
        }
    }

    function handleClose() {
        setOpen(false);
        setOpenError(false);
        setOpenWarning(false);
    };

    function handleRemoveUser() {
        setShowEntry(true)
    }

    // function handleDialogClose() {
    //     setShowEntry(false)
    //     setShowFinalConfirm(false)
    //     setConfirmField("")
    //     props.close()
    // }

    if (!props.open) return <div />

    let handleFinalConfirm

    if (type === 'user') {
        handleFinalConfirm = handleUserFinalConfirm
    } else if (type === 'employee') {
        handleFinalConfirm = handleEmployeeFinalConfirm
    }

    return (

        <Dialog className="remove-dialog" open={props.open} onClose={props.close} color="primary">
            <DialogTitle align="center">Remove this {type}?</DialogTitle>
            <DialogContent>

                <FormControl align="center">

                    <Typography gutterBottom variant='h6'>{fboData[activeFBOKey].users[userKey].firstName} {fboData[activeFBOKey].users[userKey].lastName}</Typography>
                    <div className="button-container">
                        <div className="button">
                            <Button color="primary" variant="contained" onClick={()=>handleRemoveUser()}>Confirm</Button>
                        </div>
                        <div className="button">
                            <Button color="primary" variant="outlined" onClick={()=>props.close()}>Cancel</Button>
                        </div>
                    </div>

                    {
                        showEntry === false 
                        ?
                        null
                        :
                        <div>
                            <Typography className="text" variant="body2" gutterBottom>Type in "{code}" to remove this user.</Typography>
                            <TextField
                                margin="dense" 
                                id="confirm" 
                                variant="outlined" 
                                fullWidth
                                required
                                name="confirm"
                                value={confirmField}
                                onChange={handleTextChange}
                            />

                            {
                                showFinalConfirm === true 
                                ?
                                <Button fullWidth color="secondary" variant="contained" onClick={()=>handleFinalConfirm()}>Confirm</Button>
                                :
                                <Button fullWidth disabled variant="contained">Confirm</Button>
                            }

                        </div>
                    }

                </FormControl>

            </DialogContent>
            {/* <FlyBar data={flyBarData} open={flyBarData}/> */}
            

            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">Success!</Alert>
            </Snackbar>
            <Snackbar open={openError} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">This user is already in the group!</Alert>
            </Snackbar>
            <Snackbar open={openWarning} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning">This user already has an accout, but has been added to your group!</Alert>
            </Snackbar>

        </Dialog>
        
    )
}