import React from 'react'
import { Line } from 'react-chartjs-2'

export default function LineChart(props) {

    const { labels, newData, title, suggestedMax, mainColor, secondaryColor } = props

    let data = {
        labels: labels,
        datasets: [
            {
                data: newData,
                fill: true,
                lineTension: 0.5,
                backgroundColor: secondaryColor,
                borderColor: mainColor,
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: mainColor,
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: mainColor,
                pointHoverBorderColor: '#212121',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                spanGaps: false,
                // xAxisID: xaxis,
                // yAxisID: yaxis,
            }
        ]
    }

    let options = {
        scales: {
            yAxes: [{
                ticks: {
                    suggestedMin: 0,
                    suggestedMax: suggestedMax + 1,
                    stepSize: 1,
                }
            }]
        },
        title: {
            display: true,
            text: title,
            fontStyle: 'bold',
            fontSize: 16,
        },
        legend: {
            display: false,
            labels: {
                fontColor: 'rgb(255, 99, 132)'
            }
        }
    }

    return (
        <>
            <Line
                data={data}
                options={options}
            />
        </>
    )

}