import React, { useState } from 'react';
import {
  TextField, Typography, Snackbar, FormControl, Select, MenuItem, Button, Grid, FormHelperText
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { db, auth } from '../../utilities/fire';
import { statesList } from '../../defaults/statesList';
import NotificationSettings from './components/NotificationSettings'

export default function Profile(props) {

  const { userData, setUserData, fboData } = props

  const [openSuccess, setOpenSuccess] = useState(false) // snackbar open
  const [userInfo, setUserInfo] = useState(userData)

  function handleSuccessClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
  };

  function handleUpdateUserData() {

    setUserData(userInfo)
    db.collection('users').doc(auth.currentUser.uid).update(userInfo)
    setOpenSuccess(true)

  }

  // Rewrite profile field generation
  function GenerateProfileFields() {
    const profileFields = [
      {
        label: 'First Name',
        value: 'firstName',
        type: 'textField',
      },
      {
        label: 'Last Name',
        value: 'lastName',
        type: 'textField',
      },
      {
        label: 'Email',
        value: 'email',
        type: 'textField',
      },
      {
        label: 'Phone Number',
        value: 'phone',
        type: 'textField',
      },
      {
        label: 'Address',
        value: 'address',
        type: 'textField',
      },
      {
        label: 'City',
        value: 'city',
        type: 'textField',
      },
      {
        label: 'US State',
        value: 'usState',
        type: 'select',
      },
      {
        label: 'Zip Code',
        value: 'zip',
        type: 'textField',
      },
    ]

    let fields = profileFields.map((field, index) => {
      if (field.type === 'textField') {
        return <div key={index}>
          <FormHelperText>{field.label}</FormHelperText>
          <TextField
            className="text-field"
            required
            variant="outlined"
            name="firstName"
            size="small"
            value={userInfo[field.value] || ''}
            onChange={(event) => setUserInfo({ ...userInfo, [field.value]: event.target.value })}
          />
        </div>
      }

      else {
        return <FormControl key={index} className="select-field">
          <FormHelperText>State</FormHelperText>
          <Select
            align="left"
            required
            id="state"
            labelId="state"
            name="usState"
            variant="outlined"
            value={userData[field.value] || ''}
            onChange={(event) => setUserData({ ...userData, [field.value]: event.target.value })}
          >
            {statesList.map(usState => {
              return (
                <MenuItem key={usState.abbr} value={usState.abbr}>{usState.name}</MenuItem>
              );
            })}
          </Select>
        </FormControl>
      }
    })

    return <FormControl fullWidth>
      {fields}
      <Button className='submit-button' color="primary" variant="contained" type="submit" form="form" disabled={(userData === userInfo)} onClick={() => handleUpdateUserData()} fullWidth>
        Update
      </Button>
    </FormControl>
  }

  return (
    <Grid className="main-wrapper" container direction="row" alignItems='flex-start' justify='space-evenly'>
      <Grid className="profile-forms" item xs={12} sm={12} md={6}>

        <Typography className="forms-title" align="center" variant="h5">Update your information.</Typography>

        {GenerateProfileFields()}

        <Snackbar open={openSuccess} autoHideDuration={2000} onClose={handleSuccessClose}>
          <Alert onClose={handleSuccessClose} severity="success">Success!</Alert>
        </Snackbar>

      </Grid>
      <Grid className="profile-forms" item align="center" xs={12} sm={12} md={6}>

        <Typography className="forms-title" align="center" variant="h5">Update your notification settings.</Typography><br />
        <Typography variant="body1" gutterBottom>When would you like event reminders? (Select all that apply)</Typography>

        <NotificationSettings settingType="notifications" fboData={fboData} userData={userData} setUserData={setUserData} />

      </Grid>
    </Grid>
  );
}