import {createMuiTheme} from '@material-ui/core/styles'

export const theme = createMuiTheme({

    palette: {
        // type: 'dark',
        primary: { 
            main: '#212121', 
        },
        secondary: {
            light: '#007c91',
            main: '#00acc1',
        },
        error: {
            main: '#5b1759'
        },
    },
        
})

