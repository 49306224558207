import React, { useState } from 'react'
import moment from 'moment'
import {
    TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Button, Typography, TablePagination,
    FormControl, Dialog, DialogActions, DialogTitle,
    CircularProgress,
    DialogContent,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@material-ui/core'
import { useEffect } from 'react'
import firebase from 'firebase'
import PaymentCard from '../../../components/PaymentCard'
import BalanceAccount from '../../../helpers/BalanceAccount'

//*********** Stripe ******************
import CheckoutForm from '../../../components/StripeCheckoutForm'
import { Link } from 'react-router-dom'
// ************************************

export default function FlightTableRows(props) {

    const { events, userData, setUserData, fboData } = props

    const createPayment = firebase.functions().httpsCallable('createPayment')
    // const balanceAccount = firebase.functions().httpsCallable('balanceAccount')

    // Set the flight arrays to display
    const [showFlights, setShowFlights] = useState({
        completed: [],
        pending: [],
        upcoming: [],
    })

    // Dialog state
    const [addCardOpen, setAddCardOpen] = useState(false)
    const [makePaymentOpen, setMakePaymentOpen] = useState(false)
    const [activeCard, setActiveCard] = useState(null)
    const [processingPayment, setProcessingPayment] = useState(false)
    const [paymentType , setPaymentType] = useState('full')
    const [customPaymentAmount, setCustomPaymentAmount] = useState('')
    const [paymentError, setPaymentError] = useState('')
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(0)
    const [cardList, setCardList] = useState([])

    function calculateFUTTMCost(instructor, plane, sbmFee) {
        
        const newInstructorRate = (instructor * (1 + sbmFee)).toFixed(2)
        const newPlaneRate = (plane * (1 + sbmFee)).toFixed(2)

        const feeObject = {
            instructorRate: newInstructorRate,
            planeRate: newPlaneRate
        }
        return feeObject
    }

    useEffect(() => {


        function createTableRow(type, event, fbo) {

            // Set the values for the table
            let date = String(moment(event.start).format('MM/DD h:mma'))
            let duration = ""
            if (event?.hobbsEnd && event?.hobbsStart) {
                duration = Math.abs(event.hobbsEnd - event.hobbsStart).toFixed(1)
                // duration = parseFloat(event.hobbsEnd - event.hobbsStart).toFixed(1)
            }
            let groundTime = ""
            if (event?.groundTime) {
                groundTime = parseFloat(event.groundTime).toFixed(1)
            }

            const sbmFee = fboData[fbo].info.rates.pfbo
            const baseInstructorRate = fboData[fbo].info.rates.instructors
            const basePlaneRate = parseFloat(fboData[fbo].planes[event.planeUID].planeDetails.rate.value).toFixed(2)
            const newCharges = calculateFUTTMCost(baseInstructorRate, basePlaneRate, sbmFee)
            let { instructorRate, planeRate } = newCharges
            
            // TOTAL = (FLIGHT TIME * PLANE$) + (FLIGHT TIME * INSTRUCTOR$) + (GROUND TIME * INSTRUCTOR$)
            let totalCost = ((duration * planeRate) + (duration * instructorRate * Number(Boolean(event.hasInstructor))) + (groundTime * instructorRate * Number(Boolean(event.hasInstructor)))).toFixed(2)

            let label
            if (type === "completed") {
                label = 'History'
            } else if (type === "pending") {
                label = 'Complete'
            } else if (type === "upcoming") {
                label = 'Details'
            }

            // Return the table row
            return (
                <TableRow key={event.id}>
                    <TableCell align="center">{date}</TableCell>
                    <TableCell align="center">{duration}</TableCell>
                    <TableCell align="center">{groundTime}</TableCell>
                    <TableCell align="center">${instructorRate}</TableCell>
                    <TableCell align="center">${planeRate}</TableCell>
                    <TableCell align="center">${totalCost}</TableCell>
                    <TableCell align="center">
                        <Link to={`/event/${event.id}`}>
                            <Button variant="contained" color="primary" size="small">{label}</Button>
                        </Link>
                    </TableCell>
                </TableRow>
            )
        }

        // These are all intial array holders - not implemented in the return
        let completedFlights = [] // Flights that have all details completed
        let pendingFlights = [] // Same as above, but have yet to pay
        let upcomingFlights = [] // Flights that are in the future

        events.sort((a, b) => { return moment(b.end) - moment(a.end) })

        console.log(events)
        events.forEach(event => {
            let eventTime = { start: moment(event.start), end: moment(event.end) }

            // Completed Flights 
            if (eventTime.end.isSameOrBefore(moment()) && event?.hobbsStart && event?.hobbsEnd && !event?.paid) {

                Object.keys(fboData).forEach(fbo => {

                    // If the plane exists in the fbo
                    if (fboData[fbo]?.planes[event.planeUID]) {

                        let row = createTableRow("completed", event, fbo)
                        completedFlights.push(row)

                    }
                })

                // Upcoming flights
            } else if (eventTime.end.isAfter(moment())) {

                Object.keys(fboData).forEach(fbo => {

                    // If the plane exists in the fbo
                    if (fboData[fbo]?.planes[event.planeUID]) {

                        let row = createTableRow("upcoming", event, fbo)
                        upcomingFlights.push(row)

                    }
                })

                // Pending flights
            } else if (eventTime.end.isSameOrBefore(moment()) && (!event?.hobbsStart || !event?.hobbsEnd)) {

                Object.keys(fboData).forEach(fbo => {
                    if (fboData[fbo]?.planes[event.planeUID]) {

                        let row = createTableRow("pending", event, fbo)
                        pendingFlights.push(row)

                    }
                })
            } 
        })

        setShowFlights({
            completed: completedFlights,
            pending: pendingFlights,
            upcoming: upcomingFlights,
        })

    }, [events, fboData])

        
    
    useEffect(() => {

        // Need to put inside useEffect to rid of error
        const retrievePaymentMethods = firebase.functions().httpsCallable('retrievePaymentMethods')

        async function getPaymentMethods() {

            const paymentData = await retrievePaymentMethods({ stripe_cid: userData.stripe_cid }).catch(e => {
                console.log(e.message)
            })

            if (paymentData?.data?.data) {
                setCardList(paymentData.data.data)
            } else {
                setCardList([])
            }
            return true
        }

        getPaymentMethods()
    }, [userData])

    // Handles the historic flight table number of rows displayed
    function handleChangeRowsPerPage(event) {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    // Changes pages on the historic table
    function handleChangePage(event, newPage) {
        setPage(newPage)
    }

    // Handles the dialog open/closed state
    async function handleAddCard() {

        // Not sure why this is here? Seems redundant
        // await getPaymentMethods()
        setAddCardOpen(!addCardOpen)
    }

    function handleOpenCard(card) {

        setMakePaymentOpen(true)
        setActiveCard(card)

    }

    async function payBill() {

        setProcessingPayment(true)

        let finalAmount
        let validInput = false

        if (paymentType === 'full') {
            finalAmount = userData.accountBalance * 100
            validInput = true
        } else {

            let parsedInput = parseFloat(customPaymentAmount)

            if (customPaymentAmount.length > 0 && !isNaN(parsedInput)) {
                
                finalAmount = parsedInput * 100
                validInput = true

            } else {

                setPaymentError('Your input was invalid')
                setProcessingPayment(false)

            }
        }

        if (validInput) {
    
            const payment = await createPayment({
                stripe_cid: userData.stripe_cid,
                amount: finalAmount,
                payment_method_id: activeCard.id,
                userUID: userData.uid
            })
    
            if (payment.data.status === "succeeded") {
    
                // const result = await balanceAccount({userUID: userData.uid})
                const result = await BalanceAccount(userData.uid, fboData)
                setUserData({
                    ...userData,
                    accountBalance: result.netAccountBalance,
                    paymentsTotal: result.paymentsTotal
                })
                setActiveCard(null)
                setMakePaymentOpen(false)
                setProcessingPayment(false)
    
            } else {
    
                // TODO: Show error please try again
                setPaymentError('Your payment failed. Please try again.')
                setProcessingPayment(false)
    
            }

        }

        // TODO: Add Notification of success

    }

    const tableHeader = <TableHead className="table-head">
        <TableRow className="tr">
            <TableCell className="th-cell">Date</TableCell>
            <TableCell className="th-cell">Flight Time</TableCell>
            <TableCell className="th-cell">Ground Time</TableCell>
            <TableCell className="th-cell">Instructor Rate ($/hour)</TableCell>
            <TableCell className="th-cell">Plane Rate ($/hour)</TableCell>
            {/* <TableCell className="th-cell">Flight Cost</TableCell> */}
            {/* <TableCell className="th-cell">Fees</TableCell> */}
            <TableCell className="th-cell">Total</TableCell>
            <TableCell className="th-cell">Details</TableCell>
        </TableRow>
    </TableHead>

    /**
     * Returns a set of JSX Objects representing cards
     */
    function paymentArea() {

        if (cardList.length === 0) return <CircularProgress />

        let paymentObjects = []

        cardList.forEach(card => {
            paymentObjects.push(<PaymentCard key={card.id} cardData={card} handleClick={handleOpenCard} />)
        })

        paymentObjects.push(<PaymentCard key={'add'} add first={cardList.length === 0} handleClick={setAddCardOpen} />)

        return paymentObjects


    }

    function handleSetCustomPaymentAmount(val) {

        let newVal = val.replace('$', '')

        setCustomPaymentAmount(newVal)
        setPaymentType('custom')

    }

    function changePaymentType(val) {
        setPaymentType(val)
        setCustomPaymentAmount('')
    }

    if (events.length === 0) {
        return (
            <div style={{ marginTop: '100px', width: '100%', fontWeight: '700', textAlign: 'center', fontSize: '24px' }}>
                No flights have been scheduled yet!<br />
                Check back later once an event has been scheduled.
            </div>
        )
    }
    return (
        <>
            
            <Dialog maxWidth={'xs'} fullWidth open={addCardOpen} onClose={()=>setAddCardOpen(false)}>
                <DialogTitle>Add Payment Method</DialogTitle>
                <DialogActions>
                    <CheckoutForm
                        userData={userData}
                        closeDialog={handleAddCard}
                        cardList={cardList}
                        setCardList={setCardList}
                    />
                </DialogActions>
            </Dialog>            
            <Dialog maxWidth={'xs'} fullWidth open={makePaymentOpen} onClose={()=>setMakePaymentOpen(false)}>
                <DialogTitle>Pay Your Bill</DialogTitle>
                <DialogContent className='pay-bill'>
                    <div className="owe-amount">
                        You currently owe <b>${parseFloat(userData.accountBalance).toFixed(2)}</b>
                    </div>
                    <FormControl fullWidth>
                        <RadioGroup value={paymentType} onChange={e => changePaymentType(e.target.value)}>
                            <FormControlLabel
                                className='selection'
                                value='full'
                                control={<Radio
                                    className='radio-button'
                                    color='primary'
                                    icon={<span className='unchecked-icon' />}
                                    checkedIcon={<span className='checked-icon' />}
                                />}
                                label={<Typography className='label'>Pay In Full</Typography>}
                            />
                            <FormControlLabel
                                className='selection'
                                value='custom'
                                control={<Radio
                                    className='radio-button'
                                    color='primary'
                                    icon={<span className='unchecked-icon' />}
                                    checkedIcon={<span className='checked-icon' />}
                                />}
                                label={<div className='label-container'>
                                    <Typography className='label'>Other:</Typography>
                                    <input className="custom-text" value={`$${customPaymentAmount}`} onChange={e => handleSetCustomPaymentAmount(e.target.value)}/>
                                </div>}
                            />
                        </RadioGroup>

                        {paymentError.length > 0 &&
                            <div className="payment-error">
                                {paymentError}
                            </div>
                        }

                        <div className="finalize">

                            {processingPayment ?
                                <CircularProgress />
                                :
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    disabled={userData.accountBalance === 0}
                                    onClick={() => payBill()}
                                >
                                    Submit Payment
                                </Button>
                            }

                        </div>

                    </FormControl>
                </DialogContent>
            </Dialog>
            
            {
                showFlights.pending.length > 0 &&
                <div className="log-containers">
                    <Typography gutterBottom variant="h5" align="center"><b>Pending Flights</b></Typography>
                    <TableContainer component={Paper}>
                        <Table className="table" size="small">
                            {tableHeader}
                            <TableBody>
                                {showFlights.pending}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            }

            <div className="balance" align="center">

                {userData.accountBalance !== undefined &&
                    <div>
                        <Typography gutterBottom>Your current flight balance is: </Typography>
                        <h2 className={userData.accountBalance > 0 ? "current-balance" : 'no-balance'}>${userData.accountBalance.toFixed(2)}</h2>
                    </div>
                }
               
                {userData.paymentsTotal !== undefined &&
                    <div>
                        <Typography gutterBottom>Amount you've spent so far: </Typography>
                        <h2 className="amount-spent">${userData.paymentsTotal.toFixed(2)}</h2>
                    </div>

                }

            </div>

            {cardList.length > 0 && <Typography align='center' variant='h6' gutterBottom style={{fontWeight: '700'}}>CLICK TO PAY</Typography>}
            <div className="payment-area">
                {paymentArea()}
            </div>
        

            {
                showFlights.upcoming.length > 0 &&
                    <div className="log-containers">
                        <Typography gutterBottom variant="h5" align="center"><b>Upcoming Flights</b></Typography>
                        <TableContainer component={Paper}>
                            <Table className="table" size="small">
                                {tableHeader}
                                <TableBody>
                                    {showFlights.upcoming}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
            }

            {
                showFlights.completed.length > 0 &&
                <div className="log-containers">
                    <Typography gutterBottom variant="h5" align="center"><b>Completed Flights</b></Typography>
                    <TableContainer component={Paper}>
                        <Table className="table" size="small">
                            {tableHeader}
                            <TableBody>
                                {showFlights.completed}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={showFlights.completed.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </div>
            }            
        </>
    )

}