
import { db } from '../utilities/fire'

/**
 * Fetches the relevant data from the database
 * @param {Array} fbosManaged fbos managed by the user
 * @return {Array} Returns an array of the events as a promise
 */
export default function FetchEventsForReports(fbosManaged) {

    function getFBOevents(fbo) {

        return new Promise(res => {

            db.collection('events').where('fboID', '==', fbo).get().then(events => {

                let eventList = []

                events.forEach(event => {

                    let eventData = event.data()

                    if (!eventData.deleted) {

                        let { id, title, start, end, resourceEditable, resourceIds, planeUID, groundTime, hobbsStart, hobbsEnd } = eventData.event // add "color" if wanting default color for an event
                        // NOTE: If you add color here, you can't get any other color - anywhere on the calendar

                        let user = eventData.user
                        let fboID = eventData.fboID
                        let instructor = eventData.hasInstructor
                        
                        let newEvent = {
                            id: id,
                            title: title,
                            start: start,
                            end: end,
                            // color: color,
                            resourceIds: resourceIds,
                            resourceEditable: resourceEditable,
                            planeUID: planeUID,
                            instructor: instructor,
                            groundTime: groundTime,
                            hobbsStart: hobbsStart,
                            hobbsEnd: hobbsEnd,
                            user: user, // ADDED THIS FOR REPORTS ONLY
                            fboID: fboID, // ADDED THIS FOR REPORTS ONLY
                        }
                        
                        eventList.push(newEvent)

                    }
                })

                res(eventList)

            })

        })
    }


    // The final return
    return new Promise(async (res, rej) => {

        let eventTypePromises = []

        fbosManaged.forEach(fbo => {
            eventTypePromises.push(getFBOevents(fbo))
        })

        Promise.all(eventTypePromises).then(eventList => {

            let resultEvents = []
            eventList.forEach(asset => {
                asset.forEach(event => {
                    resultEvents.push(event)
                })
                // resultEvents.push(event)
            })
            res(resultEvents)
        })
    })
}
