import React from 'react'

import {
    FormControlLabel, Switch, FormGroup, Typography, FormControl, 
} from '@material-ui/core'

import _ from 'lodash'

import {UpdateAcceptablePlanesForUser} from '../../../helpers/SaveData'


export default function PlaneSettingsSelector(props) {

    const {fboData} = props


    /**
     * Show the planes available to a user
     * 
     * @params None
     * 
     * @returns {JSX} A JSX object that has functional switchs for the user's AVAILABLE planes
     */
    function ShowPlaneSettings() {

        /**
         * 
         * Toggle the checked state of the plane and save to user's database
         * 
         * @param {String} key The plane's UID
         */
        function handlePlaneCheckChange(key) {
          
            // The new user data is a copy of the old data
            let newUserData = props.userData
  
            // An array to hold the new plane list
            let newPlaneList = []
  
            // if the User has any acceptable planes selected
            if (newUserData?.acceptablePlanes) {
  
              // The new planes list will be built from the old
              newPlaneList = [...newUserData.acceptablePlanes]
  
              // If the plane list includes the plane, it should be removed
              if (newPlaneList.includes(key)) {
  
                // Remove it and update firebase
                _.pull(newPlaneList, key)
                UpdateAcceptablePlanesForUser(props.userData.uid, key, 'remove')
  
              // Otherwise, add it to the list
              } else {
                newPlaneList.push(key)
                UpdateAcceptablePlanesForUser(props.userData.uid, key, 'add')
              }
            
              // If they didn't even have a list yet, add it to the new list and firebase
            } else {
              newPlaneList.push(key)
              UpdateAcceptablePlanesForUser(props.userData.uid, key, 'add')
            }
  
            // Update the user data object and save it locally
            newUserData.acceptablePlanes = newPlaneList
            props.setUserData({...props.userData, ...newUserData})
        }
  
        // The planes to show array (this will be JSX)
        let planes = []
  
        // For each FBO the user is a MEMBER OF
        Object.keys(fboData).forEach(fboKey => {
  
          // For each plane at the FBO
          Object.keys(fboData[fboKey].planes).forEach(planeKey => {
  
            // Check if the plane is even available to the user (as managed by the FBO manager)
            let isAvailableToUser = fboData[fboKey].planes?.[planeKey]?.users?.includes(props.userData.uid) ? true : false
  
            // If the plane is in the user's acceptable list already, it should be checked
            let checked = props.userData.acceptablePlanes?.includes(planeKey) ? true : false
            
            // If the plane is available
            if (isAvailableToUser) {

              // Add it to the visible list
              planes.push(
                <FormControlLabel 
                  key={planeKey}
                  control={
                    <Switch
                      checked={checked}
                      onChange={()=>handlePlaneCheckChange(planeKey)}
                      color="primary"
                    />
                  }
                  label={<Typography>{fboData[fboKey].planes[planeKey].identifier} <b>({fboData[fboKey].info.name})</b></Typography>}
                />
              )
            }
          })
        })
  
        // If there are no planes available, let the user know what to do
        if (planes.length === 0) return (<div>No Planes Available. Please contact your FBO Manager</div>)
  
        return (
          <FormGroup>
            {planes}
          </FormGroup>
        )
      }

    return (
        <>
            <Typography gutterBottom className="forms-title" align="center" variant="h5">
                Select your airplanes.
            </Typography>
            <FormControl component="fieldset">
                <FormGroup aria-label="position">
                    {ShowPlaneSettings()}
                </FormGroup>
            </FormControl>
        </>
    )
}