import React, { useState, useEffect } from 'react'

import {
    Typography, Button, Table, TableContainer, TableBody, TableHead, TableRow, TableCell
} from '@material-ui/core'

import AddBankAccount from './AddBankAccount'
import { db } from '../../../../utilities/fire'

export default function AccountsTable(props) {

    const { activeFBOKey } = props
    
    const [showAddBankDialog, setShowAddBankDialog] = useState(false)
    const [bankAccounts, setBankAccounts] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {

        // TODO - set the bank account info
        
        // GetBankAccounts(activeFBOKey).then(result => {
        //     console.log(result)
        //     setBankAccounts(result)
        // })

    },[activeFBOKey])

    function GetBankAccounts(fboKey) {

        // TODO - get bank accout info from db

        // return new Promise(res => {
        //     db.collection('fbos').doc(fboKey).collection('stripePersons').get().then(doc => {
        //         let persons = []
        //         doc.forEach(user => {
        //             persons.push(user.data())
        //         })
        //         res(persons)
        //     })
        // })
    }   

    function CreateTableRows(type) {

        let accounts = []

        if (type === "header") {
            return (
                <TableHead className="table-head">
                    <TableRow>
                        <TableCell className="header-cell">Type</TableCell>
                        <TableCell className="header-cell">Account Name</TableCell>
                        <TableCell className="header-cell">Edit</TableCell>
                    </TableRow>
                </TableHead>
            )
        }

        bankAccounts.forEach(account => {


            // TODO create account rows


            accounts.push(
                <TableRow key={account.id} hover={true}>
                    <TableCell className="body-cell"></TableCell>
                    <TableCell className="body-cell"></TableCell>
                    <TableCell className="body-cell">
                        <Button variant="contained" size="small" color="primary">Edit</Button>
                    </TableCell>
                </TableRow>
            )
        })

        if (type === 'body') {
            return (
                <TableBody className="table-body">
                    {accounts}
                </TableBody>
            )
        }


    }

    return (
        <>
            <AddBankAccount
                open={showAddBankDialog}
                close={()=>setShowAddBankDialog(false)}
                activeFBOKey={activeFBOKey}
            />
            
            {
                bankAccounts.length > 0 &&
                <>
                    <Typography className="accounts-title" variant="h5">Bank Accounts</Typography>
                    <TableContainer className="table-container">
                        <Table>
                            {CreateTableRows('header')}
                            {CreateTableRows('body')}
                        </Table>
                    </TableContainer>
                </>
            }

            <Button
                className="add-account"
                variant="contained"
                color="primary"
                onClick={() => setShowAddBankDialog(true)}
            >
                {bankAccounts.length > 0 ? 'Add Additional Account' : 'Add Bank Account' }
            </Button>
                
            
            
        </>
    )
}