import React, { useRef, useEffect} from 'react'
import { Editor, RichUtils, getDefaultKeyBinding } from 'draft-js'
import 'draft-js/dist/Draft.css'
import { IconButton } from '@material-ui/core'
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatSizeIcon from '@material-ui/icons/FormatSize';

export default function MyEditor(props) {

    // Create the intial editorState object
    const { editorState, setEditorState, disabled } = props

    let className = 'RichEditor-editor';
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
        if (contentState.getBlockMap().first().getType() !== 'unstyled') {
            className += ' RichEditor-hidePlaceholder';
        }
    }

    // Set the focus to the notes section
    const mainRef = useRef(null)
    useEffect(() => {
        mainRef.current.focus()
    },[])

    /**
     * @param {String} command - this is the literal keyboard command, returns "bold" for example
     * @param {Object} editorState - the editorState
     */
    function _handleKeyCommand(command, editorState) {
        const newState = RichUtils.handleKeyCommand(editorState, command)

        if (newState) {
            setEditorState(newState)
            return true
        }

        return false
    }

    /**
     * 
     * @param {number} e - the keyboard command number
     */
    function _mapKeyToEditorCommand(e) {
        if (e.keyCode === 9 /* TAB */) {
            const newEditorState = RichUtils.onTab(
                e,
                editorState,
                4, /* maxDepth */
            )
            console.log(newEditorState)
            if (newEditorState !== editorState) {
                setEditorState(newEditorState)
            }
            return
        }
        return getDefaultKeyBinding(e)
    }

    // This just helps us toggle the stuff in the editor
    function _toggleBlockType(blockType) {
        setEditorState(
            RichUtils.toggleBlockType(
                editorState,
                blockType
            )
        );
    }

    // This just helps us toggle the stuff in the editor
    function _toggleInlineStyle(inlineStyle) {
        setEditorState(
            RichUtils.toggleInlineStyle(
                editorState,
                inlineStyle
            )
        );
    }

    // Constants for the block types
    // Label is not used right now - since I changed it to a icon via material-ui
    const BLOCK_TYPES = [
        { label: 'Title', style: 'header-four', icon: <FormatSizeIcon /> },
        // { label: 'Blockquote', style: 'blockquote' },
        { label: 'Bullet', style: 'unordered-list-item', icon: <FormatListBulletedIcon />  },
        { label: '123', style: 'ordered-list-item', icon: <FormatListNumberedIcon />  },
        // { label: 'Code Block', style: 'code-block' }, // Could use for a code section
    ]

    // Creates the button for the toolbar
    function StyleButton(props) {
        function onToggle(e) {
            e.preventDefault()
            props.onToggle(props.style)
        }

        // See events.scss for further understanding here
        // This is just what draftjs did
        let className = 'RichEditor-styleButton'
        if (props.active) {
            className += 'RichEditor-activeButton'
        }

        return (
            <IconButton className={className} onMouseDown={onToggle}> 
                {props.icon}
            </IconButton>
        )
    }

    // Creating the block style control buttons
    function BlockStyleControls(props) {
        const { editorState, onToggle } = props
        const selection = editorState.getSelection()
        const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType()
        return (
            <div className="RichEditor-controls">
                {BLOCK_TYPES.map((type) =>
                    <StyleButton
                        key={type.label} // KEEP
                        active={type.style === blockType}
                        // label={type.label} // No longer using the label (still using it for the key)
                        onToggle={onToggle} // Called in the props
                        style={type.style} // Draftjs needs this for the styling
                        icon={type.icon} // The icon I imported
                    />
                )}
            </div>
        )
    }
    
    // Same song and dance with the inline styles
    var INLINE_STYLES = [
        { label: 'B', style: 'BOLD', icon: <FormatBoldIcon/> },
        { label: 'I', style: 'ITALIC', icon: <FormatItalicIcon/> },
        { label: 'U', style: 'UNDERLINE', icon: <FormatUnderlinedIcon/> },
        // { label: 'Monospace', style: 'CODE' },
    ];

    function InlineStyleControls(props) {

        const { editorState, onToggle } = props
        const currentStyle = editorState.getCurrentInlineStyle()
        return (
            <div className="RichEditor-controls">
                {INLINE_STYLES.map((type) =>
                    <StyleButton
                        key={type.label}
                        active={currentStyle.has(type.style)}
                        // label={type.label}
                        onToggle={onToggle}
                        style={type.style}
                        icon={type.icon}
                    />
                )}
            </div>
        )
    }

    function getBlockStyle(block) {
        switch (block.getType()) {
            case 'blockquote': return 'RichEditor-blockquote';
            default: return null;
        }
    }

    return (
        <div className="RichEditor-root" >
            <BlockStyleControls
                editorState={editorState}
                onToggle={_toggleBlockType}
                
            />
            <InlineStyleControls
                editorState={editorState}
                onToggle={_toggleInlineStyle}
            />
            <div className={className}>
                <Editor
                    editorState={editorState}
                    onChange={setEditorState}
                    handleKeyCommand={_handleKeyCommand}
                    keyBindingFn={_mapKeyToEditorCommand}
                    blockStyleFn={getBlockStyle}
                    placeholder="Write notes here!"
                    spellCheck={true}
                    ref={mainRef} // Focus the notes section first - not sure if even needed
                    readOnly={disabled}
                />
            </div>
        </div>
    )
}