import React from 'react'
import { CardElement } from '@stripe/react-stripe-js';

export default function MyCard() {
    
    return (
        <CardElement
            options={{
                style: {
                    base: {
                        fontSize: '18px',
                        color: '#424770',
                        '::placeholder': {
                            color: '#aab7c4',
                        },
                    },
                    invalid: {
                        color: '#9e2146',
                    },
                },
            }}
        />
    )
}
    