import React, { useState, useEffect, useLayoutEffect } from 'react'
import {
    Grid, Typography, Button, TableContainer, Table, TableHead, TableBody, TableRow, TableCell,
    Select, MenuItem, FormHelperText, FormControl, IconButton, Accordion, AccordionSummary, AccordionDetails,
    Checkbox, ListItemText, TablePagination, TableFooter, TextField
} from '@material-ui/core'

import moment from 'moment'
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import FetchEventsForReports from '../../helpers/FetchEventsForReports'

import LineChart from '../../components/Charts/LineChart'

export default function Reports(props) {

    const { userData, fboData } = props

    const [activeFBO, setActiveFBO] = useState([Object.keys(fboData)[0]])
    const [activeUser, setActiveUser] = useState([])
    const [activeEmployee, setActiveEmployee] = useState([])
    const [activePlane, setActivePlane] = useState([])
    const [events, setEvents] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(0)
    const [showCharts, setShowCharts] = useState(false)
    const [sortOldToNew, setSortOldToNew] = useState(false)
    const [filterByDates, setFilterByDates] = useState({
        start: '',
        end: '',
    })
    const [activateDateFilter, setActivateDateFilter] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    // Get the initial dimensions of the window
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
        height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    })
    const [displayStuff, setDisplayStuff] = useState(false)

    useLayoutEffect(() => {

        // handle the window resizing and how we display the filter
        function handleWindowResize() {

            setDimensions({
                width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
                height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
            })

        }

        window.addEventListener('resize', handleWindowResize)

        if (dimensions.width < 600) {
            setDisplayStuff(false)
        } else {
            setDisplayStuff(true)
        }
        return _ => {
            window.removeEventListener('resize', handleWindowResize)
        }

    }, [dimensions])
    
    useEffect(() => {

        // New helper function - needed all events for all FBO's managed
        FetchEventsForReports(userData.fbosManaged).then(result => {
            setEvents(result)
        })

    }, [userData, fboData])
    
    useEffect(() => {
        if (filterByDates.start.length && filterByDates.end.length) {
            if (moment(filterByDates.start).isSameOrBefore(filterByDates.end, 'day')) {
                setActivateDateFilter(true)
                setErrorMessage('')
            } else {
                setActivateDateFilter(false)
                setErrorMessage('Start date is after end date!')
            }
        } else {
            setActivateDateFilter(false)
            setErrorMessage('')
        }
    },[filterByDates])


    // handles removal of the chip underneath the filter
    function handleRemoveChip(user, type) {

        if (type === "user") {
            let userList = activeUser
            userList = userList.filter(id => { return user !== id })
            setActiveUser(userList)
        }

        if (type === "employee") {
            let employeeList = activeEmployee
            employeeList = employeeList.filter(id => { return user !== id })
            setActiveEmployee(employeeList)
        }

        if (type === "plane") {
            let planeList = activePlane
            planeList = planeList.filter(id => { return user !== id })
            setActivePlane(planeList)
        }
    }

    function handleFBOChange(fbo) {
        setActiveUser([])
        setActiveEmployee([])
        setActivePlane([])
        setActiveFBO([fbo])
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }

    // Changes pages on the historic table
    function handleChangePage(event, newPage) {
        setPage(newPage)
    }


    /**
     * 
     * @param {String} type upcoming OR past
     * @param {String} title The title of the calendar
     * @param {Number} showDays length of xaxis
     */
    function CreateChart(type, title, showDays) {

        let data = []
        let labels = []

        let filter = events
        filter = filter.filter(event => activeFBO.includes(event.fboID))

        if (type === "past") {
            // Change the chart direction
            for (let i = showDays; i > 0; i--) {
                let day = moment().subtract(i, 'days').format('MM/DD')
                labels.push(day)
            }

            // Get the dates from the event data
            let formattedDates = filter.map(event => moment(event.start).format('MM/DD'))

            // Get the unique ones
            // let uniqueDates = formattedDates.filter((v, i, a) => a.indexOf(v) === i)

            labels.forEach(date => {
                // Count the number of times each unique value is in the array
                let count = formattedDates.reduce((total, x) => total + (x === date), 0)
                data.push(count)
            })
        }

        if (type === "upcoming") {
            for (let i = 0; i < showDays; i++) {
                let day = moment().add(i, 'days').format('MM/DD')
                labels.push(day)
            }

            // Get the dates from the event data
            let formattedDates = filter.map(event => moment(event.start).format('MM/DD'))

            // Get the unique ones
            // let uniqueDates = formattedDates.filter((v, i, a) => a.indexOf(v) === i)

            labels.forEach(date => {
                // Count the number of times each unique value is in the array
                let count = formattedDates.reduce((total, x) => total + (x === date), 0)
                data.push(count)
            })
        }

        let mainColor
        let secondaryColor
        if (type === "upcoming") {
            mainColor = 'rgba(100, 181, 246, 1)'
            secondaryColor = 'rgba(100, 181, 246, 0.4)'
        }

        if (type === "past") {
            mainColor = 'rgba(124, 179, 66, 1)'
            secondaryColor = 'rgba(124, 179, 66, 0.4)'
        }

        if (filter.length) {
            
            return (
                <LineChart
                    newData={data}
                    labels={labels}
                    suggestedMax={Math.max(...data)}
                    mainColor={mainColor}
                    secondaryColor={secondaryColor}
                    title={title}
                />
            )
        }
    }

    /**
     * @param {String} type - fbos, users, employees, planes
     * 
     * Returns the entire select component- including menu items
     */
    function Dropdown(type) {

        let fbos = []
        let users = []
        let employees = []
        let planes = []

        // Get all the potential options for the filter by going through the active fbo object

        // Get all the users
        if (fboData[activeFBO]?.users) {
            Object.keys(fboData[activeFBO].users).forEach(user => {
                let name = `${fboData[activeFBO].users[user].firstName} ${fboData[activeFBO].users[user].lastName}`
                users.push(
                    <MenuItem className="menu-item" key={user} value={user}>
                        <Checkbox checked={activeUser.indexOf(user) > -1} />
                        <ListItemText primary={name} />
                    </MenuItem>
                )
            })
        }

        // Get all the employees
        if (fboData[activeFBO]?.employees) {
            Object.keys(fboData[activeFBO].employees).forEach(employee => {
                let name = `${fboData[activeFBO].users[employee].firstName} ${fboData[activeFBO].users[employee].lastName}`
                employees.push(
                    <MenuItem className="menu-item" key={employee} value={employee}>
                        <Checkbox checked={activeEmployee.indexOf(employee) > -1} />
                        <ListItemText primary={name}/>
                    </MenuItem>
                )
            })
        }

        // Get all the planes
        if (fboData[activeFBO]?.planes) {
            Object.keys(fboData[activeFBO].planes).forEach(plane => {
                let name = `${fboData[activeFBO].planes[plane].manufacturer} ${fboData[activeFBO].planes[plane].model} (${fboData[activeFBO].planes[plane].identifier})`
                planes.push(
                    <MenuItem className="menu-item" key={plane} value={plane}>
                        <Checkbox checked={activePlane.indexOf(plane) > -1} />
                        <ListItemText primary={name} />
                    </MenuItem>
                )
            })
        }

        // We give the user the option to look at fbos that they manage
        if (userData?.fbosManaged.length) {
            userData.fbosManaged.forEach(fbo => {
                fbos.push(
                    <MenuItem className="menu-item" key={fbo} value={fbo}>
                        {fboData[fbo].info.name}
                    </MenuItem>
                )
            })
        }

        // if the dropdown type is fbo - then return the proper select box
        if (type === "fbos") {

            return (
                <FormControl fullWidth>
                    <FormHelperText className="helper-text">FBOs</FormHelperText>
                    <Select
                        className="select"
                        variant="outlined"
                        value={activeFBO}
                        onChange={(event) => handleFBOChange(event.target.value)}
                        MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                            }
                        }}
                    >
                        {fbos}
                    </Select>
                </FormControl>
            )
        }

        // samesies - but we also have the chips underneath the select boxes
        if (type === "users") {
            
            return (
                <FormControl fullWidth>
                    <FormHelperText className="helper-text">Users</FormHelperText>
                    <Select
                        className="select"
                        variant="outlined"
                        multiple={true}
                        value={activeUser}
                        renderValue={() => activeUser.map(user => `${fboData[activeFBO].users[user].firstName} ${fboData[activeFBO].users[user].lastName} `)}
                        onChange={(event) => setActiveUser(event.target.value)}
                        MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                            }
                        }}
                    >
                        {users}
                    </Select>
                    {activeUser.map(user => {
                        let name = `${fboData[activeFBO].users[user].firstName} ${fboData[activeFBO].users[user].lastName}`
                        return (
                            <div key={user} className="active-chip">
                                <div className="label">
                                    {name}
                                </div>
                                <div className="remove">
                                    <IconButton className="icon" size="small" onClick={() => handleRemoveChip(user, "user")}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </div>
                        )
                    })}
                </FormControl>
            )

        // samesies - but we also have the chips underneath the select boxes
        } else if (type === "employees") {
            return (
                <FormControl fullWidth>
                    <FormHelperText className="helper-text">Instructors</FormHelperText>
                    <Select
                        className="select"
                        variant="outlined"
                        multiple={true}
                        value={activeEmployee}
                        renderValue={() => activeEmployee.map(user => `${fboData[activeFBO].users[user].firstName} ${fboData[activeFBO].users[user].lastName} `)}
                        onChange={(event) => setActiveEmployee(event.target.value)}
                        MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                            }
                        }}
                    >
                        {employees}
                    </Select>
                    {activeEmployee.map(user => {
                        console.log(user)
                        let name = `${fboData[activeFBO].users[user].firstName} ${fboData[activeFBO].users[user].lastName}`
                        return (
                            <div key={user} className="active-chip">
                                <div className="label">
                                    {name}
                                </div>
                                <div className="remove">
                                    <IconButton className="icon" size="small" onClick={() => handleRemoveChip(user, "employee")}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </div>
                        )
                    })}
                </FormControl>
            )
          
            // samesies - but we also have the chips underneath the select boxes
        } else if (type === "planes") {
            return (
                <FormControl fullWidth>
                    <FormHelperText className="helper-text">Planes</FormHelperText>
                    <Select
                        className="select"
                        variant="outlined"
                        multiple={true}
                        value={activePlane}
                        renderValue={() => activePlane.map(plane => `${fboData[activeFBO].planes[plane].identifier}  `)}
                        onChange={(event) => setActivePlane(event.target.value)}
                        MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                            }
                        }}
                    >
                        {planes}
                    </Select>
                    {activePlane.map(user => {
                        let name = fboData[activeFBO].planes[user].identifier
                        return (
                            <div key={user} className="active-chip">
                                <div className="label">
                                    {name}
                                </div>
                                <div className="remove">
                                    <IconButton className="icon" size="small" onClick={() => handleRemoveChip(user, "plane")}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </div>
                        )
                    })}
                </FormControl>
            )
        }
        
    }

    // Returns all flights for the active fbo
    function TableDisplay() {

        // Sort the events by date
        if (sortOldToNew) {
            // Sort oldest to newest
            events.sort((a, b) => { return moment(a.end) - moment(b.end) })
        } else {
            // Sort newest to oldest
            events.sort((a, b) => { return moment(b.end) - moment(a.end) })
        }

        let displayEvents = []
        let filter = events

        // If the activeUser state has a value - then filter the events
        if (activeUser.length) {
            filter = filter.filter(event => activeUser.includes(event.user))
        }

        // Same as above
        if (activeEmployee.length) {
            filter = filter.filter(event => activeEmployee.includes(event.instructor))
        }

        // Same
        if (activePlane.length) {
            filter = filter.filter(event => activePlane.includes(event.planeUID))
        }

        filter = filter.filter(event => activeFBO.includes(event.fboID))

        if (activateDateFilter) {
            filter = filter.filter(event => moment(event.start).isBetween(filterByDates.start, filterByDates.end))
        }

        // For each event in the filter - we then handle what is displayed in the tables
        filter.forEach(event => {

            // If the event is included in the active FBO (can't display events from other fbos)
            let start = String(moment(event.start).format('MM/DD h:mma'))
            let end = String(moment(event.end).format('MM/DD h:mma'))

            let firstName = ''
            let lastName = ''
            if (fboData[activeFBO].users[event.user]?.firstName) {
                firstName = fboData[activeFBO].users[event.user]?.firstName
            }
            if (fboData[activeFBO].users[event.user]?.lastName) {
                lastName = fboData[activeFBO].users[event.user]?.lastName
            }
            let pilot = `${firstName} ${lastName}`
            let instructor = '---'
            if (event.instructor) {

                let firstName_instructor = ''
                let lastName_instructor = ''
                if (fboData[activeFBO].users[event.instructor]?.firstName) {
                    firstName_instructor = fboData[activeFBO].users[event.instructor]?.firstName
                } else {
                    firstName_instructor = 'No Name'
                }
                if (fboData[activeFBO].users[event.instructor]?.lastName) {
                    lastName_instructor = fboData[activeFBO].users[event.instructor]?.lastName
                }
                instructor = `${firstName_instructor} ${lastName_instructor}`
            }
            let duration = ""
            if (event?.hobbsEnd && event?.hobbsStart) {
                duration = parseFloat(event.hobbsEnd - event.hobbsStart).toFixed(1)
            }
            let groundTime = ""
            if (event?.groundTime) {
                groundTime = parseFloat(event.groundTime).toFixed(1)
            }
            let plane = fboData[activeFBO].planes[event.planeUID].identifier

            displayEvents.push(
                <TableRow key={event.id} hover={true}>
                    <TableCell className="body-cell">{start}</TableCell>
                    <TableCell className="body-cell">{end}</TableCell>
                    <TableCell className="body-cell">{pilot}</TableCell>
                    <TableCell className="body-cell">{instructor}</TableCell>
                    <TableCell className="body-cell">{duration}</TableCell>
                    <TableCell className="body-cell">{groundTime}</TableCell>
                    <TableCell className="body-cell">{plane}</TableCell>
                </TableRow>
            )
        })

        return (
            <>                
                <TableContainer className="table-container">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="header-cell-start" onClick={() => setSortOldToNew(!sortOldToNew)}>
                                    <div>Start {sortOldToNew ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />}</div>
                                </TableCell>
                                <TableCell className="header-cell">End</TableCell>
                                <TableCell className="header-cell">Pilot</TableCell>
                                <TableCell className="header-cell">Instructor</TableCell>
                                <TableCell className="header-cell">Duration</TableCell>
                                <TableCell className="header-cell">Ground Time</TableCell>
                                <TableCell className="header-cell">Plane</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                rowsPerPage > 0 ? 
                                    displayEvents.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    :
                                    displayEvents
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    colSpan={7}
                                    count={displayEvents.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </>
        )
    } 

    /**
     * 
     * @param {String} type fbos, users, employees, planes
     * 
     * @returns {Table} the summary table above flights table
     */
    function SummaryDisplay(type) {

        let pilots = []
        let employees = []
        let all = []
        let planes = []

        events.sort((a, b) => { return moment(b.end) - moment(a.end) })

        let filter = events


        let userFilter
        // If the activeUser state has a value - then filter the events
        if (activeUser.length) {
            userFilter = filter.filter(event => activeUser.includes(event.user))
            userFilter = userFilter.filter(event => activeFBO.includes(event.fboID))
        }

        let employeeFilter
        if (activeEmployee.length) {
            employeeFilter = filter.filter(event => activeEmployee.includes(event.instructor))
            employeeFilter = employeeFilter.filter(event => activeFBO.includes(event.fboID))
        }

        let planeFilter 
        if (activePlane.length) {
            planeFilter = filter.filter(event => activePlane.includes(event.planeUID))
            planeFilter = planeFilter.filter(event => activeFBO.includes(event.fboID))
        }

        let allFilter
        allFilter = filter.filter(event => activeFBO.includes(event.fboID))

        activeUser.forEach(user => {

            let name = `${fboData[activeFBO].users[user].firstName} ${fboData[activeFBO].users[user].lastName}` // Get the users name

            let count = userFilter.filter(event => event.user === user).length // Get the number of flights for the active user

            let flightsToDate = userFilter.filter(event => event.user === user)
            let diff = ''
            if (flightsToDate.length) {
                let pastEvents = flightsToDate.filter(event => moment().isSameOrAfter(moment(event.start))) // Get all events from today or prior
                diff = moment().diff(moment(pastEvents[0].start), 'days') // Get the days difference from the nearest event
            } 
            
            let flightHours
            let flightsToSum = userFilter.filter(event => event.user === user && event.hobbsStart && event.hobbsEnd)
            if (flightsToSum.length) {
                flightHours = flightsToSum.reduce((total, event) => total + (event.hobbsEnd - event.hobbsStart), 0).toFixed(1)
            }

            pilots.push(
                <TableRow key={user} hover={true}>
                    <TableCell className="body-cell">{name}</TableCell>
                    <TableCell className="body-cell">{count}</TableCell>
                    <TableCell className="body-cell">{diff}</TableCell>
                    <TableCell className="body-cell">{flightHours}</TableCell>
                </TableRow>
            )
        })

        activeEmployee.forEach(user => {

            let name = `${fboData[activeFBO].users[user].firstName} ${fboData[activeFBO].users[user].lastName}` // Get the users name

            let count = employeeFilter.filter(event => event.instructor === user).length // Get the number of flights for the active user

            let flightsToDate = employeeFilter.filter(event => event.instructor === user)
            let diff = ''
            if (flightsToDate.length) {
                let pastEvents = flightsToDate.filter(event => moment().isSameOrAfter(moment(event.start))) // Get all events from today or prior
                diff = moment().diff(moment(pastEvents[0].start), 'days') // Get the days difference from the nearest event
            }

            let flightHours
            let flightsToSum = employeeFilter.filter(event => event.instructor === user && event.hobbsStart && event.hobbsEnd)
            if (flightsToSum.length) {
                flightHours = flightsToSum.reduce((total, event) => total + (event.hobbsEnd - event.hobbsStart), 0).toFixed(1)
            }

            employees.push(
                <TableRow key={user} hover={true}>
                    <TableCell className="body-cell">{name}</TableCell>
                    <TableCell className="body-cell">{count}</TableCell>
                    <TableCell className="body-cell">{diff}</TableCell>
                    <TableCell className="body-cell">{flightHours}</TableCell>
                </TableRow>
            )
        })

        activePlane.forEach(plane => {

            let name = `${fboData[activeFBO].planes[plane].identifier}` // Get the users name

            let count = planeFilter.filter(event => event.planeUID === plane).length // Get the number of flights for the active user

            let flightsToDate = planeFilter.filter(event => event.planeUID === plane)
            let diff = ''
            if (flightsToDate.length) {
                let pastEvents = flightsToDate.filter(event => moment().isSameOrAfter(moment(event.start))) // Get all events from today or prior
                diff = moment().diff(moment(pastEvents[0].start), 'days') // Get the days difference from the nearest event
            }

            let flightHours
            let flightsToSum = planeFilter.filter(event => event.planeUID === plane && event.hobbsStart && event.hobbsEnd)
            if (flightsToSum.length) {
                flightHours = flightsToSum.reduce((total, event) => total + (event.hobbsEnd - event.hobbsStart), 0).toFixed(1)
            }

            planes.push(
                <TableRow key={plane} hover={true}>
                    <TableCell className="body-cell">{name}</TableCell>
                    <TableCell className="body-cell">{count}</TableCell>
                    <TableCell className="body-cell">{diff}</TableCell>
                    <TableCell className="body-cell">{flightHours}</TableCell>
                </TableRow>
            )
        })

        activeFBO.forEach(fbo => {
            let name = `${fboData[fbo].info.name}`
            let count = allFilter.length
            let flightsToSum = allFilter.filter(event => event.hobbsStart && event.hobbsEnd)
            let flightHours = flightsToSum.reduce((total, event) => total + (event.hobbsEnd - event.hobbsStart), 0).toFixed(1)

            all.push(
                <TableRow key={fbo} hover={true}>
                    <TableCell className="body-cell">{name}</TableCell>
                    <TableCell className="body-cell">{count}</TableCell>
                    <TableCell className="body-cell">{flightHours}</TableCell>
                </TableRow>
            )
        })

        if (type === "pilots" && pilots.length) {

            return (
                <TableContainer className="table-container">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="header-cell">Pilot</TableCell>
                                <TableCell className="header-cell">Total Flights</TableCell>
                                <TableCell className="header-cell">Days Since Last Flight</TableCell>
                                <TableCell className="header-cell">Flight Hours</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pilots}
                        </TableBody>
                    </Table>
                </TableContainer>
            )
        }

        if (type === "employees" && employees.length) {

            return (
                <TableContainer className="table-container">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="header-cell">Instructor</TableCell>
                                <TableCell className="header-cell">Total Flights</TableCell>
                                <TableCell className="header-cell">Days Since Last Flight</TableCell>
                                <TableCell className="header-cell">Flight Hours</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {employees}
                        </TableBody>
                    </Table>
                </TableContainer>
            )
        }

        if (type === "planes" && planes.length) {

            return (
                <TableContainer className="table-container">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="header-cell">Instructor</TableCell>
                                <TableCell className="header-cell">Total Flights</TableCell>
                                <TableCell className="header-cell">Days Since Last Flight</TableCell>
                                <TableCell className="header-cell">Flight Hours</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {planes}
                        </TableBody>
                    </Table>
                </TableContainer>
            )
        }

        if (type === "all" && all.length) {
            return (
                <TableContainer className="table-container">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="header-cell">FBO Name</TableCell>
                                <TableCell className="header-cell">Total Flights</TableCell>
                                <TableCell className="header-cell">Flight Hours</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {all}
                        </TableBody>
                    </Table>
                </TableContainer>
            )
        }
    }

    function DropDownContainer() {
        return (
            <div className="dropdown-container">
                {Dropdown("fbos")}
                {Dropdown("users")}
                {Dropdown("employees")}
                {Dropdown("planes")}
                <Button className="show-charts-button" variant={showCharts ? 'outlined' : 'contained'} color="primary" onClick={() => setShowCharts(!showCharts)}>
                    {showCharts ? 'Hide' : 'Show'} Flight Charts
                                </Button>
                <FormControl className='dates' fullWidth>
                    <Typography className='date-title'>Filter By Date</Typography>
                    <FormHelperText className='helper'>Start</FormHelperText>
                    <TextField
                        className='date-selector'
                        value={filterByDates.start}
                        type='date'
                        variant='outlined'
                        size='small'
                        onChange={e => setFilterByDates({ ...filterByDates, start: e.target.value })}
                    />

                    <FormHelperText className='helper'>End</FormHelperText>
                    <TextField
                        className='date-selector'
                        value={filterByDates.end}
                        type='date'
                        variant='outlined'
                        size='small'
                        onChange={e => setFilterByDates({ ...filterByDates, end: e.target.value })}
                    />

                    <Typography className='error-message'>{errorMessage}</Typography>
                </FormControl>
            </div>
        )
    }

    return (
        <>
            <Grid className="reports-container" container direction="row" alignItems="stretch" justify="center">
                <Grid className="selectors" xs={12} sm={3} item>
                    <Typography className="title" variant="h5" align="center">Filter Reports</Typography>
                    {
                        displayStuff ? 
                            DropDownContainer()
                            :
                            <Accordion style={{margin: '10px'}}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6">Filter Display Options</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {DropDownContainer()}
                                </AccordionDetails>
                            </Accordion>
                            
                    }
                </Grid>
                <Grid item xs={12} sm={9}>
                    {SummaryDisplay("all")}
                    {
                        showCharts &&
                            <Grid container direction="row" alignItems="center" justify="space-around">
                                {/* <Typography>Flight Trends</Typography> */}
                                <Grid item xs sm={6}>
                                    {CreateChart("past", "Flight History (Last 14 Days)", 14)}
                                </Grid>
                                <Grid item xs sm={6}>
                                    {CreateChart("upcoming", "Upcoming Flights (Next 14 Days)", 14)}
                                </Grid>
                            </Grid>
                    }
                    {SummaryDisplay("pilots")}
                    {SummaryDisplay("employees")}
                    {SummaryDisplay("planes")}
                    {TableDisplay()}
                </Grid>
            </Grid>
        </>
    )
}