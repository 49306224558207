

import firebase, { db } from '../utilities/fire'

/**
 * 
 * Adds a user to the list of users for a given plane
 * 
 * @param {String} fbo A FBO UID from firebase
 * @param {String} user The user key to add or remove
 * @param {String} asset An asset type from firebase that belongs to the FBO (planes or assets)
 * @param {String} newAsset The object of the asset to be set: Firebase will update {asset: newAsset}
 * @param {String} changeType - If it is 'add', the array is appended, otherwise the key is removed 
 */
export function UpdateEmployeeAsset(fbo, user, asset, newAsset) {

    db.collection('fbos').doc(fbo).collection('employees').doc(user).update({
        [asset]: newAsset
    })

}

/**
 * 
 * Adds a user to the list of users for a given plane
 * 
 * @param {String} fbo A FBO UID from firebase
 * @param {String} asset An asset type from firebase that belongs to the FBO (planes or assets)
 * @param {String} key The key of the asset (firebase UID)
 * @param {String} user The user key to add or remove
 * @param {String} changeType - If it is 'add', the array is appended, otherwise the key is removed 
 */
export function UpdatePlaneUser(fbo, asset, key, user, changeType) {

    db.collection('fbos').doc(fbo).collection(asset).doc(key).update({
        users: changeType === 'add' ? firebase.firestore.FieldValue.arrayUnion(user) : firebase.firestore.FieldValue.arrayRemove(user)
    })

}

/**
 * 
 * Adds a plane to acceptable identifiers
 * 
 * @param {String} userUID A user's UID
 * @param {String} planeUID A plane's uid
 * @param {String} changeType if it is 'add' then adds to array unition
 */
export function UpdateAcceptablePlanesForUser(userUID, planeUID, changeType) {

    db.collection('users').doc(userUID).update({
        acceptablePlanes: changeType === 'add' ? firebase.firestore.FieldValue.arrayUnion(planeUID) : firebase.firestore.FieldValue.arrayRemove(planeUID)
    })

}

/**
 * 
 * Adds a pilot to acceptable identifiers
 * 
 * @param {String} userUID A user's UID
 * @param {String} planeUID A plane's uid
 * @param {String} changeType if it is 'add' then adds to array unition
 */
export function UpdateAcceptablePilotsForUser(userUID, planeUID, changeType) {

    db.collection('users').doc(userUID).update({
        acceptablePilots: changeType === 'add' ? firebase.firestore.FieldValue.arrayUnion(planeUID) : firebase.firestore.FieldValue.arrayRemove(planeUID)
    })

}

/**
 * 
 * Adds a certification to acceptable identifiers
 * 
 * @param {String} userUID A user's UID
 * @param {String} certUID A plane's uid
 * @param {String} changeType if it is 'add' then adds to array unition
 */
export function UpdateCertificationsForUser(userUID, certUID, changeType) {

    db.collection('users').doc(userUID).update({
        certifications: changeType === 'add' ? firebase.firestore.FieldValue.arrayUnion(certUID) : firebase.firestore.FieldValue.arrayRemove(certUID)
    })

}

/**
 * 
 * Adds a certification to acceptable identifiers
 * 
 * @param {String} userUID A user's UID
 * @param {String} notificationUID A plane's uid
 * @param {String} changeType if it is 'add' then adds to array unition
 */
export function UpdateNotificationsForUser(userUID, notificationUID, changeType) {

    db.collection('users').doc(userUID).update({
        notifications: changeType === 'add' ? firebase.firestore.FieldValue.arrayUnion(notificationUID) : firebase.firestore.FieldValue.arrayRemove(notificationUID)
    })

}

/**
 * 
 * @param {Object} event A calendar event object
 * @param {String} user The UID of the user who created the event
 * @param {String} assetType The type of asset - planes or assets
 * @param {String} fboID  The UID of the FBO 
 * @param {String/Boolean} hasInstructor False if there is no instructor or the instructor uid if there is
 * 
 * @returns {String} The new event's UID in the database
 */
export function AddEvent(event, user, assetType, fboID, hasInstructor) {

    return new Promise((res, rej) => {

        // Create a new document reference early so you can get the ID
        const newDoc = db.collection('events').doc()
    
        // Save the new event and add its new ID
        let newEvent = event
        newEvent.id = newDoc.id
    
        // Set the new event in the database
        newDoc.set({
            event: newEvent,
            user: user,
            assetType: assetType,
            fboID: fboID,
            createdOn: firebase.firestore.FieldValue.serverTimestamp(),
            hasInstructor: hasInstructor
        }, {merge: true}).then(() => {
    
            // After the event has been added, return its added ID
            res(newEvent.id)
        }).catch(err => {
    
            // If there is an error, return the code and a null
            console.log('Adding Error: Code 34A')
            rej('Error')
        })
    })

}

/**
 * @param {Object} eventID The database reference of the current event
 * @param {Object} event A calendar event object
 * @param {String} user The UID of the user who editted the event
 * 
 * @returns {Boolean} If there is a good update, returns true
 */
export function UpdateEvent(eventID, event, user) {

    return new Promise((res, rej) => {

        console.log('updating')
        console.log(event)

        // Update the database document
        db.collection('events').doc(eventID).update({
            event: event,
            edittedBy: firebase.firestore.FieldValue.arrayUnion(user)
        }).then(() => {
    
            // After the event has been added, return its added ID
            res(true)

        }).catch(err => {
    
            // If there is an error, return the code and a null
            console.log('Adding Error: Code 34A')
            console.log(err)
            rej(false)
        })
    })

}
/**
 * @param {Object} eventID The database reference of the current event
 * @param {String} user The UID of the user who editted the event
 * 
 * @returns {Boolean} If there is a good update, returns true
 */
export function DeleteEvent(eventID, user) {

    return new Promise((res, rej) => {

        console.log('updating')
        console.log(eventID)

        // Update the database document
        db.collection('events').doc(eventID).update({
            deleted: true,
            edittedBy: firebase.firestore.FieldValue.arrayUnion(user)
        }).then(() => {
    
            // After the event has been editted, return its added ID
            res(true)

        }).catch(err => {
    
            // If there is an error, return the code and a null
            console.log('Adding Error: Code 34B')
            console.log(err)
            rej(false)
        })
    })

}

/**
 * 
 * @param {Object} newFBO - contains the fbo name, a singular plane, and the new(?) user details
 * @param {String} userID - the doc id for the user
 * 
 * @returns {Array} [(String) new fbo id, (Object) user data]
 */
export function CreateFBO(newFBO, userID) {

    return new Promise( async (res, rej) => {
        
        let fboData = newFBO

        const createFBO = await db.collection('fbos').doc()
            
        createFBO.set({
            name: fboData.orgName,
            showUnavailablePlanes: true,
        }).catch(err => {
            console.error(err.message)
            rej(err)
        })

        fboData.id = createFBO.id

        const createPlanes = await db.collection('fbos').doc(fboData.id).collection('planes').doc()
            
        createPlanes.set({
            deleted: false,
            identifier: fboData.identifier,
            manufacturer: fboData.manufacturer,
            planeDetails: {
                model: {
                    name: "Model",
                    value: fboData.model,
                },
                rate: {
                    name: "Rate",
                    value: 0,
                },
                year: {
                    name: "Year",
                    value: fboData.year,
                }
            },
            users: [userID]
        }, { merge: true }).catch(err => {
            console.error(err.message)
            rej(err)
        })

        const createEmployees = db.collection('fbos').doc(fboData.id).collection('employees').doc(userID)
            
        createEmployees.set({
            active: true,
            deleted: false,
            planes: {},
            type: {},
        }, { merge: true }).catch(err => {
            console.error(err.message)
            rej(err)
        })

        db.collection('users').doc(userID).get().then(doc => {

            let newFBOarray = doc.data().fbos
            let newFBOmanaged = doc.data().fbosManaged || []
            newFBOarray.push(fboData.id)
            newFBOmanaged.push(fboData.id)

            db.collection('users').doc(userID).update({
                fbos: newFBOarray,
                fbosManaged: newFBOmanaged
            })

            res([fboData.id, doc.data()])

        }).catch(err => {
            console.error(err.message)
            rej(err)
        })

    })
}