import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
    TableRow, TableCell, TableHead, TableContainer, Typography, Table, Button, TablePagination, Paper, TableBody, Grid
} from '@material-ui/core'
import FetchInstructorEvents from '../../helpers/FetchInstructorEvents'
import moment from 'moment'

export default function Instructors(props) {

    const { userData, fboData } = props 

    const [isLoading, setIsLoading] = useState(true)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(0)
    const [events, setEvents] = useState([])
    const [showFlights, setShowFlights] = useState({
        past: [],
        upcoming: [],
        pending: []
    })
    
    const tableHeader = <TableHead className="table-head">
        <TableRow className="tr">
            <TableCell className="th-cell">Date</TableCell>
            <TableCell className='th-cell'>Student</TableCell>
            <TableCell className="th-cell">Flight Time</TableCell>
            <TableCell className="th-cell">Ground Time</TableCell>
            <TableCell className="th-cell">Details</TableCell>
        </TableRow>
    </TableHead>

    useEffect(() => {
    
        FetchInstructorEvents(userData.uid).then(result => {
            setEvents(result)
            setIsLoading(false)
        })    

    }, [userData])

    useEffect(() => {

        /**
         * 
         * @param {String} type pending, past, upcoming
         * @param {Object} event the event object
         * @param {Key} fbo the fob the event exists in
         */
        function createTableRow(type, event, fbo) {

            let date = String(moment(event.start).format('MM/DD h:mma'))
            let duration = ""
            if (event?.hobbsEnd && event?.hobbsStart) {
                duration = parseFloat(event.hobbsEnd - event.hobbsStart).toFixed(1)
            }
            let groundTime = ""
            if (event?.groundTime) {
                groundTime = parseFloat(event.groundTime).toFixed(1)
            }

            let name = `${fboData[fbo].users[event.user].firstName} ${fboData[fbo].users[event.user].lastName}`

            return (
                <TableRow key={event.id}>
                    <TableCell align="center">{date}</TableCell>
                    <TableCell align="center">{name}</TableCell>
                    <TableCell align="center">{duration}</TableCell>
                    <TableCell align="center">{groundTime}</TableCell>
                    <TableCell align="center">
                        <Link to={`/event/${event.id}`}>
                            <Button variant="contained" color="primary" size="small">Details</Button>
                        </Link>
                    </TableCell>
                </TableRow>
            )
        }

        // These are all intial array holders - not implemented in the return
        let pastFlights = [] // Flights that have all details completed
        let upcomingFlights = [] // Flights that are in the future
        let pendingFlights = [] // Flights that have not been updated

        events.sort((a, b) => { return moment(b.end) - moment(a.end) })

        events.forEach(event => { 

            let eventTime = { start: moment(event.start), end: moment(event.end) }

            // past Flights 
            if (eventTime.end.isSameOrBefore(moment()) && event?.hobbsStart && event?.hobbsEnd && !event?.paid) {

                Object.keys(fboData).forEach(fbo => {

                    // If the plane exists in the fbo
                    if (fboData[fbo]?.planes[event.planeUID]) {

                        let row = createTableRow("past", event, fbo)
                        pastFlights.push(row)
                    }
                })

                // Upcoming flights
            } else if (eventTime.end.isAfter(moment())) {

                Object.keys(fboData).forEach(fbo => {

                    // If the plane exists in the fbo
                    if (fboData[fbo]?.planes[event.planeUID]) {

                        let row = createTableRow("upcoming", event, fbo)
                        upcomingFlights.push(row)
                    }
                })

                // Pending flights
            } else if (eventTime.end.isSameOrBefore(moment()) && (!event?.hobbsStart || !event?.hobbsEnd)) {

                Object.keys(fboData).forEach(fbo => {
                    if (fboData[fbo]?.planes[event.planeUID]) {

                        let row = createTableRow("pending", event, fbo)
                        pendingFlights.push(row)
                    }
                })
            } 
        })

        setShowFlights({
            past: pastFlights,
            upcoming: upcomingFlights,
            pending: pendingFlights,
        })

    }, [events])
    
    // Handles the historic flight table number of rows displayed
    function handleChangeRowsPerPage(event) {
        setRowsPerPage(parseInt(event.target.value, 10))
        // setPage(0)
    }

    // Changes pages on the historic table
    function handleChangePage(event, newPage) {
        setPage(newPage)
    }

    if (isLoading) return <div/>

    if (events.length === 0) {
        return (
            <div style={{ marginTop: '100px', width: '100%', fontWeight: '700', textAlign: 'center', fontSize: '24px' }}>
                You don't have any lessons scheduled yet!<br />
                Check back later once a student books a lesson.
            </div>
        )
    }
    
    return (
        
        <Grid container className='flight-forms' direction='row' alignItems='center' justify='center'>
            <Grid item xs sm md={10} >

                {
                    showFlights.pending.length > 0 &&
                    <div className="log-containers">
                        <Typography gutterBottom variant="h5" align="center"><b>Pending Flights</b></Typography>
                        <TableContainer component={Paper}>
                            <Table className="table" size="small">
                                {tableHeader}
                                <TableBody>
                                    {showFlights.pending}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                }

                {
                    showFlights.upcoming.length > 0 &&
                    <div className="log-containers">
                        <Typography gutterBottom variant="h5" align="center"><b>Upcoming Lessons</b></Typography>
                        <TableContainer component={Paper}>
                            <Table className="table" size="small">
                                {tableHeader}
                                <TableBody>
                                    {showFlights.upcoming}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                }

                {
                    showFlights.past.length > 0 &&
                    <div className="log-containers">
                        <Typography gutterBottom variant="h5" align="center"><b>Completed Lessons</b></Typography>
                        <TableContainer component={Paper}>
                            <Table className="table" size="small">
                                {tableHeader}
                                <TableBody>
                                    {showFlights.past}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={showFlights.past.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </div>
                }   
            </Grid>
        </Grid>
    )
}