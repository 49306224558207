import React, {useState, useEffect} from 'react'

import {
    Dialog, DialogTitle, DialogContent, FormControl,
    FormHelperText, TextField, Button, IconButton,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { db } from '../../../utilities/fire';

// import {UpdatePlaneUser} from '../../../helpers/SaveData'

export default function EditAssetDialog(props) {


    const { action, assetKey, fboData, activeFBOKey } = props

    
    const [newAssetData, setNewAssetData] = useState({
        name: '',
        capacity: '',
    })
    const [allowSave, setAllowSave] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    // This always runs on the page load
    useEffect(()=>{

        // When the dialog opens
        if(action === 'edit') {

            // Set the newPlaneData to the one we clicked
            setNewAssetData({
                name: fboData[activeFBOKey].assets[assetKey].name,
                capacity: fboData[activeFBOKey].assets[assetKey].capacity,
            })
            
        } 

        setIsLoading(false)

    },[action, activeFBOKey, assetKey, fboData])

    

    function handleTextChange(arg) {
        const value = arg.target.value;
        setNewAssetData({
            ...newAssetData,
            [arg.target.name]: value,
        })
        setAllowSave(true)
    }

    // When you press the reset button
    function handleReset() {

        // Set the data to the data in firebase
        setNewAssetData({
            name: fboData[activeFBOKey].assets[assetKey].name,
            capacity: fboData[activeFBOKey].assets[assetKey].capacity,
        })
        // Diable the update button
        setAllowSave(false)
        
    }

    function handleSave() {
  
        let assetDoc 
        
        if (assetKey) {
            
            assetDoc = db.collection('fbos').doc(activeFBOKey).collection('assets').doc(assetKey)

        } else {

            assetDoc = db.collection('fbos').doc(activeFBOKey).collection('assets').doc()

        }
        
        // Pick the fields from firebase we want to update


        // Merge the data in with the old doc
        assetDoc.set(newAssetData, {merge: true})

        // Get the doc id
        handleUpdateLocalData({...newAssetData, id: assetDoc.id})

        props.close(true)

    }

    function handleUpdateLocalData(asset) {

        console.log(fboData)

        // So get the data you need to update
        let newFBOData = fboData

        newFBOData[activeFBOKey].assets[asset.id] = asset

        console.log(newFBOData)

        // Update the FBO Data
        props.setFBOData({ ...props.fboData, ...newFBOData })

        
    }

    console.log(newAssetData)

    // If the dialog isn't open, show an empty div
    if (isLoading) return <div />

    // Otherwise return the dialog
    return (

        <Dialog className='dialog' maxWidth={'xs'} open={props.open} onClose={props.close} color="primary">

            <DialogTitle className='dialog-title' disableTypography>
                <h4 className="title"><b>{action === 'add' ? 'Add' : 'Edit'} Asset</b></h4>
                <IconButton edge='end' onClick={props.close}  >
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>

            <DialogContent className="dialog-content">

                <FormControl className="form" align="center" fullWidth={true}>
                    {/* Show the email field */}
                    <FormHelperText id="name">Name</FormHelperText>
                    <TextField 
                        margin="dense" 
                        id="name" 
                        variant="outlined" 
                        fullWidth
                        required
                        name="name"
                        value={newAssetData.name}
                        onChange={handleTextChange}
                    />

                    <FormHelperText id="capacity">Capacity</FormHelperText>
                    <TextField 
                        margin="dense" 
                        id="capacity" 
                        variant="outlined" 
                        fullWidth
                        required
                        name="capacity"
                        value={newAssetData.capacity}
                        onChange={handleTextChange}
                    />

                    {
                        allowSave === true ?
                        <Button className="add-button" color="primary" variant="contained" onClick={()=>handleSave()}>{action === 'edit' ? 'Update' : 'Add'} Asset</Button>
                        :
                            <Button className="add-button" color="primary" variant="contained" disabled>{action === 'edit' ? 'Update' : 'Add'} Asset</Button>
                    }

                    <Button className="reset-button" color="primary" variant="outlined" onClick={()=>handleReset()}>Reset</Button>
                    
                </FormControl>

            </DialogContent>

        </Dialog>
    )
}