const cardMap = {
    visa: {
        light: {
            src: 'https://firebasestorage.googleapis.com/v0/b/lets-fly-2020.appspot.com/o/site-media%2Fcredit-card-images%2Flight%2F1.png?alt=media&token=d2c178b0-3389-4f35-8c04-3cd52dca935a'
        },
        dark: {
            src: 'https://firebasestorage.googleapis.com/v0/b/lets-fly-2020.appspot.com/o/site-media%2Fcredit-card-images%2Fdark%2F1.png?alt=media&token=19690dff-9989-4f81-ab6e-748cb7f63087'
        }
    },
    mastercard: {
        light: {
            src: 'https://firebasestorage.googleapis.com/v0/b/lets-fly-2020.appspot.com/o/site-media%2Fcredit-card-images%2Flight%2F2.png?alt=media&token=4f025434-6e93-4f41-af9d-a6c30ac7e1c2'
        },
        dark: {
            src: 'https://firebasestorage.googleapis.com/v0/b/lets-fly-2020.appspot.com/o/site-media%2Fcredit-card-images%2Fdark%2F2.png?alt=media&token=748dc662-d116-40b8-9490-952874963806'
        }
    },
    amex: {
        light: {
            src: 'https://firebasestorage.googleapis.com/v0/b/lets-fly-2020.appspot.com/o/site-media%2Fcredit-card-images%2Flight%2F22.png?alt=media&token=9eee08c6-2d51-4372-b26d-9b4991b5526e'
        },
        dark: {
            src: 'https://firebasestorage.googleapis.com/v0/b/lets-fly-2020.appspot.com/o/site-media%2Fcredit-card-images%2Fdark%2F22.png?alt=media&token=6312543f-f5af-49e0-b0d5-44d3ef26c1a4'
        }
    }
}

export default cardMap;