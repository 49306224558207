import React from 'react'

import FBOFinancials from './FBOFinancials'

export default function Settings(props) {

    const { activeFBOKey, fboData } = props

    console.log(fboData)


    if (!fboData[activeFBOKey].info.verified) {

        return (
            <FBOFinancials {...props} />
        )

    }

    return (
        <div>
            Settings
        </div>
    )
}