
import { db } from '../utilities/fire'

/**
 * 
 * Fetches the relevant data from the database
 * 
 * @param {String} userID Firebase User UID
 * @return {Array} Returns an array of the events as a promise
 */
export default function FetchInstructorEvents(userID) {

    return new Promise(async res => {
        const userQuery = await db.collection('events').where('hasInstructor', '==', userID).get()

        let eventList = []

        if (userQuery.empty) {
            res(eventList)
        } else {
            userQuery.forEach(event => {
                let eventData = event.data()
                if (!eventData.deleted) {

                    let { id, title, start, end, resourceEditable, resourceIds, planeUID, groundTime, hobbsStart, hobbsEnd } = eventData.event // add "color" if wanting default color for an event
                    // NOTE: If you add color here, you can't get any other color - anywhere on the calendar

                    let logData = {}
                    if (eventData?.logData) {
                        logData = eventData.logData
                    }

                    // console.log(eventData.event)
                    let newEvent = {
                        id: id,
                        title: title,
                        start: start,
                        end: end,
                        // color: color,
                        resourceIds: resourceIds,
                        resourceEditable: resourceEditable,
                        planeUID: planeUID,
                        logData: logData, // NOTE TO GET RID OF THIS
                        groundTime: groundTime,
                        hobbsStart: hobbsStart,
                        hobbsEnd: hobbsEnd,
                        hasInstructor: eventData.hasInstructor,
                        user: eventData.user,
                    }
                    if (!(eventData.user === userID)) {
                        newEvent.rendering = 'background'

                    }

                    eventList.push(newEvent)

                }
            })
        }

        res(eventList)

    })

}