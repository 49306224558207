import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import FCalendar from './Calendar'

export default function RenderCalendar(props) {

    const { userData, fboData } = props
    
    return (
        <Grid className="calendar-container" container direction="row"  alignItems="flex-start" justify="space-evenly">

            <FCalendar
                userData={userData}
                fboData={fboData}
            />

        </Grid>
    )
}