import React, { useState, useEffect } from 'react'
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, Typography } from '@material-ui/core'

export default function OwnershipTable(props) {

    const { HandleNewPersonDialog, stripePersons } = props

    const [isLoading, setIsLoading] = useState(true)
    
    useEffect(() => {

        if (stripePersons.length > 0) {
            setIsLoading(false)
        }
        
    }, [stripePersons])
    
    /**
     * 
     * @param {String} type header or body
     */
    function CreateTableRows(type) {

        let bodyRows = []

        if (type === "header") {
            return (
                <TableHead className="table-head">
                    <TableRow>
                        <TableCell className="header-cell">Name</TableCell>
                        <TableCell className="header-cell">Title</TableCell>
                        <TableCell className="header-cell">Edit</TableCell>
                    </TableRow>
                </TableHead>
            )
        }

        stripePersons.forEach(person => {
            let name = `${person.first_name} ${person.last_name}`
            let title = `${person.relationship.title}`
            bodyRows.push(
                <TableRow key={person.id} hover={true}>
                    <TableCell className="body-cell">{name}</TableCell>
                    <TableCell className="body-cell">{title}</TableCell>
                    <TableCell className="body-cell">
                        <Button variant="contained" color="primary" size="small" onClick={() => HandleNewPersonDialog('edit', person)}>Edit</Button>
                    </TableCell>
                </TableRow>
            )
        })

        if (type === "body") {
            return (
                <TableBody className="table-body">
                    {bodyRows}
                </TableBody>
            )
        }


    }

    if (isLoading) {
        return (
            <div></div>
        )
    }

    return (
        <>
            {
                stripePersons.length > 0 &&
                    <>
                        <Typography className="title" variant="h5">Account Ownership</Typography>
                        <TableContainer className="table-container">
                            <Table size="small">
                                {CreateTableRows('header')}
                                {CreateTableRows('body')}
                            </Table>
                        </TableContainer>
                    </>
            }
        </>
    )
}