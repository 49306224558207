import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Grid, Button, TextField, FormControl, FormHelperText, Paper, Snackbar, Typography, Backdrop, CircularProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab'; 
import { db } from '../../utilities/fire'
import moment from 'moment'
import firebase from 'firebase'
import BalanceAccount from '../../helpers/BalanceAccount' // New helper function - rewrote from cloud function - works perfectly now?????

// ********* https://draftjs.org/docs/getting-started ********************

import MyEditor from '../../components/DraftJS'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'

// ***********************************************************************
 

/**
 * A Page to view the details of a flight event
 * @param {Props} props - Required Prop: userData
 */
export default function EventDetail(props) {

    const { userData, setUserData, fboData } = props

    // const balanceAccount = firebase.functions().httpsCallable('balanceAccount')
    
    // Create the initial editorState
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    // This is the id of the event - see the url
    const { eventid } = useParams()

    // Snackbar success state
    const [open, setOpen] = useState(false);

    const [isUpdating, setIsUpdating] = useState(false) // Backdrop
    const [isLoading, setIsLoading] = useState(true)
    const [eventDetails, setEventDetails] = useState({})

    const [disableFields, setDisableFields] = useState(false)
    // The error message
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {

        if (eventDetails.hobbsEnd < eventDetails.hobbsStart) {
            setErrorMessage("End cannot be less than start!")
        } else {
            setErrorMessage('')
        }

    },[eventDetails])


    // Get initial data 
    useEffect(()=>{

        db.collection('events').doc(eventid).get().then(doc => {

            const eventData = doc.data()
            // Create blank object
            let noteObject = {}

            // If the notes object exists in the database
            if (eventData.event.notes) {

                // Need to change the JSON string into an object - using convertFromRaw via draft-js
                noteObject = convertFromRaw(JSON.parse(eventData.event.notes))

                // Set the editorstate with the new object
                // NOTE - createWithContent made this whole thing work properly
                setEditorState(EditorState.createWithContent(noteObject))
            }

            // Then set the rest of the data via the doc
            setEventDetails({
                ...eventData,
                event: {
                    ...eventData.event,
                    title: eventData.event.title,
                    hobbsStart: Number(eventData.event.hobbsStart),
                    hobbsEnd: Number(eventData.event.hobbsEnd),
                    groundTime: Number(eventData.event.groundTime),
                }
            })

            if (eventData.event.finalizedOn) {
                let timestamp = moment(eventData.event.finalizedOn.toDate()).add(1, 'h')
                // let eventEndPlusOneHour = moment(eventData.event.end).add(1, 'h')
                if (moment().isAfter(timestamp) && moment().isAfter(eventData.event.end)) {
                    setDisableFields(true)
                    setErrorMessage('This event is no longer editable!')
                    console.log('disabling fields')
                }
            }

            setIsLoading(false)
        })
        
    }, [eventid])

    async function handleSave(updatedEvent) {

        // Update the local state
        setEventDetails(updatedEvent)

        if (updatedEvent.event.hobbsEnd >= updatedEvent.event.hobbsStart) {
            updatedEvent.finalizedOn = firebase.firestore.FieldValue.serverTimestamp()
        }

        // // Set the event in the database
        await db.collection('events').doc(eventid).update(updatedEvent)

        // Balance the user's account
        let result = await BalanceAccount(updatedEvent.user, fboData)

        // If the current user is the same as the event's user
        if (updatedEvent.user === userData.uid) {
            // We handle the local state
            setUserData({...userData, ...{accountBalance: result.netAccountBalance}})
        }
        
        // Open the snackbar
        setOpen(true)
        setIsUpdating(false)

    }

    function handleUpdate() {
        
        setIsUpdating(true)
        // The contentState is called with this function getCurrentContent
        const contentState = editorState.getCurrentContent()

        // Then make it into JSON string form for database - using convertToRaw via draft-js
        let noteJSON = JSON.stringify(convertToRaw(contentState))

        // Set the event data
        let updatedEvent = {
            ...eventDetails,
            event: {
                ...eventDetails.event,
                notes: noteJSON
            }
        }

        // Call the handleSave function
        handleSave(updatedEvent)
    }

    if (isLoading) {
        return (
            <div></div>
        )
    }

    return (
        <div className="event-wrapper">
            
            <div className="button-wrapper">
                
                <Button variant="contained" color="primary" className="update" disabled={disableFields} onClick={() => handleUpdate()}>Update</Button>
                <Link to='/flights'>
                    <Button variant="outlined" color="primary" className="go-back">Back to Flights</Button>
                </Link>
                
            </div>
            <Typography variant="body1" align="center" gutterBottom className="error-message">{errorMessage}</Typography>
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid item xs={12} align="center">

                    <div className="form-container">

                        <FormControl className="form" fullWidth component={Paper}>

                            <TextField
                                className="title"
                                color="secondary"
                                name="title"
                                disabled={disableFields}
                                value={eventDetails.event.title || ''}
                                onChange={(arg) => setEventDetails({
                                    ...eventDetails,
                                    event: {
                                        ...eventDetails.event,
                                        title: arg.target.value
                                    }
                                })}
                            />

                            {eventDetails.user && fboData[eventDetails.fboID].users?.[eventDetails.user]?.firstName &&
                                <>
                                    <Typography className='subtitle'>Pilot</Typography>
                                    <Typography className='person'>{fboData[eventDetails.fboID].users[eventDetails.user].firstName} {fboData[eventDetails.fboID].users[eventDetails.user].lastName}</Typography>
                                </>
                            }

                            {eventDetails.hasInstructor && fboData[eventDetails.fboID].users?.[eventDetails.hasInstructor]?.firstName &&
                                <>
                                <Typography className='subtitle'>Instructor</Typography>
                                <Typography className='person'>{fboData[eventDetails.fboID].users?.[eventDetails.hasInstructor].firstName} {fboData[eventDetails.fboID].users?.[eventDetails.hasInstructor].lastName}</Typography>
                                </>
                            }

                            <FormHelperText className="helper-text" id="hobbsStart">Hobbs Start Time</FormHelperText>
                            <TextField
                                className="hobbs"
                                size="small"
                                id="hobbsStart"
                                type="number"
                                variant="outlined"
                                name="hobbsStart"
                                disabled={disableFields}
                                value={eventDetails.event.hobbsStart || ''}
                                onChange={(arg) => setEventDetails({
                                    ...eventDetails,
                                    event: {
                                        ...eventDetails.event,
                                        hobbsStart: Number(arg.target.value)
                                    }
                                })}
                            />

                            <FormHelperText className="helper-text" id="hobbsEnd">Hobbs End Time</FormHelperText>
                            <TextField
                                className="hobbs"
                                size="small"
                                id="hobbsEnd"
                                type="number"
                                variant="outlined"
                                name="hobbsEnd"
                                disabled={disableFields}
                                value={eventDetails.event.hobbsEnd || ''}
                                onChange={(arg) => setEventDetails({
                                    ...eventDetails,
                                    event: {
                                        ...eventDetails.event,
                                        hobbsEnd: Number(arg.target.value)
                                    }
                                })}
                            />

                            <FormHelperText className="helper-text" id="ground">Additional Ground Time</FormHelperText>
                            <TextField
                                className="hobbs"
                                size="small"
                                id="ground"
                                type="number"
                                variant="outlined"
                                name="groundTime"
                                disabled={disableFields}
                                value={eventDetails.event.groundTime || ''}
                                onChange={(arg) => setEventDetails({
                                    ...eventDetails,
                                    event: {
                                        ...eventDetails.event,
                                        groundTime: Number(arg.target.value)
                                    }
                                })}
                            />

                            <FormHelperText className="helper-text" id="notes">Lesson Notes</FormHelperText>
                            <MyEditor
                                editorState={editorState}
                                setEditorState={setEditorState}
                                disabled={disableFields}
                            />
                            
                        </FormControl>
                        
                    </div>

                </Grid>

            </Grid>

            <Snackbar open={open} autoHideDuration={2000} onClose={()=>setOpen(false)}>
                <Alert onClose={() => setOpen(false)} severity="success">Success!</Alert>
            </Snackbar>

            <Backdrop className='backdrop' open={isUpdating} onClick={() => setIsUpdating(false)}>
                <CircularProgress color='secondary' />
            </Backdrop>
            
        </div>
        
    )
}
