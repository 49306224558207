import React, {useState, useEffect, useLayoutEffect} from 'react';

import {FormControl, Select, MenuItem, 
        Radio, RadioGroup, FormControlLabel, Typography } from '@material-ui/core'

        
/**
 * 
 * @param {Props} props fboData - The FBO Data accessible to the user
 */
export default function FBOSelector(props) {

    let fboData = props.fboData

    let fboKeys = Object.keys(fboData)

    useEffect(()=>{

        // If there is not currently an FBO selected, then pick the first one
        if (!props.activeFBOKey) {

            props.setActiveFBOKey(fboKeys[0])
        }

    }, [props, fboData, fboKeys])


    const [dimensions, setDimensions] = useState({
        width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
        height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    })
    const [displayStuff, setDisplayStuff] = useState(false)

    useLayoutEffect(() => {

        function handleWindowResize() {

            setDimensions({
                width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
                height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
            })

        }

        window.addEventListener('resize', handleWindowResize)

        console.log(dimensions.width)

        if (dimensions.width < 600) {
            setDisplayStuff(false)
        } else {
            setDisplayStuff(true)
        }
        return _ => {
            window.removeEventListener('resize', handleWindowResize)
        } 
        
    },[dimensions])
        
    /**
     * 
     * @param {String} val The FBO UID from firebase that should be selected and displayed
     */
    function handleSelectFBO(key) {
        
        // If display < 600px use the dropdown method
        if (displayStuff === false) {
            props.setActiveFBOKey(key)
        }

        // Else use the radio selection method  
        else {
            props.setActiveFBOKey(key)
        }
        
    }

    // Refresh the page on a screen size change - should edit 

    if (!props.activeFBOKey) {return <div />}
    
    return(

        <div>
            {
                displayStuff === false
            ?
            <FormControl className="select-control">
                <Select className="select" variant="outlined"  value={props.activeFBOKey} onChange={(event) => handleSelectFBO(event.target.value)}>   
                            
                    {fboKeys.map(fboKey => {
                        return(   
                            <MenuItem key={fboKey} value={fboKey}>{fboData[fboKey].info.name}</MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
            :
            <FormControl className="button-control">
                
                <Typography variant="h5" align="center" style={{fontWeight: '800', }} gutterBottom>Select Your Active FBO</Typography>
                <RadioGroup className="button" onChange={(event) => handleSelectFBO(event.target.value)}>   
                    {fboKeys.map(fboKey => {
                        
                        return(
                            <FormControlLabel 
                            className="button-text"
                            key={fboKey} 
                            value={fboKey}

                            control={<Radio
                                checked={props.activeFBOKey === fboKey}
                            />}
                            label={<Typography variant="body1" style={{fontWeight: '500'}}>{fboData[fboKey].info.name}</Typography>}
                            />
                        )
                    })}
                </RadioGroup>
            </FormControl>
            }
            
        </div>
    )
}