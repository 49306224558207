import React, {useState} from 'react'

import {
    Dialog, DialogTitle, DialogContent, Typography,
    TextField, FormControl, Button, Snackbar,
} from '@material-ui/core'

// import FlyBar from '../../../components/FlyBar'

import { Alert } from '@material-ui/lab'; 
import {db} from '../../../utilities/fire';

/**
 * 
 * @param {Props} props -
 * - planeKey - the selected user object
 * - open - a state of whether the Dialog is open or not
 * - close - a function to close the dialog
 * - activeFBO - the selected fbo from the selector
 * - fboData - the complete fbo data object
 * - setFBOData - a function to set the FBO data
 */
export default function RemovePlaneDialog(props) {

    const CODE = "REMOVE"

    const { fboData, activeFBOKey, planeKey } = props

    const [open, setOpen] = useState(false);
    const [openError, setOpenError] = useState(false)
    const [openWarning, setOpenWarning] = useState(false)
    const [showEntry, setShowEntry] = useState(false)
    const [showFinalConfirm, setShowFinalConfirm] = useState(false)
    const [confirmField, setConfirmField] = useState('')
    const [processing, setProcessing] = useState(false)

    function handleTextChange(evt) {
        // Set the value to the current input
        const value = evt.target.value;

        // Set the field to value
        setConfirmField(value);   
   
        // If the value = to the code then show the final confirm button
        if (value === CODE) {
            setShowFinalConfirm(true)
        } else {

            // Need this because need to revert state if entry is changed != code
            setShowFinalConfirm(false)
        }
    }



    function handleFinalConfirm() {

        setProcessing(true)

        db.collection('fbos').doc(activeFBOKey).collection('planes').doc(planeKey).update({
            deleted: true
        })

        let newFBOData = fboData
        delete newFBOData[activeFBOKey].planes[planeKey]
        props.setFBOData({...newFBOData})
        props.close()

    }

    function handleClose() {
        setOpen(false);
        setOpenError(false);
        setOpenWarning(false);
    };

    function handleRemovePlane() {
        setShowEntry(true)
    }

    if (processing) return <div />

    return (

        <Dialog className="remove-dialog" open={props.open} onClose={props.close} color="primary">
            <DialogTitle align="center">Remove this plane?</DialogTitle>
            <DialogContent>

                <FormControl align="center" fullWidth={true}>

                    <Typography gutterBottom variant='h6'>{fboData[activeFBOKey].planes[planeKey].identifier}</Typography>
                    <div className="button-container">
                        <div className="button">
                            <Button color="primary" variant="contained" onClick={()=>handleRemovePlane()}>Confirm</Button>
                        </div>
                        <div className="button">
                            <Button color="primary" variant="outlined" onClick={()=>props.close()}>Cancel</Button>
                        </div>
                    </div>

                    {
                        showEntry === false 
                        ?
                        null
                        :
                        <div>
                            <Typography className="text" variant="body2" gutterBottom>Type in "{CODE}" to remove this plane.</Typography>
                            <TextField
                                margin="dense" 
                                id="confirm" 
                                variant="outlined" 
                                fullWidth
                                required
                                name="confirm"
                                value={confirmField}
                                onChange={handleTextChange}
                            />

                            {
                                showFinalConfirm === true 
                                ?
                                <Button fullWidth color="secondary" variant="contained" onClick={()=>handleFinalConfirm()}>Confirm</Button>
                                :
                                <Button fullWidth disabled variant="contained">Confirm</Button>
                            }

                        </div>
                    }

                </FormControl>

            </DialogContent>
            {/* <FlyBar data={flyBarData} open={flyBarData}/> */}
            

            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">Success!</Alert>
            </Snackbar>
            <Snackbar open={openError} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">This user is already in the group!</Alert>
            </Snackbar>
            <Snackbar open={openWarning} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning">This user already has an accout, but has been added to your group!</Alert>
            </Snackbar>

        </Dialog>
        
    )
}