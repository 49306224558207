import React from 'react'
import { Grid, FormControl, FormGroup, Typography, FormControlLabel, Switch } from '@material-ui/core'
import PlaneSettingsSelector from './components/PlaneSettingsSelector'
import CertificationSettings from './components/CertificationSettings'
import _ from 'lodash'
import { UpdateAcceptablePilotsForUser } from '../../helpers/SaveData'

export default function RenderSettings(props) {

    const { userData, setUserData, fboData } = props

    function ShowAvailableInstructors() {

        function handleInstructorCheckChange(key) {

            // The new user data is a copy of the old data
            let newUserData = props.userData

            // An array to hold the new instructor list
            let newInstructorList = []

            // if the User has any acceptable pilots selected
            if (newUserData?.acceptablePilots) {

                // The new pilots list will be built from the old
                newInstructorList = [...newUserData.acceptablePilots]

                // If the pilot list includes the instructor, it should be removed
                if (newInstructorList.includes(key)) {

                    // Remove it and update firebase
                    _.pull(newInstructorList, key)
                    UpdateAcceptablePilotsForUser(props.userData.uid, key, 'remove')

                    // Otherwise, add it to the list
                } else {

                    newInstructorList.push(key)
                    UpdateAcceptablePilotsForUser(props.userData.uid, key, 'add')

                }

                // If they didn't even have a list yet, add it to the new list and firebase
            } else {
                newInstructorList.push(key)
                UpdateAcceptablePilotsForUser(props.userData.uid, key, 'add')
            }

            // Update the user data object and save it locally
            newUserData.acceptablePilots = newInstructorList
            setUserData({ ...props.userData, ...newUserData })
        }

        let instructors = []

        Object.keys(fboData).forEach(fboKey => {
            Object.keys(fboData[fboKey].employees).forEach(employeeKey => {
                let checked = props.userData.acceptablePilots?.includes(employeeKey) ? true : false
                if (employeeKey !== props.userData.uid) {

                    // If the employee is an instructor
                    if (fboData[fboKey].employees[employeeKey]?.type?.instructor?.active) {

                        let key = `${fboKey}_${employeeKey}`

                        instructors.push(
                            <FormControlLabel
                                key={key}
                                control={
                                    <Switch
                                        checked={checked}
                                        onChange={() => handleInstructorCheckChange(employeeKey)}
                                        color="primary"
                                    />
                                }
                                label={
                                    <Typography>{fboData[fboKey].users[employeeKey].firstName} {fboData[fboKey].users[employeeKey].lastName} <b>({fboData[fboKey].info.name})</b></Typography>
                                }
                            />
                        )
                    }

                }


            })

        })

        return instructors

    }
    
    return (
        <Grid className='settings-forms' container direction='row' alignItems='flex-start' justify='space-evenly'>

            <Grid className='setting-item' item xs={12} sm={4}>
                <Typography gutterBottom className="forms-title" align="center" variant="h5">
                    Select your instructors.
                </Typography>

                <FormControl component="fieldset">
                    <FormGroup aria-label="position">

                        {ShowAvailableInstructors()}

                    </FormGroup>
                </FormControl>
            </Grid>

            <Grid className='setting-item' item xs={12} sm={4}>
                <PlaneSettingsSelector settingType="planes" fboData={fboData} userData={userData} setUserData={setUserData} />
            </Grid>

            <Grid className='setting-item' item xs={12} sm={4}>
                <CertificationSettings settingType="certs" fboData={fboData} userData={userData} setUserData={setUserData} />
            </Grid>

        </Grid>
    )
}