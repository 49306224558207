
import {db} from '../utilities/fire'

/**
 * 
 * Fetches the relevant data from the database
 * 
 * @param {String} userID Firebase User UID
 * @param {String} managedOrMember A string: 'man' for just FBOs managed, 'mem' for just FBOs the user is a member of, 'both' for both
 */
export default function FetchLinkedData(userID, managedOrMember) {


        /**
         * 
         * For the given asset type (assets general or planes) return the asset common name and its data
         * 
         * @param {String} thingType Type of asset class like planes or assets
         * @param {String} fboID Firebase UID of the FBO
         * 
         * @returns {Object} That has the asset class as the key and the data as the value
         */
        function getObject(assetType, fboID) {

            // Returns a promise with the asset type information
            return new Promise((res) => {

                // Get all of the assets of that type (by collection) from firebase
                db.collection('fbos').doc(fboID).collection(assetType).get().then(query => {

                    // Hold them in an object
                    let assetObject = {}

                    // Add each individual asset of the type
                    query.forEach(asset => {
                        assetObject[asset.id] = asset.data()
                    })

                    // Return the full asset type collection as an object
                    res({[assetType]: assetObject})
                }).catch(err => {
                    console.log('error')
                })
            })
        }

        /**
         * 
         * Get the FBO Metadata
         * 
         * @param {String} fboID A Firebase UID of an FBO
         * 
         * @returns {Object} an object with the key as info and the balue as the FBO metadata
         */
        function getFBOInfo(fboID) {

            // return the info of the fbo
            return new Promise((res) => {

                // Get the document from firebase (returns its own promise)
                db.collection('fbos').doc(fboID).get().then(doc => {

                    let info = {...doc.data(), uid: doc.id}

                    // Resolve that data as an info object
                    res({info: info})
                })
            })
        }

        /**
         * 
         * Get the users of the FBO as an object
         * 
         * @param {String} fbo  Firebase UID of the FBO you want
         * 
         * @returns {Promise} as an ojbect of {users: user.data()}
         */
        function getUsers(fbo) {

            return new Promise(res => {

                // Get the users that are part of the FBO
                db.collection('users').where('fbos', 'array-contains', fbo).get().then(query => {

                    let users = {}

                    query.forEach(user => {
                        
                        users[user.id] = user.data()
                    })

                    res({users: users})
                })
            })
        }

        function getEmployees(fbo) {

            return new Promise(res => {

                // Get the users that are part of the FBO
                db.collection('fbos').doc(fbo).collection('employees').get().then(query => {

                    let employees = {}

                    query.forEach(employee => {
                        
                        if (!employee.data().deleted) {
                            employees[employee.id] = employee.data()
                        }
                    })

                    res({employees: employees})
                })
            })
        }

        /**
         * Given an FBO key, go out and get the information, planes, and assets. Compile those into a single object
         * of the form {fboUID: 
         *                  {info: infoData},
         *                  {planes: 
         *                      {planeUID: planeData}
         *                  },
         *                  {assets:
         *                      {assetUID: assetData}
         *                  }
         *              }
         * @param {String} fbo An FBO UID from firebase
         * 
         * @returns {Object} An object as described above
         */
        function compileFBOData(fbo) {

            // Return a promise
            return new Promise((res) => {
                
                // info promise array
                let infoPromises = []
                
                // Get each of the types of data desired
                // Info is different as it is not a subcollection of the FBO
                infoPromises.push(getFBOInfo(fbo))

                // All asset types are subcollections thus need a separate type of database call
                infoPromises.push(getObject('planes', fbo))
                infoPromises.push(getObject('assets', fbo)) 
                infoPromises.push(getUsers(fbo))
                infoPromises.push(getEmployees(fbo))
                
                // Once all of the promises from the info have been resolved, complie that into a single FBO Object
                Promise.all(infoPromises).then(infoList => {

                    let fboObject = {}
                    
                    // For each of the types of information
                    infoList.forEach(infoObject => {
                        
                        // add that to the returnable object
                        fboObject = {...fboObject, ...infoObject}
                    })

                    // Return the fbo as the key and the data as the value
                    res({[fbo]: fboObject})
                    
                })

            })
        }

        /**
         * Given a userID get the user's FBOs
         * 
         * @param {String} userID The user ID Object
         * 
         * @return {Array} An array of the user's FBOs
         */
        function getFBOList(userID) {
            return new Promise(res => {

                // Get the user's object
                db.collection('users').doc(userID).get().then(doc => {

                    console.log(doc.data())

                    // Return the managed array, member array, or flattened version of both
                    if (managedOrMember === 'man') {

                        res(doc.data().fbosManaged)
                    } else if (managedOrMember === 'mem') {
                        res(doc.data().fbos)
                    } else if (managedOrMember === 'both') {
                        let managed = doc.data().fbosManaged
                        let member = doc.data().fbos
                        let combined = [...new Set(managed.concat(member))]
                        res(combined)
                    }
                    
                })
            })
        }



    // The final return
    return new Promise (async (res, rej) => {

        
        if (!(managedOrMember === 'man' || managedOrMember === 'mem' || managedOrMember === 'both')) {
            res({})
        }

        // The fbos for the user
        let fboList
        await getFBOList(userID).then(result => {
            fboList = result
        })

        // Create promises for each fbo's data
        let fboPromises = []

        // Go through the FBOs and get the data, and push the promise to the fbos array
        fboList.forEach(fbo => {
            
            fboPromises.push(compileFBOData(fbo))
            
        })
        // Once you have the data for all of the FBOs, resolve that into a big ass state object
        Promise.all(fboPromises).then(fboList => {
            
            // The final object
            let outputObject = {}
            fboList.forEach(fbo => {

                // For each fbo, save it to the object
                outputObject[Object.keys(fbo)[0]] = fbo[Object.keys(fbo)[0]]
                
            })

            // return the final object and move on with yo life
            res(outputObject)
        })

    })
}