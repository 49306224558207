import React from 'react'
import { Grid } from '@material-ui/core'
import FlightLog from './components/FlightLog'

export default function RenderFlights(props) {

    const { userData, setUserData, fboData } = props

    return (
        <Grid container className='flight-forms' direction='row' alignItems='center' justify='center'>
            <Grid item xs sm md={10} >
                <FlightLog
                    fboData={fboData}
                    userData={userData}
                    setUserData={setUserData}
                />
            </Grid>
        </Grid>
    )
}