import React from 'react'

import {
    FormControlLabel, FormGroup, FormControl, Checkbox
} from '@material-ui/core'

import _ from 'lodash'

import {UpdateNotificationsForUser} from '../../../helpers/SaveData'
import {notifications} from '../../../defaults/notifications'

export default function NotificationSettings(props) {

    const {userData} = props

    function ShowNotificationsSettings() {

        function handleCertChange(key) {

            let newUserData = props.userData

            let newNotificationList = []

            if (newUserData?.notifications) {

                newNotificationList = [...newUserData.notifications]

                if (newNotificationList.includes(key)) {

                    _.pull(newNotificationList, key)
                    UpdateNotificationsForUser(props.userData.uid, key, 'remove')

                } else {

                    newNotificationList.push(key)
                    UpdateNotificationsForUser(props.userData.uid, key, 'add')

                }

            } else {

                newNotificationList.push(key)
                UpdateNotificationsForUser(props.userData.uid, key, 'add')

            }

            newUserData.notifications = newNotificationList
            props.setUserData({...userData, ...newUserData})
            
        }

        let notificationList = []

        Object.keys(notifications).forEach(key => {
            
            let checked = props.userData.notifications?.includes(key) ? true : false
            notificationList.push(
                <FormControlLabel
                    key={key}
                    control={
                        <Checkbox
                            checked={checked}
                            onChange={()=>handleCertChange(key)}
                            color="primary"
                        />
                    }
                    label={notifications[key].name}
                />
            )
        })

        if (notificationList.length === 0) return (<div>Something is wrong!</div>)

        return (
            <FormGroup>
                {notificationList}
            </FormGroup>
        )
    }

    return (

        <>

            <FormControl align="left" component="fieldset">
                <FormGroup aria-label="position">
                    {ShowNotificationsSettings()}
                </FormGroup>
            </FormControl>

        </>

    )

}