import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types';
import {
    Box, Button,
    Tab, Container, AppBar, Tabs,
    Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, Typography
} from '@material-ui/core'

import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import EditUserDialog from './EditUserDialog'
import EditPlaneDialog from './EditPlaneDialog.js'
import EditAssetDialog from './EditAssetDialog'

import RemovePersonDialog from './RemovePersonDialog'
import RemovePlaneDialog from './RemovePlaneDialog.js'
import RemoveAssetDialog from './RemoveAssetDialog'

import Settings from './settings'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box><Container>{children}</Container></Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export default function FBODisplay(props) {

    const history = useHistory()

    const TABSET = [
        {
            label: 'Users'
        },
        {
            label: 'Employees'
        },
        {
            label: 'Planes'
        },
        {
            label: 'Assets'
        },
        {
            label: 'Settings'
        },
    ]

    const { activeFBOKey, fboData, setFBOData, userData, setUserData } = props

    const [value, setValue] = useState(0);
    const [openRemoveUser, setOpenRemoveUser] = useState(false)
    const [openRemovePlane, setOpenRemovePlane] = useState(false)
    const [openEditPlane, setOpenEditPlane] = useState(false)
    const [openRemoveAsset, setOpenRemoveAsset] = useState(false)
    const [openEditAsset, setOpenEditAsset] = useState(false)

    const [dialogType, setDialogType] = useState(null)          // String - user or employee
    const [dialogClass, setDialogClass] = useState(null)        // String - person, plane, asset
    const [dialogAction, setDialogAction] = useState(null)
    const [userDialogOpen, setUserDialogOpen] = useState(null)
    const [currentUser, setCurrentUser] = useState(null)
    const [currentAsset, setCurrentAsset] = useState(null)



    useEffect(() => {

        let searchVal = history?.location?.search.replace(/\?/g, '')

        let defaultTabValue = 0

        TABSET.forEach((tab, index) => {
            if (searchVal === tab.label.toLowerCase()) {
                defaultTabValue = index
            }
        })

        setValue(defaultTabValue)


    }, [history, TABSET])

    /**
     * 
     * Display a Person Dialog
     * 
     * @param {String} type employee, user
     * @param {String} action add, edit, delete
     * @param {String} userKey UID of the user to be editted
     */
    function showPersonDialog(classification, type, action, userKey) {

        setDialogType(type)
        setDialogClass(classification)
        setDialogAction(action)
        setUserDialogOpen(true)
        setCurrentUser(userKey)

    }

    function showPlaneDialog(action, assetKey) {
        setOpenEditPlane(true)
        setCurrentAsset(assetKey)
        setDialogAction(action)
    }


    function showAssetDialog(action, assetKey) {

        setDialogAction(action)
        // setDialogType(type)
        setOpenEditAsset(true)
        setCurrentAsset(assetKey)
    }

    function closePersonDialog() {
        setDialogType(null)
        setDialogClass(null)
        setDialogAction(null)
        setUserDialogOpen(false)
        setCurrentUser(null)
    }

    function closePlaneDialog() {
        setOpenEditPlane(false)
        setCurrentUser(null)
    }


    function closeAssetDialog() {
        setOpenEditAsset(false)
        setCurrentAsset(null)
    }

    function showRemoveUserDialog(type, userKey) {

        setDialogType(type)
        setOpenRemoveUser(true)
        setCurrentUser(userKey)
    }

    function showRemovePlaneDialog(planeKey) {
        setOpenRemovePlane(true)
        setCurrentAsset(planeKey)
    }

    function showRemoveAssetDialog(assetKey) {
        setCurrentAsset(assetKey)
        setOpenRemoveAsset(true)
    }

    function closeRemovalDialog() {
        setOpenRemoveUser(false)
        setOpenRemovePlane(false)
        setOpenRemoveAsset(false)
        setCurrentUser(null)
        setCurrentAsset(null)
    }


    function handleTabChange(event, value) {

        setValue(value)

        history.push(`/manage-fbo?${TABSET[value].label.toLowerCase()}`)

    }



    function createPersonHeader(type) {

        return (
            <TableHead className="table-head">
                <TableRow className="tr">
                    <TableCell className="th-cell">Permissions</TableCell>
                    <TableCell className="th-cell">First Name</TableCell>
                    <TableCell className="th-cell">Last Name</TableCell>
                    <TableCell className="th-cell">Email</TableCell>
                    <TableCell className="th-cell">Remove {type}</TableCell>
                </TableRow>
            </TableHead>

        )


    }

    function createPersonRow(type, userKey) {

        const { firstName, lastName, email } = fboData[activeFBOKey].users[userKey]

        return (
            <TableRow className="tr" key={userKey} hover={true}>
                <TableCell className="tr-cell" align="center"><Button variant="contained" color="primary" size="medium" onClick={() => showPersonDialog('person', type, 'edit', userKey)}>Edit</Button></TableCell>
                <TableCell className="tr-cell" align="center">{firstName}</TableCell>
                <TableCell className="tr-cell" align="center">{lastName}</TableCell>
                <TableCell className="tr-cell" align="center">{email}</TableCell>
                <TableCell className="tr-cell" align="center"><Button onClick={() => showRemoveUserDialog(type, userKey)}><RemoveCircleIcon fontSize="large" /></Button></TableCell>
            </TableRow>
        )
    }


    return (

        <div>

            {activeFBOKey === "" ?
                <div style={{ marginTop: '100px' }}>Choose your FBO to manage!</div>
                :
                <div className="appbar-container">
                    {dialogClass === 'person' &&
                        <EditUserDialog
                            userKey={currentUser}
                            setUserKey={setCurrentUser}
                            open={userDialogOpen}
                            activeFBOKey={activeFBOKey}
                            fboData={fboData}
                            setFBOData={setFBOData}
                            close={closePersonDialog}
                            type={dialogType}
                            action={dialogAction}
                            setAction={setDialogAction}
                        />
                    }
                    {
                        openRemoveUser &&
                        <RemovePersonDialog
                            userKey={currentUser}
                            open={openRemoveUser}
                            activeFBOKey={activeFBOKey}
                            fboData={fboData}
                            setFBOData={setFBOData}
                            close={closeRemovalDialog}
                            type={dialogType}
                        />
                    }
                    {
                        openEditPlane &&
                        <EditPlaneDialog
                            planeKey={currentAsset}
                            open={openEditPlane}
                            activeFBOKey={activeFBOKey}
                            fboData={fboData}
                            setFBOData={setFBOData}
                            close={closePlaneDialog}
                            action={dialogAction}
                        />
                    }
                    {
                        openRemovePlane &&
                        <RemovePlaneDialog
                            assetKey={currentAsset}
                            open={openRemovePlane}
                            activeFBOKey={activeFBOKey}
                            fboData={fboData}
                            setFBOData={setFBOData}
                            close={closeRemovalDialog}
                            action={dialogAction}
                        />
                    }
                    {
                        openEditAsset &&
                        <EditAssetDialog
                            assetKey={currentAsset}
                            open={openEditAsset}
                            activeFBOKey={activeFBOKey}
                            fboData={fboData}
                            setFBOData={setFBOData}
                            close={closeAssetDialog}
                            action={dialogAction}
                        />
                    }
                    {
                        openRemoveAsset &&
                        <RemoveAssetDialog
                            assetKey={currentAsset}
                            open={openRemoveAsset}
                            activeFBOKey={activeFBOKey}
                            fboData={fboData}
                            setFBOData={setFBOData}
                            close={closeRemovalDialog}
                            action={dialogAction}
                        />
                    }
                    <AppBar className="manageFBO-appbar" position="sticky">
                        <Tabs className='tabs' value={value} onChange={handleTabChange} variant="scrollable" scrollButtons="auto" aria-label="simple tabs example">

                            {TABSET.map((tab, index) => {
                                return (
                                    <Tab key={tab.label} label={tab.label} icon={''} {...a11yProps(index)} />
                                )
                            })}

                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} spacing={2} index={0}>

                        {/* Add User Button */}
                        <div align="center" style={{ marginTop: '10px' }}>
                            <Button color="secondary" variant="contained" onClick={() => showPersonDialog('person', 'user', 'add')}>
                                <Typography color="primary" variant="body1">Add User&nbsp;&nbsp;</Typography><AddCircleIcon fontSize="large" />
                            </Button>
                        </div>


                        {/* Users Table */}
                        <div>
                            <TableContainer className="usersTable" component={Paper}>
                                <Table className="table" size="small">

                                    {createPersonHeader('User')}

                                    <TableBody className="table-body">

                                        {Object.keys(fboData[activeFBOKey].users).map(userKey => createPersonRow('user', userKey))}

                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </div>

                    </TabPanel>

                    <TabPanel value={value} spacing={2} index={1}>

                        {/* Add Employee Button */}
                        <div align="center" style={{ marginTop: '10px' }}>
                            <Button color="secondary" variant="contained" onClick={() => showPersonDialog('person', 'employee', 'add')}>
                                <Typography color="primary" variant="body1">Add Employee&nbsp;&nbsp;</Typography><AddCircleIcon fontSize="large" />
                            </Button>
                        </div>


                        {/* Employees Table */}
                        <div>
                            <TableContainer className="usersTable" component={Paper}>
                                <Table className="table" size="small">

                                    {createPersonHeader('Employee')}

                                    <TableBody className="table-body">

                                        {Object.keys(fboData[activeFBOKey].employees).map(employeeKey => createPersonRow('employee', employeeKey))}


                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </div>

                    </TabPanel>

                    <TabPanel value={value} spacing={2} index={2}>

                        {/* Add Plane Button */}
                        <div align="center" style={{ marginTop: '10px' }}>
                            <Button color="secondary" variant="contained" onClick={() => showPlaneDialog('add', null)}>
                                <Typography color="primary" variant="body1">Add Plane&nbsp;&nbsp;</Typography><AddCircleIcon fontSize="large" />
                            </Button>
                        </div>

                        {/* Planes Table */}
                        <div>
                            <TableContainer className="usersTable" component={Paper}>
                                <Table className="table" size="small">

                                    <TableHead className="table-head">
                                        <TableRow className="tr">
                                            <TableCell className="th-cell">Edit</TableCell>
                                            <TableCell className="th-cell">Manufacturer</TableCell>
                                            <TableCell className="th-cell">Model</TableCell>
                                            <TableCell className="th-cell">Year</TableCell>
                                            <TableCell className="th-cell">Identifier</TableCell>
                                            <TableCell className="th-cell">Rate ($/Hour)</TableCell>
                                            <TableCell className="th-cell">Remove Plane</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody className="table-body">

                                        {Object.keys(fboData[activeFBOKey].planes).map(planeKey => {

                                            if (fboData[activeFBOKey].planes[planeKey].deleted === true) {

                                                return null

                                            } else {

                                                let { manufacturer, planeDetails, identifier } = fboData[activeFBOKey].planes[planeKey]

                                                return (
                                                    <TableRow className="tr" key={planeKey} hover={true}>
                                                        <TableCell className="tr-cell" align="center"><Button variant="contained" color="primary" size="medium" onClick={() => showPlaneDialog('edit', planeKey)}>Edit</Button></TableCell>
                                                        <TableCell className="tr-cell" align="center">{manufacturer}</TableCell>
                                                        <TableCell className="tr-cell" align="center">{planeDetails.model.value}</TableCell>
                                                        <TableCell className="tr-cell" align="center">{planeDetails.year.value}</TableCell>
                                                        <TableCell className="tr-cell" align="center">{identifier}</TableCell>
                                                        <TableCell className="tr-cell" align="center">${parseFloat(planeDetails.rate.value).toFixed(2)}</TableCell>
                                                        <TableCell className="tr-cell" align="center"><Button onClick={() => showRemovePlaneDialog(planeKey)}><RemoveCircleIcon fontSize="large" /></Button></TableCell>
                                                    </TableRow>
                                                )

                                            }
                                        })}

                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </div>

                    </TabPanel>
                    <TabPanel value={value} spacing={2} index={3}>

                        {/* Add Asset Button */}
                        <div align="center" style={{ marginTop: '10px' }}>
                            <Button color="secondary" variant="contained" onClick={() => showAssetDialog('add', null)}>
                                <Typography color="primary" variant="body1">Add Asset&nbsp;&nbsp;</Typography><AddCircleIcon fontSize="large" />
                            </Button>
                        </div>

                        {/* Assets Table */}
                        <div>
                            <TableContainer className="usersTable" component={Paper}>
                                <Table className="table" size="small">

                                    <TableHead className="table-head">
                                        <TableRow className="tr">
                                            <TableCell className="th-cell">Edit</TableCell>
                                            <TableCell className="th-cell">Name</TableCell>
                                            <TableCell className="th-cell">Capacity</TableCell>
                                            <TableCell className="th-cell">Remove</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody className="table-body">

                                        {Object.keys(fboData[activeFBOKey].assets).map(assetKey => {

                                            if (fboData[activeFBOKey].assets[assetKey].deleted === true) {

                                                console.log(fboData[activeFBOKey].assets[assetKey])
                                                return null

                                            } else {

                                                return (
                                                    <TableRow className="tr" key={assetKey} hover={true}>
                                                        <TableCell className="tr-cell" align="center"><Button variant="contained" color="primary" size="medium" onClick={() => showAssetDialog('edit', assetKey)}>Edit</Button></TableCell>
                                                        <TableCell className="tr-cell" align="center">{fboData[activeFBOKey].assets[assetKey].name}</TableCell>
                                                        <TableCell className="tr-cell" align="center">{fboData[activeFBOKey].assets[assetKey].capacity}</TableCell>
                                                        <TableCell className="tr-cell" align="center"><Button onClick={() => showRemoveAssetDialog(assetKey)}><RemoveCircleIcon fontSize="large" /></Button></TableCell>
                                                    </TableRow>
                                                )

                                            }
                                        })}

                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </div>

                    </TabPanel>

                    <TabPanel value={value} spacing={2} index={4}>

                        <Settings activeFBOKey={activeFBOKey} fboData={fboData} setFBOData={setFBOData} userData={userData} setUserData={setUserData} />
                        
                    </TabPanel>
                </div>


            }
        </div>
    )
}