export const certifications = {
    STU: {
        name: "Student",
        value: "STU"
    },
    VFR: {
        name: "Private Pilot License (Visual Flight Rules)",
        value: "VFR"
    },
    IFR: {
        name: "Private Pilot License (Instrument Flight Rules)",
        value: "IFR"
    },
    CPL: {
        name: "Commercial Pilot License",
        value: "CPL"
    },
    CFI: {
        name: "Certified Flight Instructor",
        value: "CFI"
    },
    CFII: {
        name: "Certified Flight Instructor-Instrument",
        value: "CFII"
    },
    ATP: {
        name: "Airline Transport Pilot",
        value: "ATP"
    },
    MEL: {
        name: "Multi Engine Land",
        value: "MEL"
    },
    SES: {
        name: "Single Engine Sea",
        value: "SES"
    },
    MES: {
        name: "Multi Engine Sea",
        value: "MES"
    },
    GLD: {
        name: "Glider",
        value: "GLD"
    },
    TLD: {
        name: "Taildragger",
        value: "TLD"
    },

}