import React, {useState, useRef, useEffect, useLayoutEffect} from 'react'
import { withRouter, useLocation } from 'react-router-dom';

import PropTypes from 'prop-types'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import timeGridPlugin from '@fullcalendar/timegrid';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import listPlugin from '@fullcalendar/list';
import timelinePlugin from '@fullcalendar/timeline';
import resourceDayGridPlugin from '@fullcalendar/resource-daygrid';
import resourcePlugin from '@fullcalendar/resource-common';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import googleCalendarPlugin from '@fullcalendar/google-calendar';

import '../../styles/components/calendar.scss'
import moment from 'moment'
// import {v4} from 'uuid'

import AddFlightFilter from '../../components/Calendar/components/AddFlightFilter'


import { 
    Typography, Grid, Accordion, AccordionActions, AccordionSummary,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'


import FetchEvents from '../../helpers/FetchEvents'

import { UpdateEvent, DeleteEvent } from '../../helpers/SaveData' //AddEvent

import EditFlightDialog from './components/EditFlightDialog'

// import CreatePresetDialog from './components/CreatePresetDialog'

/**
 * 
 * The full calendar object - This will render a calendar given the userData and the fboData
 * 
 * @param {Props} props Props list: userData, fboData
 * 
 * @returns {JSX} - A Calendar Object
 */
function FCalendar(props) {

    function useQuery() {
        return new URLSearchParams(useLocation().search)
    }

    const query = useQuery()

    // Destructure the props
    const { userData, fboData } = props

    const mobileSettings = {
        header: {
            left: 'prev, next',
            center: 'title',
            right: 'listMonth, timeGridWeek, planeGrid',
        },
        titleFormat: { month: 'numeric', day: 'numeric' },
        views: {
            timeGridWeek: {
                type: 'timeGridWeek',
                duration: { days: 7 },
                buttonText: 'Week',
            },
            listMonth: {
                type: 'listMonth',
                duration: { days: 30 },
                buttonText: 'List',
            },
            planeGrid: {
                type: 'resourceTimeGrid',
                duration: { days: 1 },
                buttonText: 'Grid',
            },
        },
        height: 500
    }

    const settings = {
        header: {
            left: 'prev, next today',
            center: 'title', // buttons for switching between views
            right: 'dayGridMonth, timeGridWeek, timeGridDay, listMonth, planeGrid',
        },
        titleFormat: { month: 'long', day: 'numeric', year: 'numeric' },
        views: {
            timeGridWeek: {
                type: 'timeGridWeek',
                duration: { days: 7 },
                buttonText: 'Week',
            },
            timeGridDay: {
                type: 'timeGridDay',
                duration: { days: 1 },
                buttonText: 'Day',
            },
            dayGridMonth: {
                type: 'dayGridMonth',
                duration: { weeks: 6 },
                buttonText: 'Month',
            },
            listMonth: {
                type: 'listMonth',
                duration: { days: 30 },
                buttonText: 'List',
            },
            planeGrid: {
                type: 'resourceTimeGrid',
                duration: { days: 1 },
                buttonText: 'Grid',
            },
        },
        height: 700
    }
    
    // Calendar Ref enables react hooks on event move
    const CalendarRef = useRef()

    // Block the date loaded after the first time it was set
    const [dateHasLoaded, setDateHasLoaded] = useState(false)

    // An array of events to show (or block) on the calendar
    const [events, setEvents] = useState([])

    const [planeActiveEvent, setPlaneActiveEvent] = useState(null)
    
    // Create the resources
    const [resourcePlanes, setResourcePlanes] = useState([])

    // NOTE: The inital calendar height was not working properly when the state was {}
    // So default to the mobile settings - useLayoutEffect still works properly
    
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
        height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    })

    // Set the state of the settings based on mobile/desktop
    const [viewState, setViewState] = useState(settings)
    
    // Set initial state of schedule widget
    const [dropdown, setDropdown] = useState(false)

    // Set the plugins to use for the calendar
    const plugins = [
        dayGridPlugin,
        interactionPlugin,
        resourcePlugin,
        resourceTimelinePlugin,
        resourceDayGridPlugin,
        resourceTimeGridPlugin,
        timeGridPlugin,
        bootstrapPlugin,
        listPlugin,
        timelinePlugin,
        googleCalendarPlugin,
    ]

    // Get the events for the calendar that include either your acceptable instructors or acceptable planes
    useEffect(() => {

        async function getResources() {

            // Create a temporary array
            let resourceTempList = []

            //Add the user to the resource list
            let self = {
                id: userData.uid,
                title: String(userData.firstName + ' ' + userData.lastName + " (Self)"),
                eventOverlap: false,
                eventColor: "#1e88e5",

            }
            resourceTempList.push(self)

            // If the user has more than 0 acceptable planes
            if (userData.acceptablePlanes.length > 0) {

                // For each plane in that list
                userData.acceptablePlanes.forEach(key => {

                    // And check through each FBO for the plane
                    Object.keys(fboData).forEach(fboKey => {

                        // If the plane exsists
                        // FYI: https://ponyfoo.com/articles/null-propagation-operator
                        if (fboData[fboKey]?.planes?.[key]) {

                            // Set the proper resource details per fullcalendar.io
                            let plane = {
                                id: key,
                                title: String(fboData[fboKey].planes[key].manufacturer + " " + fboData[fboKey].planes[key].planeDetails.model.value + " (" + fboData[fboKey].planes[key].identifier + ")"),
                                eventOverlap: false,
                                eventColor: "#ff7043",

                            }

                            // Push each object to the temp array
                            resourceTempList.push(plane)
                        }
                    })


                })
            }

            // If the user has more than 0 acceptable instructors
            if (userData.acceptablePilots.length) {

                // For each instructor in that list
                userData.acceptablePilots.forEach(key => {

                    // And check through each FBO for the instructor
                    Object.keys(fboData).forEach(fboKey => {

                        // If the instructor exsists
                        if (fboData[fboKey]?.employees?.[key]) {

                            // Set the proper resource details per fullcalendar.io
                            let instructor = {
                                id: key,
                                title: String(fboData[fboKey].users[key].firstName + " " + fboData[fboKey].users[key].lastName),
                                eventOverlap: false,
                                eventColor: "#4caf50",

                            }

                            // Push each object to the temp array
                            resourceTempList.push(instructor)
                        }
                    })


                })
            }

            // Set the resourcePlanes from the array we created
            setResourcePlanes(resourceTempList)

        }

        async function getEvents() {
            await getResources()

            return (
                // The fetch events will return a list of events given the user data and fbo data
                FetchEvents(userData.uid, userData.acceptablePlanes, userData.acceptablePilots, Object.keys(fboData)).then(result => {

                    setEvents(result)
                })
            )
        }

        getEvents()

    }, [userData, fboData])


    /**
     * The useLayoutEffect will set the way that we show the time grid based on mobile or full screen
     */
    useLayoutEffect(() => {

        

        function handleWindowResize() {

            setDimensions({
                width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
                height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
            })

        }

        window.addEventListener('resize', handleWindowResize)

        if (dimensions.width < 600) {
            console.log('mobile settings')
            setViewState(mobileSettings)
            setDropdown(true)
        } else {
            console.log('desktop settings')
            setViewState(settings)
            setDropdown(false)
        }
        return _ => {
            window.removeEventListener('resize', handleWindowResize)
        }

    }, [dimensions])


    function makeTheInitialView() {

        // Only do the function if the date hasn't loaded once and only if the CalendarRef is loaded
        if (dateHasLoaded || !CalendarRef.current) return
        
        // Have start date but not view, set the date and default to timeGridWeek
        if (query.get('startDate') && !query.get('view')) {
            CalendarRef.current.getApi().changeView('timeGridWeek', query.get('startDate'))
        }

        // Have view but not the date, set the view and use current date
        if (query.get('view') && !query.get('startDate')) {
            CalendarRef.current.getApi().changeView(query.get('view'))
        }

        // Have both, set them both
        if (query.get('view') && query.get('startDate')) {
            CalendarRef.current.getApi().changeView(query.get('view'), query.get('startDate'))
        }
        
        // Ensure we are not updating a thousand times
        setDateHasLoaded(true)
    }

    

    /**
     * Handle actions when an event is moved from one time to a different time (or different resource)
     * @param {EventDropInfo} arg The info for the event that was dropped, arg.event has traditional event info. See: https://fullcalendar.io/docs/eventDrop
     */
    function handleDrop(arg) {

        // Unsure of these two lines but they make things work - React/fullcalendar interface issue
        const calendarApi = CalendarRef.current.getApi()
        calendarApi.unselect()

        // Get a new events array and the index that needs to be changed
        let newEvents = [...events]
        let changeIndex = newEvents.findIndex(x => x.id === arg.event.id)
                
        // Create the event with the new start and end time
        // Note that the arg.event object does NOT come with resourceIds. Theny must be obtained with the getResources() function and looped
        let newEvent = {...arg.event.extendedProps,
            id: arg.event.id,
            title: arg.event.title,
            start: moment(arg.event.start).format('YYYY-MM-DD HH:mm'),
            end: moment(arg.event.end).format('YYYY-MM-DD HH:mm'),
            // color: arg.event.backgroundColor,
            allDay: false,
            resourceIds: arg.event.getResources().map(resource => {return resource.id})
        }

        // If the event is all day, then say so. If it doesn't have an end value, then set one as +2 Hours
        if (arg.event.allDay === true) {
            newEvent.allDay = true
        } else if (arg.event.end === null) {
            newEvent.end = moment(arg.event.start).add(2,'h').format('YYYY-MM-DD HH:mm')
            newEvent.allDay = false   
        }

        // Update the new events
        newEvents[changeIndex] = newEvent
        
        // Set them locally and save the event on Firebase
        setEvents(newEvents)
        UpdateEvent(arg.event.id, newEvent, userData.uid)

    }

    /**
     * 
     * Similar to handleDrop, this will receive an event that has been dropped
     * See above for instructions regarding resouceIds
     * 
     * @param {eventDropInfo} arg And event Drop object
     */
    function handleResize(arg) {    

        // Handle the fullcalendar / react interface
        const calendarApi = CalendarRef.current.getApi()
        calendarApi.unselect()
        
        // Get the old events and find the index that needs changed
        let newEvents = [...events]
        const changeIndex = newEvents.findIndex(x => x.id === arg.event.id)

        // Set the new event data
        const newEvent = {...arg.event.extendedProps,
            id: arg.event.id,
            title: arg.event.title,
            start: moment(arg.event.start).format('YYYY-MM-DD HH:mm'),
            end: moment(arg.event.end).format('YYYY-MM-DD HH:mm'),
            // color: arg.event.backgroundColor,
            allDay: false,
            resourceIds: arg.event.getResources().map(resource => {return resource.id}),
        }

        // Update the events list
        newEvents[changeIndex] = newEvent

        // Save them locally and update them on the database
        setEvents(newEvents)
        UpdateEvent(arg.event.id, newEvent, userData.uid)

    }

    /**
     * When an event is clicked, set the active event and show the dialog
     * 
     * @param {EventInfoObject} arg 
     */
    function handleEventClick(arg) {

        // NO LONGER USING TABS ON PROFILE PAGE
        
        // Get the current view and the current start date
        // const currentView = CalendarRef.current.getApi().view

        // const startDay = moment(currentView.currentStart).format('YYYY-MM-DD')
        // const startView = currentView.type

        // props.history.push(`/profile?startDate=${startDay}&view=${startView}`)
        
        let changeIndex = events.findIndex(x => x.id === arg.event.id)

        setPlaneActiveEvent(events[changeIndex])

    }

    /**
     * 
     * Update an event that has been changed
     * 
     * @param {EventObject} newEvent 
     * @param {String} action The action to be taken. Supports: 'delete'
     */
    function handleUpdateEvent(newEvent, action) {
        

        // Save the old events and find the index to change
        let newEvents = [...events]
        let changeIndex = newEvents.findIndex(x => x.id === newEvent.id)
       
        // To delete, splice off the index to remove and delete from the database (marks deleted not actual delete)
        if (action === 'delete') {

            newEvents.splice(changeIndex, 1)
            DeleteEvent(newEvent.id, userData.uid)

        }
        
        // Remove the active event dialog and update the event list
        setPlaneActiveEvent(null)
        setEvents(newEvents)
    }


    // During a drag and drop event, this will do advanced checks if the event can be moved
    /**
     * 
     * @param {Event} stillEvent The event that is being covered by a moving event
     * @param {Event} movingEvent The event that is being clicked and dragged (or resized)
     */
    function handleOverlap(stillEvent, movingEvent) {

        console.log('checking')
        console.log(stillEvent.extendedProps.planeUID)
        console.log(movingEvent.extendedProps.planeUID)

        // This may be deprecated at some point
        if (stillEvent.extendedProps.planeUID === movingEvent.extendedProps.planeUID) {

            return false

        } else {

            return true

        }
    }

    /**
     * Depending on the user's screen sice show the flight panel or show a dropdown to display it
     */
    function FlightFilter() {

        if (dropdown) {
            return (
                <>
                    <Accordion>

                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            id="scheduler"
                        >
                            <Typography variant="h6">Schedule Event</Typography>
                        </AccordionSummary>

                        <AccordionActions>
                            <AddFlightFilter
                                userData={userData}
                                fboData={fboData}
                                oldEvents={events}
                                setEvents={setEvents}
                            />
                        </AccordionActions>

                    </Accordion>
                </>
            )
        } else {
            return (
                <>
                    <Typography variant="h6">Schedule Event</Typography>

                    <AddFlightFilter
                        userData={userData}
                        fboData={fboData}
                        oldEvents={events}
                        setEvents={setEvents}
                    />
                </>
            )
        }
    }
    
    return (
        <>

            <Grid item xs sm={3} align="center">

                {FlightFilter()}

            </Grid>            
    
            <Grid item xs={12} sm={8} align="center">

                {planeActiveEvent &&
                    <EditFlightDialog
                    activeEvent={planeActiveEvent}
                    updateEvent={handleUpdateEvent}
                    userData={userData}
                    fboData={fboData}
                    oldEvents={events}
                    setEvents={setEvents}
                    />
                }
                
                <FullCalendar
                    ref={CalendarRef} // Ref for manageing react/fullcalendar interface
                    schedulerLicenseKey="GPL-My-Project-Is-Open-Source" // License
                    themeSystem="bootstrap" // Button theme
                    defaultView='resourceTimeGrid' // Only show week view
                    views={viewState.views} // The list of views available (Month, Week, Day, etc)
                    titleFormat={viewState.titleFormat}
                    resources={resourcePlanes} // Those planes/rooms/assetss available to the user
                    refetchResourcesOnNavigate={true} // TODO: What does this do?
                    nowIndicator={true} // Show a red line for the current time
                    eventDrop={handleDrop} // When an event is moved, handle that action
                    eventResize={handleResize} // When an event is resized, manage that
                    droppable={true} // See if an event can be moved
                    eventClick={handleEventClick} // Handle the actions when an event is clicked on                    
                    height={viewState.height} // Set the calendar height
                    eventOverlap={handleOverlap} // Check if an event can be dropped (when drug or resized) on a location
                    plugins={plugins}        // The plugins used 
                    editable={true} // If a calendar's events can be changed
                    selectable={true} // TODO: What does this do?
                    handleWindowResize={true} // Change calendar size when a window is changed
                    header={viewState.header} // Hide buttons/titles
                    minTime='00:00:00' // Start time for the calendar
                    maxTime='24:00:00' // End time for the calendar
                    scrollTime={moment().format("HH:mm:ss")} // TODO: Unsure what this does?
                    displayEventTime={true} // Show the event time on the event
                    allDayText='All Day' // Text shown in first column second row of the calendar
                    events={events} // The list of events for the calendar to show (disabled and real)
                    viewSkeletonRender={makeTheInitialView()} // DEPRECATION WARNING (DEPRECATED) - This will break on 5.0.0 - change to viewDidMount 
                />
                
                
            </Grid>
        </>

    )
}

export default withRouter(FCalendar)

// The Props an FCalendar needs
FCalendar.propTypes = {
    userData: PropTypes.object,
    fboData: PropTypes.object,
    events: PropTypes.object,
    setEvents: PropTypes.object,
}
