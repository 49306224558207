import React, { useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import {
    Dialog, DialogTitle, DialogActions, Snackbar,
    Typography, FormControl, Button, FormHelperText, TextField, DialogContent
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'; 
import moment from 'moment'
import { UpdateEvent } from '../../../helpers/SaveData'

/**
 * @param {Props} props -
 * - activeEvent - the event that you clicked on
 * - updateEvent - function called from Calendar.js with paramaters (event, action)
 */
export default function EditFlightDialog(props) {

    const { activeEvent, updateEvent, oldEvents, userData, setEvents } = props
    const history = useHistory()

    const [logData, setLogData] = useState({
        date: String(moment(activeEvent.start).format('YYYY-MM-DD')),
        start: String(moment(activeEvent.start).format('HH:mm')),
        end: String(moment(activeEvent.end).format('HH:mm')),
    })
    const [activateButton, setActivateButton] = useState(false)
    const [alert, setAlert] = useState('')
    const [openSuccess, setOpenSuccess] = useState(false)

    console.log(activeEvent)

    useEffect(() => {

        // If the date is today's date
        if (logData.date === moment().format('YYYY-MM-DD')) {

            // If the start time is greater than the current time
            if (logData.start > moment().format('HH:mm')) {

                // If the start time is less than the end time
                if (logData.start < logData.end) {

                    // Set the alert to nothing
                    setAlert('')
                    // Allow the user to schedule the flight
                    setActivateButton(true)

                    // NOTE: This handles if the event runs into the early AM
                } else if (logData.start > "20:00" && logData.end < "12:00") {

                    // Set the alert to nothing
                    setAlert('')
                    // Allow the user to schedule the flight
                    setActivateButton(true)

                } else {
                    setAlert('The start time is before the end time!')
                    setActivateButton(false)
                }

            } else {
                setAlert('The start time is before the current time!')
                setActivateButton(false)
            }

            // Else if the date is greater than the current date
        } else if (logData.date > moment().format('YYYY-MM-DD')) {

            // NOTE:  We can allow the start time to be less than the current time
            // If the start time is less than the end time
            if (logData.start < logData.end) {

                // Set the alert to nothing
                setAlert('')
                // Allow the user to schedule the flight
                setActivateButton(true)

                // NOTE: This handles if the event runs into the early AM
            } else if (logData.start > "20:00" && logData.end < "12:00") {

                // Set the alert to nothing
                setAlert('')
                // Allow the user to schedule the flight
                setActivateButton(true)

            } else {
                setAlert('The start time is before the end time!')
                setActivateButton(false)
            }

        } else {
            setAlert("The chosen date is before today's date!")
            setActivateButton(false)
        }


        if (logData.date === String(moment(activeEvent.start).format('YYYY-MM-DD')) && 
            logData.start === String(moment(activeEvent.start).format('HH:mm')) &&
            logData.end === String(moment(activeEvent.end).format('HH:mm'))
        ) {
            setActivateButton(false)
            setAlert('')
        }
    }, [logData, activeEvent])

    /**
         * Checks that there is no overlap in resource Ids
         * @param {EventObject} newEvent - FullCalendar Event Object
         */
    function NewCheckFlight(logData) {

        let newEvent = {
            ...activeEvent,
            start: logData.date + ' ' + logData.start,
            end: logData.date + ' ' + logData.end
        }

        let conflicts = oldEvents.filter(oldEvent => {

            // If the two resource arrays have a common element - *4 via two resourceIds per event
            if (newEvent.resourceIds.some(id => oldEvent.resourceIds.includes(id))) {

                // Added this in to be able to edit events that are itself
                if (newEvent.id === oldEvent.id) {
                    return false
                }

                let newEventTime = { start: moment(newEvent.start), end: moment(newEvent.end) }
                let oldEventTime = { start: moment(oldEvent.start), end: moment(oldEvent.end) }

                if (newEventTime.start.isSameOrBefore(oldEventTime.start) && newEventTime.end.isAfter(oldEventTime.start)) {
                    console.error('Rejected do to #1')
                    return true
                }
                if (newEventTime.start.isBefore(oldEventTime.end) && newEventTime.end.isSameOrAfter(oldEventTime.end)) {
                    console.error('Rejected do to #2')
                    return true
                }
                if (newEventTime.start.isSameOrAfter(oldEventTime.start) && newEventTime.end.isSameOrBefore(oldEvent.end)) {
                    console.error('Rejected do to #3')
                    return true
                }

            }

            return false

        })

        if (conflicts.length) {

            setActivateButton(false)
            setAlert('Event scheduled during this time frame!')

        } else {
            ScheduleEvent(newEvent)
        }

    }

    function ScheduleEvent(newEvent) {

        // handleClose(newEvent)
        let newEvents = [...oldEvents]
        let changeIndex = newEvents.findIndex(x => x.id === newEvent.id)

        newEvents[changeIndex] = newEvent
        setEvents(newEvents)
        
        // Add the event to the database
        UpdateEvent(newEvent.id, newEvent, userData.uid).then(() => {
            setOpenSuccess(true)
        })

    }

    function handleChange(arg) {
        const value = arg.target.value
        setLogData({
            ...logData,
            [arg.target.name]: value,
        })
    }

    let testTime = moment(activeEvent.start)
    let now = moment()
    let withinAnHour = false

    // LEAVE THIS PLEASE
    // Handles whether or not the delete button is activated or not
    if (testTime.diff(now, 'minutes') <= 60) {
        withinAnHour = true
    }

    function handleClose() {
        updateEvent(activeEvent)
    }

    function handleDelete() {
        updateEvent(activeEvent, 'delete')
    }

    function handleReset() {

        // Get rid of the alert
        setAlert('')

        // Turn off the active button
        setActivateButton(false)

        // Set the data back to og
        setLogData({
            date: String(moment(activeEvent.start).format('YYYY-MM-DD')),
            start: String(moment(activeEvent.start).format('HH:mm')),
            end: String(moment(activeEvent.end).format('HH:mm')),
        })

    }

    return (
        <Dialog className="edit-flight-dialog" maxWidth={'xs'} fullWidth open={true} onClose={handleClose} >

            <DialogTitle className="title">
                {activeEvent.title}
                <Typography variant="body2">{String(moment(activeEvent.start).format('dddd, MMMM Do h:mma'))} - {String(moment(activeEvent.end).format('h:mma'))}</Typography>
            </DialogTitle>

            <DialogActions>

                <div className="field-container">

                    <FormControl fullWidth align="left">

                        <FormHelperText className="label" id="date">Date</FormHelperText>
                        <TextField
                            className="text-field"
                            size="small"
                            id="start"
                            type="date"
                            variant="outlined"
                            name="date"
                            value={logData.date}
                            onChange={handleChange}
                        />

                        <FormHelperText className="label" id="start">Start Time</FormHelperText>
                        <TextField
                            className="text-field"
                            size="small"
                            id="start"
                            type="time"
                            variant="outlined"
                            name="start"
                            value={logData.start}
                            onChange={handleChange}
                        />

                        <FormHelperText className="label" id="end">End Time</FormHelperText>
                        <TextField
                            className="text-field"
                            size="small"
                            id="end"
                            type="time"
                            variant="outlined"
                            name="end"
                            value={logData.end}
                            onChange={handleChange}
                        />

                    </FormControl>

                </div>
                
            </DialogActions>

            <DialogActions>
                <div className="button-container">

                    {
                        activateButton ?
                            <Button className="button" color="secondary" variant="contained" fullWidth onClick={() => NewCheckFlight(logData)}>Update Event</Button>
                            :
                            <Button className="button" color="secondary" variant="contained" fullWidth disabled>Update Event</Button>
                    }

                    <Button className="button" color="primary" variant="outlined" fullWidth onClick={() => handleReset()}>Reset</Button>

                </div>
                
                
            </DialogActions>

            {
                activateButton ?
                    null
                    :
                    <>
                        {
                            alert === '' ?
                                null
                                :
                                <DialogContent>
                                    <Typography align="center" className="alert">{alert}</Typography>
                                </DialogContent>
                        }
                    </>
            }
            
            

            <DialogActions>

                <div className="button-container">
                    {!activeEvent.block && <Button className="button" color="primary" variant="contained" onClick={() => history.push(`/event/${activeEvent.id}`)}>Event Details</Button>}
                    
                    {activeEvent?.block ?
                        <Button className="block-button" color="primary" variant="contained" onClick={() => handleDelete()}>Delete Event</Button>
                        :
                        <Button className="button" color="primary" variant="contained" disabled={withinAnHour} onClick={() => handleDelete()}>Delete Event</Button>
                    } 
                    
                    {/* {
                        !withinAnHour ?
                            <Button className="button" color="primary" variant="contained" onClick={() => handleDelete()}>Delete Event</Button>
                            :
                            <Button className="button" color="primary" variant="contained" disabled>Delete Event</Button>

                    } */}

                </div>

            </DialogActions>

            <Snackbar open={openSuccess} autoHideDuration={4000} onClose={() => setOpenSuccess(false)}>
                <Alert onClose={() => setOpenSuccess(false)} severity="success">Event has been updated!</Alert>
            </Snackbar>

        </Dialog>
    )
}