import React, {useState, useEffect} from 'react'
import {
    FormHelperText, FormControl, Button, Select, MenuItem, TextField, Paper, Typography, FormControlLabel, Checkbox, Snackbar
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'; 
import moment from 'moment'
import { AddEvent } from '../../../helpers/SaveData'
import { certifications } from '../../../defaults/certifications'

export default function AddFlightFilter(props) {

    const {userData, fboData, oldEvents, setEvents} = props
    const [selections, setSelections] = useState({
        plane: '',
        instructor: '',
        date: String(moment().add(1, 'day').format('YYYY-MM-DD')),
        startTime: String(moment().add(1, 'h').startOf('hour').format('HH:mm')),
        endTime: String(moment().add(3, 'h').startOf('hour').format('HH:mm')),
        blockReason: '',
        allDay: false,
    })
    const [activateButton, setActivateButton] = useState(false)
    const [alert, setAlert] = useState('')
    const [filterType, setFilterType] = useState(true)
    const [openSuccess, setOpenSuccess] = useState(false)

    useEffect(() => {

        // If the user is scheduling a flight
        if (filterType) {

            // If the Select boxes are not empty
            if (selections.instructor !== '' & selections.plane !== '') {

                // If the date is today's date
                if (selections.date === moment().format('YYYY-MM-DD')) {

                    // If the start time is greater than the current time
                    if (selections.startTime > moment().format('HH:mm')) {

                        // If the start time is less than the end time
                        if (selections.startTime < selections.endTime) {

                            // Set the alert to nothing
                            setAlert('')
                            // Allow the user to schedule the flight
                            setActivateButton(true)

                            // NOTE: This handles if the event runs into the early AM
                        } else if (selections.startTime > "20:00" && selections.endTime < "12:00") {

                            // Set the alert to nothing
                            setAlert('')
                            // Allow the user to schedule the flight
                            setActivateButton(true)

                        } else {
                            setAlert('The start time is before the end time!')
                            setActivateButton(false)
                        }

                    } else {
                        setAlert('The start time is before the current time!')
                        setActivateButton(false)
                    }

                    // Else if the date is greater than the current date
                } else if (selections.date > moment().format('YYYY-MM-DD')) {

                    // NOTE:  We can allow the start time to be less than the current time
                    // If the start time is less than the end time
                    if (selections.startTime < selections.endTime) {

                        // Set the alert to nothing
                        setAlert('')
                        // Allow the user to schedule the flight
                        setActivateButton(true)

                        // NOTE: This handles if the event runs into the early AM
                    } else if (selections.startTime > "20:00" && selections.endTime < "12:00") {

                        // Set the alert to nothing
                        setAlert('')
                        // Allow the user to schedule the flight
                        setActivateButton(true)

                    } else {
                        setAlert('The start time is before the end time!')
                        setActivateButton(false)
                    }

                } else {
                    setAlert("The chosen date is before today's date!")
                    setActivateButton(false)
                }

            } else {
                setActivateButton(false)
            }

            // The user is scheduling a block
            // NOTE: This is Tyler being lazy 
            // POWER OF CTRL+C 
        } else {

            // If the date is today's date
            if (selections.date === moment().format('YYYY-MM-DD')) {

                // If the start time is greater than the current time
                if (selections.startTime > moment().format('HH:mm')) {

                    // If the start time is less than the end time
                    if (selections.startTime < selections.endTime) {

                        // Set the alert to nothing
                        setAlert('')
                        // Allow the user to schedule the flight
                        setActivateButton(true)

                        // NOTE: This handles if the event runs into the early AM
                    } else if (selections.startTime > "20:00" && selections.endTime < "12:00") {

                        // Set the alert to nothing
                        setAlert('')
                        // Allow the user to schedule the flight
                        setActivateButton(true)

                    } else {
                        setAlert('The start time is before the end time!')
                        setActivateButton(false)
                    }

                } else {
                    setAlert('The start time is before the current time!')
                    setActivateButton(false)
                }

                // Else if the date is greater than the current date
            } else if (selections.date > moment().format('YYYY-MM-DD')) {

                // NOTE:  We can allow the start time to be less than the current time
                // If the start time is less than the end time
                if (selections.startTime < selections.endTime) {

                    // Set the alert to nothing
                    setAlert('')
                    // Allow the user to schedule the flight
                    setActivateButton(true)

                    // NOTE: This handles if the event runs into the early AM
                } else if (selections.startTime > "20:00" && selections.endTime < "12:00") {

                    // Set the alert to nothing
                    setAlert('')
                    // Allow the user to schedule the flight
                    setActivateButton(true)

                } else {
                    setAlert('The start time is before the end time!')
                    setActivateButton(false)
                }

            } else {
                setAlert("The chosen date is before today's date!")
                setActivateButton(false)
            }
        }
        

    }, [selections])
    
    function ShowPlaneDropDown() {

        let planes = []

        Object.keys(fboData).forEach(fboKey => {

            Object.keys(fboData[fboKey].planes).forEach(planeKey => {

                if (!fboData[fboKey].planes[planeKey]?.deleted) {
                    
                    if (fboData[fboKey].planes[planeKey].users.includes(userData.uid)) {

                        if (userData.acceptablePlanes.includes(planeKey)) {

                            planes.push(
                                <MenuItem
                                    key={planeKey}
                                    value={planeKey}
                                >
                                    <b>{fboData[fboKey].planes[planeKey].manufacturer} {fboData[fboKey].planes[planeKey].planeDetails.model.value}</b> - {fboData[fboKey].planes[planeKey].identifier}
                                </MenuItem>
                            )
                        }


                    }
                }

                

            })

        })

        if (planes.length === 0) {
            let displayValue = <MenuItem value={''}>
                Choose planes in settings!
            </MenuItem>
            return (
                <>
                    <FormHelperText id="plane">Plane</FormHelperText>
                    <Select
                        id="plane"
                        className="text-field"
                        variant="outlined"
                        displayEmpty={true}
                        value={''}
                        // onChange={(event) => setSelections({ ...selections, plane: event.target.value })}
                        MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                            }
                        }}
                    >
                        {displayValue}
                    </Select>
                </>
            )
        }
        
        return (
            <>
                <FormHelperText id="plane">Plane</FormHelperText>
                <Select 
                    id="plane"
                    className="text-field"
                    variant="outlined" 
                    displayEmpty={true} 
                    value={selections.plane}
                    onChange={(event) => setSelections({ ...selections, plane: event.target.value })}
                    MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        }
                    }}
                >   
                    {planes}
                </Select>
            </>
        )
        

    }

    
    // TODO - Handle instructors that are part of multiple FBO's the the user is also part of
    function ShowInstructorDropDown() {

        let instructors = []

        Object.keys(fboData).forEach(fboKey => {

            Object.keys(fboData[fboKey].employees).forEach(employeeKey => {

                if (fboData[fboKey].employees[employeeKey].type?.instructor?.active) {

                    if (userData.acceptablePilots.includes(employeeKey)) {
                        
                        let key = `${fboKey}_${employeeKey}`
                        let label = `${fboData[fboKey].users[employeeKey].firstName} ${fboData[fboKey].users[employeeKey].lastName} (${fboData[fboKey].info.name})`
                        instructors.push(
                            <MenuItem
                                key={key}
                                value={employeeKey}
                            >
                                {label}
                            </MenuItem> 
                        )
                    }
                }

            })

        })

        let certList = []
        Object.keys(certifications).forEach(cert => {
            certList.push(certifications[cert].value)
        })

        if (userData.certifications.some(r => certList.indexOf(r, 1) >= 0)) {
            instructors.push(
                <MenuItem
                    key="none"
                    value="none"
                >
                    None
                </MenuItem>
            )
        }

        instructors.push(
            <MenuItem
                key="check"
                value="check"
            >
                Check Ride
            </MenuItem>
        )
        
        return (
            <>
                <FormHelperText id="instructor">Instructor</FormHelperText>
                <Select
                    id="instructor"        
                    className="text-field"
                    variant="outlined" 
                    displayEmpty={true} 
                    value={selections.instructor}
                    onChange={(event) => setSelections({ ...selections, instructor: event.target.value })}
                    fullWidth={true}
                    MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        }
                    }}
                >
                    {instructors}
                </Select>
            </>
        )
        

    }
    
    function handleReset() {

        setAlert('')

        setActivateButton(false)

        setSelections({
            plane: '',
            instructor: '',
            date: String(moment().add(1, 'day').format('YYYY-MM-DD')),
            startTime: String(moment().add(1, 'h').startOf('hour').format('HH:mm')),
            endTime: String(moment().add(3, 'h').startOf('hour').format('HH:mm')),
            blockReason: '',
            allDay: false,
        })

    }

    

    /**
     * Checks that there is no overlap in resource Ids
     * @param {EventObject} newEvent - FullCalendar Event Object
     * @param {String} fboKey - the FBO's UID
     * @param {Boolean} hasInstructor - A check if the flight has an instructor
     */
    function NewCheckFlight(newEvent, fboKey, hasInstructor) {

        let conflicts = oldEvents.filter(oldEvent => {

            // If the two resource arrays have a common element - *4 via two resourceIds per event
            if (newEvent.resourceIds.some(id => oldEvent.resourceIds.includes(id))) {

                let newEventTime = { start: moment(newEvent.start), end: moment(newEvent.end) }
                let oldEventTime = { start: moment(oldEvent.start), end: moment(oldEvent.end) }
                
                // If the event is an all day event
                if (oldEvent?.allDay) {

                    if (newEventTime.start.isSame(oldEventTime.start, 'day')) {
                        return true
                    }
                }

                if (newEventTime.start.isSameOrBefore(oldEventTime.start) && newEventTime.end.isAfter(oldEventTime.start)) {
                    console.error('Rejected due to #1')
                    return true
                }
                if (newEventTime.start.isBefore(oldEventTime.end) && newEventTime.end.isSameOrAfter(oldEventTime.end)) {
                    console.error('Rejected due to #2')
                    return true
                }
                if (newEventTime.start.isSameOrAfter(oldEventTime.start) && newEventTime.end.isSameOrBefore(oldEvent.end)) {
                    console.error('Rejected due to #3')
                    return true
                }
            }

            return false
        })

        if (conflicts.length) {

            setActivateButton(false)
            setAlert('Event scheduled during this time frame!')

        } else {
            ScheduleEvent(newEvent, fboKey, hasInstructor)
        }

    }

    /**
     * 
     * @param {Object} newEvent The event object formatted in FullCalendar expectations
     * @param {String} fboKey - The key of the FBO the event belongs to
     * @param {Boolean} hasInstructor - A Boolean if there is an instructor on the flight
     */
    function ScheduleEvent(newEvent, fboKey, hasInstructor) {

        let newEvents = [...oldEvents] 

        let assetType = 'planes'
        if (newEvent?.block) {
            assetType = 'blocks'
        }
        // Add the event to the database
        AddEvent(newEvent, userData.uid, assetType, fboKey, hasInstructor).then(result => {

            // Then update the events ID
            newEvent.id = result

            // Add it to the events list and set the new list
            newEvents.push(newEvent)
            console.log(newEvents)
            setEvents(newEvents)
            setOpenSuccess(true)
        })

    
    }



    function handleScheduleFlight() {

        // The selections the user input
        console.log(selections)

        // For each FBO
        Object.keys(fboData).forEach(fboKey => {

            // If the plane exists
            if (fboData[fboKey].planes[selections.plane] !== undefined) {

                // Create the planeData object
                const planeData = { ...fboData[fboKey].planes[selections.plane], key: selections.plane }

                // Format the start and end times
                let event_start = selections.date + ' ' + selections.startTime
                let event_end = selections.date + ' ' + selections.endTime

                let resources = [userData.uid, selections.plane, selections.instructor]

                let hasInstructor = false

                // Format the title 
                let title = ''
                if (selections.instructor === "none") {
                    title = String("Flight with " + userData.firstName + " " + userData.lastName + " in " + planeData.manufacturer + " " + planeData.planeDetails.model.value + " (" + planeData.identifier + ")")
                    resources = [userData.uid, selections.plane]
                } else if (selections.instructor === "check") {
                    title = String("Check ride in " + planeData.manufacturer + " " + planeData.planeDetails.model.value + " (" + planeData.identifier + ")")
                    resources = [userData.uid, selections.plane]
                } else {
                    title = String("Flight with " + fboData[fboKey].users[selections.instructor].firstName + " " + fboData[fboKey].users[selections.instructor].lastName + " in " + planeData.manufacturer + " " + planeData.planeDetails.model.value + " (" + planeData.identifier + ")")
                    hasInstructor = selections.instructor
                }

                // If the start time is greater than 8pm and the end time is in the early AM
                if (selections.startTime > "20:00" && selections.endTime < "12:00") {

                    // Add an extra day to the end date
                    event_end = moment(selections.date).add(1, 'days').format("YYYY-MM-DD") + ' ' + selections.endTime
                }

                // Create the newEvent object
                const newEvent = {
                    title: title,
                    resourceIds: resources, //Order of this list matters for color preference on calendar (in this case - the user's resource color (blue))
                    backgroundColor: '#b5d2ff',
                    // color: '#4287f5',
                    start: event_start,
                    end: event_end,
                    hobbsStart: 0,
                    hobbsEnd: 0,
                    groundTime: 0,
                    resourceEditable: true,
                    planeUID: selections.plane,
                }
                console.log(newEvent)

                NewCheckFlight(newEvent, fboKey, hasInstructor)
            }


        })

    }

    function handleScheduleBlock() {

        // The selections the user input
        console.log(selections)

        // Blocks don't need to be part of an fbo
        let fboKey = ''

        // Format the start and end times
        let event_start = selections.date + ' ' + selections.startTime
        let event_end = selections.date + ' ' + selections.endTime

        let resources = [userData.uid]
        let hasInstructor = false
        let title = ''
        if (selections.blockReason !== '') {
            title = selections.blockReason
        }

        // If the start time is greater than 8pm and the end time is in the early AM
        if (!selections.allDay && selections.startTime > "20:00" && selections.endTime < "12:00") {

            // Add an extra day to the end date
            event_end = moment(selections.date).add(1, 'days').format("YYYY-MM-DD") + ' ' + selections.endTime

        }

        if (selections.allDay) {
            event_end = ''
        }

        // Create the newEvent object
        const newBlock = {
            title: title,
            resourceIds: resources, //Order of this list matters for color preference on calendar (in this case - the user's resource color (blue))
            backgroundColor: '#212121', // We want these blocks to be black
            textColor: 'white',
            start: event_start,
            end: event_end,
            hobbsStart: 0,
            hobbsEnd: 0,
            groundTime: 0,
            resourceEditable: true,
            planeUID: selections.plane,
            allDay: selections.allDay,
            block: true,
        }

        NewCheckFlight(newBlock, fboKey, hasInstructor)
            
    }

    function handleChangeEventType(type) {
        if (type === 'flight') {
            setFilterType(true)
        } else if (type === 'block') {
            setFilterType(false)
        }
        setSelections({
            plane: '',
            instructor: '',
            date: String(moment().add(1, 'day').format('YYYY-MM-DD')),
            startTime: String(moment().add(1, 'h').startOf('hour').format('HH:mm')),
            endTime: String(moment().add(3, 'h').startOf('hour').format('HH:mm')),
            blockReason: '',
            allDay: false,
        })
    }

    return (
        <div className="filter-container" >

            {userData?.employedBy.length > 0 &&
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly', padding: '10px' }}>
                    <Button variant='contained' className={filterType ? 'type-button-selected' : 'type-button'} onClick={()=>handleChangeEventType('flight')}>Flight</Button>
                    <Button variant='contained' className={filterType ? 'type-button' : 'type-button-selected'} onClick={() =>handleChangeEventType('block')}>Block</Button>
                </div>
            }

            {filterType ?
                <Paper className="flight-filter" elevation={1} >
                    <FormControl fullWidth>
                        {ShowInstructorDropDown()}
                    </FormControl>

                    <FormControl fullWidth>
                        {ShowPlaneDropDown()}
                    </FormControl>

                    <FormControl fullWidth>

                        <FormHelperText id="date">Date</FormHelperText>
                        <TextField
                            className="text-field"
                            id="date"
                            type="date"
                            variant="outlined"
                            name="date"
                            value={selections.date}
                            onChange={(event) => setSelections({ ...selections, date: event.target.value })}
                        />

                        <FormHelperText id="startTime">Start Time</FormHelperText>
                        <TextField
                            className="text-field"
                            id="startTime"
                            type="time"
                            variant="outlined"
                            name="startTime"
                            value={selections.startTime}
                            onChange={(event) => setSelections({ ...selections, startTime: event.target.value })}
                        />

                        <FormHelperText id="endTime">End Time</FormHelperText>
                        <TextField
                            className="text-field"
                            id="endTime"
                            type="time"
                            variant="outlined"
                            name="endTime"
                            value={selections.endTime}
                            onChange={(event) => setSelections({ ...selections, endTime: event.target.value })}
                        />

                   
                                
                        <Button className="add-button" color="primary" variant="contained" disabled={!activateButton} fullWidth onClick={() => handleScheduleFlight()}>Schedule Flight</Button>
                        {alert && <Typography className="alert">{alert}</Typography>}
                        
                        <Button className="reset-button" color="primary" variant="outlined" fullWidth onClick={() => handleReset()}>Reset</Button>

                    </FormControl>
                </Paper>
                :
                // Create an unavailability block
                <Paper className="flight-filter" elevation={1}>
                    <FormControl fullWidth>

                        <FormHelperText>Reason</FormHelperText>
                        <TextField
                            className="text-field"
                            variant="outlined"
                            multiline
                            rows={3}
                            margin='dense'
                            value={selections.blockReason}
                            onChange={(event) => setSelections({ ...selections, blockReason: event.target.value })}
                        />

                        <FormHelperText id="date">Date</FormHelperText>
                        <TextField
                            className="text-field"
                            id="date"
                            type="date"
                            variant="outlined"
                            name="date"
                            value={selections.date}
                            onChange={(event) => setSelections({ ...selections, date: event.target.value })}
                        />

                        <FormControlLabel
                            className='checkbox'
                            label='All Day Block'
                            control={<Checkbox
                                color='primary'
                                checked={selections.allDay}
                                onChange={() => setSelections({ ...selections, allDay: !selections.allDay })}
                            />}
                        />

                        <FormHelperText>Start Time</FormHelperText>
                        <TextField
                            className="text-field"
                            type="time"
                            variant="outlined"
                            value={selections.startTime}
                            onChange={(event) => setSelections({ ...selections, startTime: event.target.value })}
                            disabled={selections.allDay}
                        />

                        <FormHelperText>End Time</FormHelperText>
                        <TextField
                            className="text-field"
                            type="time"
                            variant="outlined"
                            value={selections.endTime}
                            onChange={(event) => setSelections({ ...selections, endTime: event.target.value })}
                            disabled={selections.allDay}
                        />


                        <Button className="add-button" color="primary" variant="contained" disabled={!activateButton} fullWidth onClick={() => handleScheduleBlock()}>Schedule Block</Button>
                        {alert && <Typography className="alert">{alert}</Typography>}
                        <Button className="reset-button" color="primary" variant="outlined" fullWidth onClick={() => handleReset()}>Reset</Button>


                    </FormControl>
                </Paper>
            }

            <Snackbar open={openSuccess} autoHideDuration={4000} onClose={() => setOpenSuccess(false)}>
                <Alert onClose={() => setOpenSuccess(false)} severity="success">Event has been scheduled!</Alert>
            </Snackbar>
        </div>
    )
}