import React, { useState } from 'react'

import { Link, useHistory } from 'react-router-dom'

import {
    Grid, FormControl, Typography, TextField, Button, FormHelperText, Paper, Popover,
    Table, TableContainer, TableBody, TableRow, TableCell, TableHead, CircularProgress
} from '@material-ui/core'

import InfoIcon from '@material-ui/icons/Info';
import { CreateFBO } from '../../helpers/SaveData'
import { auth } from '../../utilities/fire'

import firebase from 'firebase'

export default function Onboarding(props) {

    const sendInquiry = firebase.functions().httpsCallable('sendInquiry')
    const history = useHistory()

    const { userData, setUserData, isLoggedIn } = props

    // The data to set to the db
    const [details, setDetails] = useState({
        orgName: '',
        manufacturer: '',
        model: '',
        year: Number(),
        identifier: '',
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        password: '',
        password2: '',
    })

    const [contactDetails, setContactDetails] = useState({
        name: '',
        email: '',
        inquiry: '',
    })

    // ***
    const [beginRegister, setBeginRegister] = useState(true)
    const [errorAlert, setErrorAlert] = useState('') // Error alert for auth
    const [anchorEl, setAnchorEl] = useState(null) // Used for the popover
    const [emailSent, setEmailSent] = useState(false) // If the inquiry has been sent
    const [emailProcessing, setEmailProcessing] = useState(false)

    // *********** Popover handling *****************
    const open = Boolean(anchorEl)

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }
    // **************************************************

    // The final create fbo function
    function handleSubmit() {

        CreateFBO(details, userData.uid).then(result => {
            console.log(result)
            setUserData(result[1])
            history.push('/manage-fbo?settings')
        })
    }

    // Handling the sign in
    function handleSignIn() {
        auth.signInWithEmailAndPassword(details.email, details.password).catch(e => {
            console.error(e)
            setErrorAlert(e.message)
        })
    }

    function handleSendInquiry() {

        setEmailProcessing(true)

        let body = contactDetails.inquiry
        body = body.replace(/\n\r?/g, '<br/>')

        sendInquiry({
            name: contactDetails.name,
            email: contactDetails.email,
            body: body,
        }).then(() => {
            setEmailProcessing(false)
            setEmailSent(true)
        })

    }

    // The function to call for the form
    function formFlow() {
        return (
            <>
                <FormHelperText className="helper-text">Your Organization Name</FormHelperText>
                <TextField
                    className="field"
                    size="small"
                    variant="outlined"
                    value={details.orgName || ''}
                    onChange={(arg) => setDetails({ ...details, orgName: arg.target.value })}
                />

                <FormHelperText className="helper-text">
                    Add a Plane
                        <InfoIcon
                        className="info-icon"
                        aria-owns={open ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                    />
                    <Popover
                        id="mouse-over-popover"
                        style={{ padding: '5px', pointerEvents: 'none' }}
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                    >
                        <div style={{ padding: "10px" }}>
                            Just add one plane now to get started. You can always add more later, always no charge.
                            </div>
                    </Popover>
                </FormHelperText>

                <TableContainer className="table-container">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="header-cell">Manufacturer</TableCell>
                                <TableCell className="header-cell">Model</TableCell>
                                <TableCell className="header-cell">Year</TableCell>
                                <TableCell className="header-cell">Identifier</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell className="cell">
                                    <TextField
                                        className="cell-input"
                                        size="small"
                                        variant="outlined"
                                        value={details.manufacturer || ''}
                                        onChange={(arg) => setDetails({ ...details, manufacturer: arg.target.value })}
                                    />
                                </TableCell>
                                <TableCell className="cell">
                                    <TextField
                                        className="cell-input"
                                        size="small"
                                        variant="outlined"
                                        value={details.model || ''}
                                        onChange={(arg) => setDetails({ ...details, model: arg.target.value })}
                                    />
                                </TableCell>
                                <TableCell className="cell">
                                    <TextField
                                        className="cell-input"
                                        size="small"
                                        variant="outlined"
                                        type='number'
                                        value={details.year || ''}
                                        onChange={(arg) => setDetails({ ...details, year: Number(arg.target.value) })}
                                    />
                                </TableCell>
                                <TableCell className="cell">
                                    <TextField
                                        className="cell-input"
                                        size="small"
                                        variant="outlined"
                                        value={details.identifier || ''}
                                        onChange={(arg) => setDetails({ ...details, identifier: arg.target.value })}
                                    />
                                </TableCell>
                            </TableRow>

                        </TableBody>
                    </Table>
                </TableContainer>

                <Button className="create-button"
                    variant="contained"
                    color="secondary"
                    disabled={details.orgName.length < 4}
                    onClick={() => handleSubmit()}
                >
                    Register FBO
                </Button>

            </>
        )
    }

    // Function to ask for more information
    function requestAccess() {
        return (
            <>
                <Typography className="request-subtitle" variant="h5">Drop us a note!</Typography>

                <div className="request-access">
                    <FormControl className="form" fullWidth>

                        <FormHelperText className="helper-text">Your Name</FormHelperText>
                        <TextField
                            className="name-field"
                            variant="outlined"
                            size="small"
                            value={contactDetails.name || ''}
                            onChange={(arg) => setContactDetails({ ...contactDetails, name: arg.target.value })}
                        />

                        <FormHelperText className="helper-text">Email</FormHelperText>
                        <TextField
                            className="name-field"
                            variant="outlined"
                            size="small"
                            type="email"
                            value={contactDetails.email || ''}
                            onChange={(arg) => setContactDetails({ ...contactDetails, email: arg.target.value })}
                        />

                        <FormHelperText className="helper-text">Inquiry</FormHelperText>
                        <TextField
                            className="inquiry-field"
                            variant="outlined"
                            // size="small"
                            multiline={true}
                            rows={5}
                            value={contactDetails.inquiry || ''}
                            onChange={(arg) => setContactDetails({ ...contactDetails, inquiry: arg.target.value })}
                        />
                        <Button
                            className="submit-button"
                            variant="contained"
                            color="primary"
                            onClick={() => handleSendInquiry()}
                            disabled={contactDetails.name.length === 0 || contactDetails.inquiry.length <= 5 || !contactDetails.email.includes("@")}
                        >
                            Send Email
                        </Button>
                              
                        {
                            emailSent ?
                                <Typography variant="body2" className="success-message">Your inquiry has been sent!</Typography>
                                :
                                emailProcessing && <div className="circular-progress"><CircularProgress /></div>
                        }

                    </FormControl>
                </div>
            </>
        )
    }


    return (
        <>
            <Grid container className="onboard-container" alignItems="center" direction="row" justify="center">
                <Grid item xs sm={4} align="center">

                    <Paper className='onboard-form'>

                        <Link to='/'>
                            <div className='title-img'>
                                <img alt='PilotFBO.com' src={require('../../images/PilotFBO.com_png.png')} />
                            </div>
                        </Link>

                        {isLoggedIn && <Typography variant='body1' gutterBottom>
                            Greetings {userData.firstName} {userData.lastName}!<br/>
                            Let's get that new FBO registered.
                        </Typography>}

                        <FormControl className='form' fullWidth>

                            {!isLoggedIn &&
                                <>

                                    <Typography className="title">Are you currently a user of PilotFBO.com?</Typography>

                                    <div className="button-container">
                                        <Button className="button" variant={beginRegister ? 'contained' : 'outlined'} color="primary" onClick={() => setBeginRegister(true)}>Yes</Button>
                                        <Button className="button" variant={beginRegister ? 'outlined' : 'contained'} color="primary" onClick={() => setBeginRegister(false)} >No</Button>
                                    </div>

                                    {beginRegister ?
                                        <>
                                            <FormHelperText>Email</FormHelperText>
                                            <TextField
                                                className="field-personal"
                                                size="small"
                                                variant="outlined"
                                                value={details.email || ''}
                                                onChange={(arg) => setDetails({ ...details, email: arg.target.value })}
                                            />
                                            <FormHelperText>Password</FormHelperText>
                                            <TextField
                                                className="field-personal"
                                                size="small"
                                                variant="outlined"
                                                type="password"
                                                value={details.password || ''}
                                                onChange={(arg) => setDetails({ ...details, password: arg.target.value })}
                                            />
                                            <Button
                                                className="submit-button"
                                                variant="contained"
                                                color='primary'
                                                fullWidth
                                                onClick={() => handleSignIn()}
                                                disabled={details.password.length === 0 || details.email.length === 0}
                                            >
                                                Sign In
                                            </Button>
                                        </>
                                        :
                                        requestAccess()
                                    }

                                </>
                            }
                            
                            {isLoggedIn && formFlow()}

                            {isLoggedIn && <Button onClick={()=>auth.signOut().then(setUserData({})).catch(e => console.error(e.message))}>Log Out</Button>}
                            {errorAlert && <Typography variant="body2" className="error-message">{errorAlert}</Typography>}

                        </FormControl>

                    </Paper>
                    
                </Grid>
            </Grid>
        </>
    )
}