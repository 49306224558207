import React, { useState, useEffect } from 'react';
import {
  Grid, Typography, TextField, FormControl, FormHelperText,
  Button, Card, CardContent, CircularProgress
}
  from '@material-ui/core';
import { auth } from '../../utilities/fire';
import firebase from 'firebase'
import { useHistory } from 'react-router-dom'


export default function Home(props) {

  const resetPassword = firebase.functions().httpsCallable('resetPassword')
  const history = useHistory()

  const { setUserData } = props
  
  const [loginFields, setLoginFields] = useState({
    email: '',
    password: '',
  })
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [showForgotPassword, setShowForgotPassword] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {

    // Return array of true/false for each key in loginFields 
    const disableArray = Object.keys(loginFields).map(key => {
      if (loginFields[key].length > 0) {
        if (key === 'email' && !loginFields[key].includes('@')) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    })

    if (disableArray.includes(false)) {
      setDisableSubmit(true)
    } else {
      setDisableSubmit(false)
    }

  }, [loginFields])

  function handleSignIn() {

    auth.signInWithEmailAndPassword(loginFields.email, loginFields.password).then(result => {
      setUserData(result.user)
    }).catch(e => {
      setErrorMessage(e.message)
    })

  }

  return (
    <>
      <Grid className="home-container" container direction="row" justify="center" alignItems="center">
        <Grid item xs={10} sm={4} align='center'>
          <Card className='card-container'>

            <div className='title'>
              <img alt='PilotFBO.com' src={require('../../images/PilotFBO.com_png.png')}/>
            </div>
            
            <CardContent>

              <FormControl className='form' fullWidth>

                <FormHelperText className='helper-text'>Email</FormHelperText>
                <TextField
                  className='field'
                  variant='outlined'
                  size='small'
                  placeholder='your-name@email.com'
                  value={loginFields.email || ''}
                  onChange={event => setLoginFields({ ...loginFields, email: event.target.value })}
                />

                <FormHelperText className='helper-text'>Password</FormHelperText>
                <TextField
                  className='field'
                  variant='outlined'
                  size='small'
                  type='password'
                  value={loginFields.password || ''}
                  onChange={event => setLoginFields({ ...loginFields, password: event.target.value })}
                />
                <Button
                  className='button'
                  fullWidth
                  color='primary'
                  variant='contained'
                  disabled={disableSubmit}
                  onClick={handleSignIn}
                >
                  Log In
                </Button>

                <div className='button-container'>
                  <Button className='forgot'  onClick={() => history.push('/acctmgmt')}>Forgot Password</Button>
                  <Button className='join-now' onClick={() => history.push('/join')}>Register</Button>
                </div>

                <Typography align='center' style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</Typography>

              </FormControl>
              
            </CardContent>
          </Card>
        </Grid>
      </Grid>

    </>
  )
}