import React, {useState, useEffect} from 'react';

import {Box, Grid} from '@material-ui/core'

import FBOSelector from './components/FBOSelector'
import FBODisplay from './components/FBODisplay'

/**
 * 
 * A component for an FBO manager to handle their FBO/planes etc
 * 
 * @param {Props} props Pass in at least the userData
 * @example <ManageFBO useData={userData} />
 */
export default function ManageFBO(props) {

    const { userData, setUserData, fboData, setFBOData } = props

    const [activeFBOKey, setActiveFBOKey] = useState(null) // A String that is the key of the active FBO
    
    return (
      <Box className="manage-fbo">
      
        <Grid className="grid-container" container direction="row" justify="center" alignItems="stretch">

            <Grid className="select-fbo-grid" item xs={12} sm={3}>
            
                {/* fboData is all the data, active is the current, and set is how to change */}
                <FBOSelector fboData={fboData} activeFBOKey={activeFBOKey} setActiveFBOKey={setActiveFBOKey}/>       

            </Grid>
            <Grid className="display-fbo-grid" item xs={12} sm={9}>

                {activeFBOKey && <FBODisplay activeFBOKey={activeFBOKey} fboData={fboData} setFBOData={setFBOData} userData={userData} setUserData={setUserData}/>}

            </Grid>
          
        </Grid>
      
      </Box>
    )
}
