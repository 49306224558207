
import {db} from '../utilities/fire'

/**
 * 
 * Fetches the relevant data from the database
 * 
 * @param {String} userID Firebase User UID
 * @param {String} planes An array of acceptable planes for the user
 * 
 * @return {Array} Returns an array of the events as a promise
 */
export default function FetchEvents(userID, planes, instructors, fbos) {


    // Only goes through each approved plane for the user
    function getAssetBlocks(assetID) {

        return new Promise(res => {

            db.collection('events').where('event.planeUID', '==', assetID).get().then(events => {

                let eventList = []

                events.forEach(event => {

                    let eventData = event.data()
                    
                    if (!eventData.deleted) {

                        let { id, title, start, end, resourceEditable, resourceIds, planeUID, groundTime, hobbsStart, hobbsEnd } = eventData.event
                        // add "color" if wanting default color for an event
                        // NOTE: If you add color here, you can't get any other color - anywhere on the calendar
                        
                        let logData = {}
                        if (eventData?.logData) {
                            logData = eventData.logData
                        }

                        
                        let newEvent = {
                            id: id,
                            title: title,
                            start: start,
                            end: end,
                            resourceIds: resourceIds,
                            resourceEditable: resourceEditable,
                            planeUID: planeUID,
                            groundTime: groundTime,
                            hobbsStart: hobbsStart,
                            hobbsEnd: hobbsEnd,
                        }
                        if (!(eventData.user === userID)) {
                            newEvent.rendering = 'background'
                            
                        }
                        
                        eventList.push(newEvent)

                    }
                })

                res(eventList)

            })

        })
    }

    // Only goes through each approved instructor for the user
    function getInstructorEvents(instructorID) {
        return new Promise(async res => {

            let resultEvents = []
            const instructorQuery = await db.collection('events').where('hasInstructor', '==', instructorID).get()

            if (!instructorQuery.empty) {
                instructorQuery.forEach(event => {
                    let eventData = event.data()

                    if (!eventData.deleted) {
                        let { id, title, start, end, resourceEditable, resourceIds, planeUID, groundTime, hobbsStart, hobbsEnd } = eventData.event // add "color" if wanting default color for an event

                        let newEvent = {
                            id: id,
                            title: title,
                            start: start,
                            end: end,
                            resourceIds: resourceIds,
                            resourceEditable: resourceEditable,
                            planeUID: planeUID,
                            groundTime: groundTime,
                            hobbsStart: hobbsStart,
                            hobbsEnd: hobbsEnd,
                            hasInstructor: eventData.hasInstructor,
                            user: eventData.user,
                            rendering: 'background'
                        }

                        resultEvents.push(newEvent)
                    }

                })
            }

            res(resultEvents)
        })
    }

    function getInstructorBlocks(instructorID) {
        return new Promise(async res => {
            
            let resultEvents = []
            const instructorQuery = await db.collection('events').where('user', '==', instructorID).get()

            if (!instructorQuery.empty) {
                instructorQuery.forEach(event => {
                    let eventData = event.data()

                    if (!eventData.deleted && eventData.assetType === 'blocks') {
                        let { id, title, start, end, resourceEditable, resourceIds, planeUID, groundTime, hobbsStart, hobbsEnd } = eventData.event // add "color" if wanting default color for an event

                        let newEvent = {
                            id: id,
                            title: title,
                            start: start,
                            end: end,
                            resourceIds: resourceIds,
                            resourceEditable: resourceEditable,
                            planeUID: planeUID,
                            groundTime: groundTime,
                            hobbsStart: hobbsStart,
                            hobbsEnd: hobbsEnd,
                            hasInstructor: eventData.hasInstructor,
                            user: eventData.user,
                            rendering: 'background'
                        }

                        if (eventData.event?.block) {
                            newEvent.block = true
                            newEvent.backgroundColor = '#212121'
                            newEvent.textColor = 'white'
                        }

                        if (eventData.event?.allDay) {
                            newEvent.allDay = true
                        }

                        resultEvents.push(newEvent)
                    }

                })
            }

            res(resultEvents)
        })
    }

    function getUserBlocks(user) {

        let resultEvents = []

        return new Promise(async res => {

            const blockQuery = await db.collection('events').where('assetType', '==', 'blocks').get()
            if (!blockQuery.empty) {
                blockQuery.forEach(block => {

                    let eventData = block.data()

                    if (!eventData.deleted && eventData.user === user) {
                        let { id, title, start, end, resourceEditable, resourceIds, planeUID, groundTime, hobbsStart, hobbsEnd } = eventData.event // add "color" if wanting default color for an event

                        let newEvent = {
                            id: id,
                            title: title,
                            start: start,
                            end: end,
                            resourceIds: resourceIds,
                            resourceEditable: resourceEditable,
                            planeUID: planeUID,
                            groundTime: groundTime,
                            hobbsStart: hobbsStart,
                            hobbsEnd: hobbsEnd,
                            hasInstructor: eventData.hasInstructor,
                            user: eventData.user,
                        }

                        if (eventData.event?.block) {
                            newEvent.block = true
                            newEvent.backgroundColor = '#212121'
                            newEvent.textColor = 'white'
                        }

                        if (eventData.event?.allDay) {
                            newEvent.allDay = true
                        }

                        resultEvents.push(newEvent)
                    }
                })
            }
            res(resultEvents)
        })
    }


    // The final return
    return new Promise (async (res, rej) => {

        let eventTypePromises = []

        planes.forEach(plane => {
            eventTypePromises.push(getAssetBlocks(plane)) // Get events for each plane
        })

        instructors.forEach(instructor => {
            eventTypePromises.push(getInstructorEvents(instructor)) // Get instructor events
            eventTypePromises.push(getInstructorBlocks(instructor)) // Then get instructor blocks
        })

        eventTypePromises.push(getUserBlocks(userID)) // Get blocks for current user
        
        Promise.all(eventTypePromises).then(async eventList => {
            
            console.log(eventList)
            let resultEvents = []
            eventList.forEach(asset => {
                asset.forEach(event => {
                    resultEvents.push(event)
                })
            })

            res(resultEvents)
        })


    })
}