import React from 'react'

import cardMap from '../defaults/cardImageMapping'
import AddIcon from '@material-ui/icons/Add';

function PaymentCard(props) {

    const {cardData, first, handleClick} = props



    if (props.add) {

        return (

            <div className="payment-card" onClick={()=>handleClick(true)}>
                <div className="add">
                    {
                        first ? 
                        "Add Payment Method"
                        :
                        <AddIcon fontSize={"large"}/>
                    }
                </div>
            </div>
        )


    }


    const brand = cardData.card.brand
    const expiry = `${cardData.card.exp_month} / ${cardData.card.exp_year}`
    const last4 = cardData.card.last4    

    return (
        <div className="payment-card" onClick={() => handleClick(cardData)}>
            {cardMap?.[brand]?.light?.src ? 
                <img 
                    src={cardMap[brand].light.src}
                    alt='credit card brand'
                />
            : 
                <div className="brand">
                    {brand}
                </div>
            }
            <div className="expiry">
                {expiry}
            </div>
            <div className="last4">
                **{last4}
            </div>
        </div>
    )

}

export default PaymentCard;