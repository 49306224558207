import React, { useEffect, useState } from 'react'

import {
    Dialog, DialogTitle, DialogContent, IconButton, FormControl, FormHelperText, TextField, Button, Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

export default function AddBankAccount(props) {

    const { open, close } = props
    
    const [formVersion, setFormVersion] = useState()
    const [formData, setFormData] = useState({})
    const [disableSubmit, setDisableSubmit] = useState(true)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        const card = {
            cardHolder: '',
            address1: '',
            address2: '',
            city: '',
            usState: '',
            zipCode: '',
            cardNumber: '',
            cvc: '',
            exMonth: '',
            exDay: '',
            exYear: '',
        }
        const bank = {
            accountType: '',
            accountName: '',
            routingNumber: '',
            accountNumber: '',
        }
        if (formVersion === 'card') {
            setFormData(card)
        } else if (formVersion === 'bank') {
            setFormData(bank)
        }

    }, [formVersion])
    
    useEffect(() => {

        const checkLengthsArray = Object.values(formData).map(value => {
            if (value?.length > 0) {
                return true
            } else {
                return false
            }
        })

        // Check if all fields are completed - disables submit button
        if (checkLengthsArray.length && !checkLengthsArray.includes(false)) {
            setDisableSubmit(false)
        } else {
            setDisableSubmit(true)
        }

    }, [formData])

    function handleSubmit() {
        
        console.log(formData)
    }

    return (
        <Dialog className="add-bank-dialog" open={open} onClose={close} fullWidth maxWidth={'xs'}>

            <DialogTitle className='dialog-title' disableTypography>
                <h4 className="title"><b>Add Banking Information</b></h4><br />
                <IconButton edge='end' onClick={close}  >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent className="dialog-content">

                <FormControl className="form" fullWidth align="center">
                    {/* <FormHelperText>Account Type</FormHelperText> */}
                    <div className="button-group">
                        <Button className="button" variant={formVersion === 'card' ? 'contained' : 'outlined'} color="primary" onClick={() => setFormVersion('card')}>Debit Card</Button>
                        <Button className="button" variant={formVersion === 'bank' ? 'contained' : 'outlined'} color="primary" onClick={() => setFormVersion('bank')}>Bank Account</Button>
                    </div>
                </FormControl>

                {
                    formVersion === 'bank' ?
                        <FormControl className="form" fullWidth align="center">

                            <div className="button-group">
                                <FormHelperText>Account Type</FormHelperText>
                                <Button className="button" variant={formData.accountType === 'personal' ? 'contained' : 'outlined'} color="primary" onClick={() => setFormData({ ...formData, accountType: 'personal' })}>Personal</Button>
                                <Button className="button" variant={formData.accountType === 'company' ? 'contained' : 'outlined'} color="primary" onClick={() => setFormData({ ...formData, accountType: 'company' })}>Company</Button>
                            </div>

                            <FormHelperText>Account Name</FormHelperText>
                            <TextField
                                className="text-field"
                                required
                                variant="outlined"
                                size="small"
                                value={formData.accountName || ''}
                                onChange={e => setFormData({ ...formData, accountName: e.target.value })}
                            />

                            <FormHelperText>Routing Number</FormHelperText>
                            <TextField
                                className="text-field"
                                required
                                variant="outlined"
                                size="small"
                                value={formData.routingNumber || ''}
                                inputProps={{ maxLength: 9, minLength: 9, }}
                                onChange={e => setFormData({ ...formData, routingNumber: e.target.value })}
                            />

                            <FormHelperText>Account Number</FormHelperText>
                            <TextField
                                className="text-field"
                                required
                                variant="outlined"
                                size="small"
                                value={formData.accountNumber || ''}
                                onChange={e => setFormData({ ...formData, accountNumber: e.target.value })}
                            />

                            <Button
                                className="submit"
                                variant="contained"
                                color="primary"
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>


                        </FormControl>
                        :
                        formVersion === 'card' &&
                        <FormControl className="form" fullWidth align="center">

                            <FormHelperText>Card Holder</FormHelperText>
                            <TextField
                                className="text-field"
                                required
                                variant="outlined"
                                size="small"
                                value={formData.cardHolder || ''}
                                onChange={e => setFormData({ ...formData, cardHolder: e.target.value })}
                            />

                            <FormHelperText className="helper-text">Address</FormHelperText>
                            <TextField
                                className="text-field"
                                required
                                variant="outlined"
                                size="small"
                                value={formData.address1 || ''}
                                onChange={e => setFormData({ ...formData, address1: e.target.value })}
                            />

                            <FormHelperText className="helper-text">Address 2</FormHelperText>
                            <TextField
                                className="text-field"
                                required
                                variant="outlined"
                                size="small"
                                value={formData.address2 || ''}
                                onChange={e => setFormData({ ...formData, address2: e.target.value })}
                            />

                            <FormHelperText className="helper-text">City</FormHelperText>
                            <TextField
                                className="text-field"
                                required
                                variant="outlined"
                                size="small"
                                value={formData.city || ''}
                                onChange={e => setFormData({ ...formData, city: e.target.value })}
                            />
                            
                            <div className="inline">
                                <div className="section">
                                    <FormHelperText className="helper-text">State</FormHelperText>
                                    <TextField
                                        className="text-field"
                                        required
                                        variant="outlined"
                                        size="small"
                                        value={formData.usState || ''}
                                        onChange={e => setFormData({ ...formData, usState: e.target.value })}
                                    />
                                </div>
                                <div className="section">
                                    <FormHelperText className="helper-text">Zip Code</FormHelperText>
                                    <TextField
                                        className="text-field"
                                        required
                                        variant="outlined"
                                        size="small"
                                        value={formData.zipCode || ''}
                                        inputProps={{ maxLength: 5, minLength: 5 }}
                                        onChange={e => setFormData({ ...formData, zipCode: e.target.value })}
                                    />
                                </div>
                            </div>


                            <div className="inline">
                                <div className="section">
                                    <FormHelperText>Card Number</FormHelperText>
                                    <TextField
                                        className="text-field"
                                        required
                                        variant="outlined"
                                        size="small"
                                        value={formData.cardNumber || ''}
                                        onChange={e => setFormData({ ...formData, cardNumber: e.target.value })}
                                    />
                                </div>
                                <div className="cvc">
                                    <FormHelperText>CVC</FormHelperText>
                                    <TextField
                                        className="text-field"
                                        required
                                        variant="outlined"
                                        size="small"
                                        value={formData.cvc || ''}
                                        onChange={e => setFormData({ ...formData, cvc: e.target.value })}
                                    />
                                </div>
                            </div>


                            <Typography className="section-header">Expiration Date</Typography>
                            <div className="inline">
                                <div className="section">
                                    <FormHelperText>Month</FormHelperText>
                                    <TextField
                                        className="text-field"
                                        required
                                        variant="outlined"
                                        size="small"
                                        placeholder='01'
                                        value={formData.exMonth || ''}
                                        inputProps={{ maxLength: 2, minLength: 2, }}
                                        onChange={e => setFormData({ ...formData, exMonth: e.target.value })}
                                    />
                                </div>
                                <div className="section">
                                    <FormHelperText>Day</FormHelperText>
                                    <TextField
                                        className="text-field"
                                        required
                                        variant="outlined"
                                        placeholder='01'
                                        size="small"
                                        value={formData.exDay || ''}
                                        inputProps={{ maxLength: 2, minLength: 2, }}
                                        onChange={e => setFormData({ ...formData, exDay: e.target.value })}
                                    />
                                </div>
                                <div className="section">
                                    <FormHelperText>Year</FormHelperText>
                                    <TextField
                                        className="text-field"
                                        required
                                        variant="outlined"
                                        placeholder='2024'
                                        size="small"
                                        value={formData.exYear || ''}
                                        inputProps={{ maxLength: 4, minLength: 4, }}
                                        onChange={e => setFormData({ ...formData, exYear: e.target.value })}
                                    />
                                </div>
                            </div>


                            <Button
                                className="submit"
                                variant="contained"
                                color="primary"
                                disabled={disableSubmit}
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>

                        </FormControl>
                }

                {errorMessage.length > 0 && <div className='message'>{errorMessage}</div>}
            </DialogContent>
        </Dialog>
    )
}